import { put, call, select, takeLatest, all, fork } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Post, MultiCallsRequest, Get } from "../../helpers/api_helper"
import { getAllJobs } from "./cronJobScheduleHelpers"
import { OBJECT_STATE } from "../../constants"
import { getCampaignAttrTypeStrByAttrMap } from "../../pages/CampaignManagement/campaignManagementHelpers"
import * as types from "./actionTypes"
import * as actions from "./actions"
import { alertActionRequest } from "../actions"

// Re-usable getCampaigns
export function* getCampaigns(campaignId, returnContacts) {
  let campaignsData, alertMessageData

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const requestBody = {
      campaignId: campaignId || 0,
      campaignChnl: "",
      campaignStatus: "",
      cin,
      returnContacts: returnContacts || false,
    }
    const response = yield call(Post, `${urls.GET_CAMPAIGNS}`, requestBody)

    if (response.status && response.data) {
      campaignsData = response.data.data
    } else {
      alertMessageData = {
        status: response.status ?? false,
        message: response?.message || "Unable to fetch existing campaigns data",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? "Failed to fetch existing campaigns data",
      isNewAlertModalType: true,
    }
  }

  return { campaignsData, alertMessageData }
}

// Re-usable get campaign contacts
function* getCampaignContacts(campaignId, returnContacts = true) {
  let campaignData, alertMessageData

  try {
    const campaignResponse = yield call(getCampaigns, campaignId, returnContacts)

    if (campaignResponse.alertMessageData) {
      alertMessageData = campaignResponse.alertMessageData
    } else {
      campaignData = campaignResponse?.campaignsData?.[0] ?? {}
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? "Failed to fetch campaign contacts",
      isNewAlertModalType: true,
    }
  }

  return { campaignData, alertMessageData }
}

// Get Existing Campaigns Data
function* getCampaignsAndJobsWatcher() {
  yield takeLatest(types.GET_CAMPAIGNS_DATA_REQUEST, getCampaignsAndJobsData)
}
function* getCampaignsAndJobsData() {
  let campaignsData, cronJobsData, alertMessageData

  try {
    const getCampaignsResponse = yield call(getCampaigns)
    campaignsData = getCampaignsResponse.campaignsData
    alertMessageData = getCampaignsResponse.alertMessageData

    const { status, jobs } = yield call(getAllJobs)
    if (status) {
      cronJobsData = jobs
    } else {
      yield put(
        alertActionRequest({
          status: false,
          message: "Unable to fetch campaigns scheduled data ",
          isNewAlertModalType: true,
        })
      )
    }

    yield call(getCampaignMultiCallData)
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? "Failed to fetch campaigns data",
      isNewAlertModalType: true,
    }
  }
  yield put(
    actions.getCampaignsDataResponse(campaignsData, cronJobsData, alertMessageData)
  )
}

// Get All Contacts
function* getAllContactsWatcher() {
  yield takeLatest(types.GET_ALL_CONTACTS_FOR_CAMPAIGN_REQUEST, getAllContacts)
}
function* getAllContacts(action) {
  let contactsData, alertMessageData

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const [bool, requestKey, value] = action.payload
    const requestValue = typeof value === "object" ? value?.value : value

    const requestBody = {
      category: requestKey === "category" ? requestValue : "",
      cin,
      contactName: "",
      customerType: requestKey === "customerType" ? requestValue : "",
      department: requestKey === "department" ? requestValue : "",
      dob: "",
      emailId: "",
      employeeStatus: requestKey === "status" ? requestValue : "",
      globalSearch: "",
      jobTitle: requestKey === "jobTitle" ? requestValue : "",
      occupation: requestKey === "occupation" ? requestValue : "",
      pageNo: 0,
      pageSize: 100,
      phonoNo: "",
      staffIndicator: requestKey === "staffFlag" ? requestValue : "",
      filterSearch: true,
    }

    const response = yield call(Post, `${urls.GET_ALL_CONTACTS}`, requestBody)

    if (response?.status) {
      contactsData = response.data?.data ?? []
    } else {
      alertMessageData = {
        status: response.status ?? false,
        message: response?.message || "Unable to fetch contacts data",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? "Failed to fetch contacts data",
      isNewAlertModalType: true,
    }
  }

  yield put(
    actions.getAllContactsForCampaignResponse(contactsData, alertMessageData)
  )
}

// Multi API call to fetch emailTemplates, whatsappTemplates, masterEmailIds, rulesData.
function* getCampaignMultiCallDataWatcher() {
  yield takeLatest(types.GET_CAMPAIGN_MULTI_DATA_REQUEST, getCampaignMultiCallData)
}
// TODO: uncomment email templates api call & respective logic in phase-2
function* getCampaignMultiCallData() {
  let whatsappTemplates, masterEmailIds, rulesData, pdpaData, alertMessageData

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const whatsappTemplatesArr = (yield select())["campaignManagementReducer"]
      ?.whatsappTemplates
    const pdpaDataArr = (yield select())["campaignManagementReducer"]?.pdpaData

    const isWhatsappTemplatesAvailable = !!(whatsappTemplatesArr?.length > 0)
    // Dont check length for pdpa.
    const isPdpaDataAvailable = !!pdpaDataArr

    if (!isWhatsappTemplatesAvailable || !isPdpaDataAvailable) {
      const multiCallsArr = [
        // { method: 'GET', url: `${urls.GET_EMAIL_TEMPLATES}?cin=${cin}` },
        {
          method: "POST",
          url: urls.GET_All_WHATS_APP_TEMPLATES,
          body: {
            cin: cin,
            system_tag: "User",
            templateId: 0,
            template_category: ["Utility", "Marketing", "OTP"],
            template_language: "",
            template_name: "",
            status: "",
          },
        },
        {
          method: "POST",
          url: urls.GET_CAMPAIGN_PDPA_DATA,
          body: {
            cin: cin,
            isDeleted: "false",
            pdpaChannel: "Whatsapp",
            pdpaIdentity: "",
          },
        },
        // ADD masterEmailIds, rulesData api calls here.
      ]

      const responses = yield call(MultiCallsRequest, multiCallsArr)
      console.log("__getCampaignMultiCallData", responses)

      whatsappTemplates = responses[0]?.data?.templates || []
      pdpaData = responses[1]?.data?.pdpaResponse || []
    } else {
      whatsappTemplates = whatsappTemplatesArr
      pdpaData = pdpaDataArr
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err.message || "Something went wront",
      isNewAlertModalType: true,
    }
    console.error("_getCampaignMultiCallData_catch", err)
  }

  const responsePayload = {
    // emailTemplates,
    whatsappTemplates,
    masterEmailIds,
    rulesData,
    pdpaData,
  }
  yield put(
    actions.getCampaignMultiCallDataResponse(responsePayload, alertMessageData)
  )
}

// Create or Update Campaign
function* campaignCrudWatcher() {
  yield takeLatest(types.CAMPAIGN_CRUD_REQUEST, campaignCrudRequest)
}
function* campaignCrudRequest(action) {
  let { objectState, contactsObj } = action.payload

  let updatedCampaignsData, responseStatus, alertMessageData
  const campaignManagementState = (yield select())["campaignManagementReducer"]
  const attributesMap = campaignManagementState?.attributesMap
  const isDeleteRequest = objectState === OBJECT_STATE.DELETE
  const MESSAGE_KEY =
    objectState === OBJECT_STATE.NEW
      ? "Create"
      : isDeleteRequest
      ? "Delete"
      : "Update"

  try {
    const { actionData: campaignItem } = campaignManagementState

    // Inject campaign contacts if not present
    if (!contactsObj) {
      if (campaignManagementState?.selectedCampaignsContactsData) {
        contactsObj = campaignManagementState?.selectedCampaignsContactsData
      } else {
        const campaignResponse = yield call(
          getCampaignContacts,
          campaignItem.campaignId,
          true
        )

        if (campaignResponse.alertMessageData) {
          alertMessageData = campaignResponse.alertMessageData
        } else {
          contactsObj = campaignResponse?.campaignData?.contacts ?? []
        }
      }
    }

    const requestObj = {
      ...(campaignItem || {}),
      attributeType: getCampaignAttrTypeStrByAttrMap(attributesMap),
      contacts: isDeleteRequest ? [] : contactsObj,
      objectState,
      total_contacts: Array.isArray(contactsObj) ? contactsObj.length : 0,
      ruleId: campaignItem?.ruleId ?? 0,
    }

    console.log("___requestObj", requestObj)
    const response = yield Post(urls.CREATE_CAMPAIGN, requestObj)
    responseStatus = !!response.data?.status

    if (responseStatus) {
      const getCampaignsResponse = yield call(getCampaigns)
      updatedCampaignsData = getCampaignsResponse.campaignsData
      alertMessageData = getCampaignsResponse.alertMessageData

      if (!alertMessageData) {
        alertMessageData = {
          status: response.data?.status,
          message: response.data?.message ?? `Campaign ${MESSAGE_KEY}d Successfully`,
          isNewAlertModalType: true,
        }
      }
    } else {
      alertMessageData = {
        status: false,
        message: response.data?.message ?? `Unable to ${MESSAGE_KEY} Campaign`,
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    console.error("_createOrUpdateCampaign_catch", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? `Failed to ${MESSAGE_KEY} Campaign`,
      isNewAlertModalType: true,
    }
  }

  yield put(
    actions.campaignCrudResponse(
      responseStatus,
      updatedCampaignsData,
      contactsObj,
      alertMessageData
    )
  )
}

function* fetchCampaignContactsWatcher() {
  yield takeLatest(types.FETCH_CAMPAIGN_CONTACTS_REQUEST, fetchCampaignContacts)
}
function* fetchCampaignContacts(action) {
  let campaignData, alertMessageData

  try {
    const { campaignId, returnContacts } = action.payload

    const campaignResponse = yield call(
      getCampaignContacts,
      campaignId,
      returnContacts
    )

    if (campaignResponse.alertMessageData) {
      alertMessageData = campaignResponse.alertMessageData
    } else {
      campaignData = campaignResponse?.campaignData ?? {}
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? "Failed to fetch campaign contacts",
      isNewAlertModalType: true,
    }
  }
  yield put(
    actions.fetchCampaignContactsResponse(
      action.payload.campaignId,
      campaignData?.contacts || [],
      alertMessageData
    )
  )
}

function* fetchCampaignInsights(action) {
  let campaignInsightsData, alertMessageData

  try {
    const campaignId = action.payload
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const response = yield Post(urls.VIEW_CAMPAIGN_INSIGHTS, { campaignId, cin })
    const insightsResponse = response.data
    console.log("_fetchCampaignInsights_response", insightsResponse)

    if (insightsResponse?.status) {
      campaignInsightsData = {
        campaign: insightsResponse?.data,
        replies: insightsResponse?.replies,
        statistics: insightsResponse?.statistics,
        contacts: insightsResponse?.contacts,
      }
    } else {
      alertMessageData = {
        status: false,
        message: insightsResponse?.message ?? "Unable to fetch campaign insights",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    console.error("_fetchCampaignInsights_catch", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message ?? "Failed to fetch campaign insights",
      isNewAlertModalType: true,
    }
  }

  yield put(
    actions.viewCampaignInsightsResponse(campaignInsightsData, alertMessageData)
  )
}

function* fetchCampaignInsightsWatcher() {
  yield takeLatest(types.VIEW_CAMPAIGN_INSIGHTS_REQUEST, fetchCampaignInsights)
}

function* getFeedbacks() {
  let feedback

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const response = yield Get(`${urls.GET_FEEDBACKS}?cin=${cin}`)

    if (response?.status && response?.feedbackDetails?.length > 0) {
      feedback = response.feedbackDetails[response.feedbackDetails.length - 1]
    }
  } catch (err) {
    console.error("_getFeedbacks_catch", err)
  }

  yield put(actions.getFeedbackResponse(feedback))
}

function* getFeedbackWatcher() {
  yield takeLatest(types.GET_FEEDBACK_REQUEST, getFeedbacks)
}

function* createFeedback(action) {
  let requestObj, alertMessageData

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    requestObj = {
      ...action.payload,
      cin,
    }
    const response = yield Post(urls.CREATE_FEEDBACK, requestObj)
    console.log("_createFeedback_response", response)

    if (response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response.data?.message || "Feedback successfully submitted",
        isNewAlertModalType: true,
      }
    } else {
      alertMessageData = {
        status: false,
        message: "Unable to submit the feedback",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    console.error("_createFeedback_catch", err)
    alertMessageData = {
      status: false,
      message: "Failed to submit the feedback",
      isNewAlertModalType: true,
    }
  }

  yield put(actions.createFeedbackResponse(requestObj, alertMessageData))
}
function* createFeedbackWatcher() {
  yield takeLatest(types.CREATE_FEEDBACK_REQUEST, createFeedback)
}

function* fetchLatestCampaignData(action) {
  const campaignId = action.payload

  try {
    const campaignResponse = yield call(getCampaigns, campaignId)

    if (!campaignResponse.alertMessageData) {
      const updatedCampaignData = campaignResponse?.campaignsData?.[0]
      yield put(
        actions.getLatestCampaignDataResponse(campaignId, updatedCampaignData)
      )
    }
  } catch (err) {
    console.error(
      `Failed to fetch latest campaign data for campaignId-${campaignId}`
    )
  }
}
function* fetchLatestCampaignDataWatcher() {
  yield takeLatest(types.GET_LATEST_CAMPAIGN_DATA_REQUEST, fetchLatestCampaignData)
}

function* fetchPdpaData(action) {
  try {
    const callback = action.payload
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo

    const pdpaResponse = yield Post(urls.GET_CAMPAIGN_PDPA_DATA, {
      cin: cin,
      isDeleted: "false",
      pdpaChannel: "Whatsapp",
      pdpaIdentity: "",
    })
    console.log("_fetchPdpaData_pdpaResponse", pdpaResponse)

    const updatedMarketOptOutMobileNumbers = pdpaResponse?.data?.pdpaResponse?.map(
      x => x.pdpaIdentity
    )
    yield put(actions.fetchLatestPdpaDataResponse(updatedMarketOptOutMobileNumbers))
    callback?.()
  } catch (err) {
    console.error("_fetchPdpaData_catch", err)
  }
}
function* fetchPdpaDataWatcher() {
  yield takeLatest(types.FETCH_LATEST_PDPA_DATA_REQUEST, fetchPdpaData)
}

function* campaignManagementSaga() {
  yield all([
    fork(getCampaignsAndJobsWatcher),
    fork(getCampaignMultiCallDataWatcher),
    fork(getAllContactsWatcher),
    fork(campaignCrudWatcher),
    fork(fetchCampaignContactsWatcher),
    fork(fetchCampaignInsightsWatcher),
    fork(getFeedbackWatcher),
    fork(createFeedbackWatcher),
    fork(fetchLatestCampaignDataWatcher),
    fork(fetchPdpaDataWatcher),
  ])
}
export default campaignManagementSaga
