import * as types from "./actionTypes";
import * as actions from "./actions";
import { call, takeLatest, all, fork, put, select, takeLeading } from "redux-saga/effects";
import * as urls from "../../helpers/url_helper";
import { Post } from "../../helpers/api_helper";
import { fileUploader } from "../helpers";
import { ACTION_TYPES, templateStatus } from "../../constants";
import { getAllPlaceHoldersFromText } from "../../helpers/internalControl";
import * as _ from 'lodash';


function* getAllMessageRulesData() {
    let messageRulesData


    let alertMessagesData
    try {
        const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
        let req = {
            cin: cin,
            ruleId: "",
            ruleStatus: ""
        }
        const response = yield call(Post, `${urls.GET_RULES_ENQUIRY}`, req)
        console.log("getMessageRulesresponse=>", response)
        if (response && response.status) {
            messageRulesData = response.data.rules;

        } else {
            alertMessagesData = {
                status: response.status ?? false,
                message: response?.data?.message || "Something went worng",
            }
        }
    } catch (err) {
        alertMessagesData = {
            status: false,
            message: err?.data?.message || "Failed to fetch message Rules",
        }
    }
    console.log('messageRulesData', messageRulesData)
    return { messageRulesData, alertMessagesData }
}

function* getAllMessageRulesDataWatcher() {
    yield takeLatest(
        types.GET_MESSAGE_RULES_REQUEST, getallMessageRulesRequest

    )
}

function* getallMessageRulesRequest() {
    let allMessageRulesData
    let alertMessageData
    try {
        let { messageRulesData, alertMessagesData } = yield call(getAllMessageRulesData)
        console.log('checlldhsdgshgdh', messageRulesData)
        allMessageRulesData = messageRulesData
        alertMessageData = alertMessagesData
    } catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to fetch message rules data",
        }
    }
    console.log(
        "responseData=>",
        allMessageRulesData,
        alertMessageData
    )
    yield put(
        actions.getAllMessageRulesResopnse(
            allMessageRulesData,
            alertMessageData
        )
    )

}
function* validatekeyWordsWatcher() {
    yield takeLatest(
        types.VALIDATE_KEYWORD_REQUEST, validateKeyWordRequest

    )
}

function* validateKeyWordRequest(action) {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo;
    const isEdit = (yield select())["MessageRulesReducer"]?.isEditRule;

    console.log('staetvalidate', action)
    let payload = action?.payload
    let alertMessageData;
    let keywordModelType = '';
    const setFieldValue = payload?.setFieldValue
    let req = {
        cin: cin,
        masterPhoneNo: '',
    }
    let keyWordsDataalreadyPresent = payload?.allKeywords;
    let failedMessage
    let keyWordAfterUpdate
    console.log('keywordstart', { req, keyWordsDataalreadyPresent })
    try {
        const response = yield call(Post, `${urls.ENQUIRY_KEY_WORDS}`, req)
        console.log("getMessageRulesresponse=>", response)
        if (response?.data?.status) {
            keywordModelType = false
            keyWordAfterUpdate = [...keyWordsDataalreadyPresent, action?.payload?.currentkeyWord]
            console.log('dsfsdfsdfsfsd', keyWordAfterUpdate)
            setFieldValue('keyWords', keyWordAfterUpdate)
        }
        else {
            alertMessageData = {
                status: false,
                message: response?.message || "Unable to validate KeyWord",
                isNewAlertModalType: true,

            }
            keywordModelType = true
            keyWordAfterUpdate = [keyWordsDataalreadyPresent]
            failedMessage = "Entered Keyword is already exists. Please try new keyword."
            setFieldValue('keyWords', keyWordAfterUpdate)


        }

    }
    catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to validate Key Word",
        }
    }

    yield put(actions?.validateKeywordResponse(alertMessageData, failedMessage, keywordModelType))

}

function* getOneTimeMessageRepliesWatcher() {
    yield takeLatest(types.GET_ONE_TIME_MESSAGE_REPLIES_REQUEST, getOneTimeMessageRepliesRequest)

}

function* getOneTimeMessageRepliesRequest(action) {
    let alertMessageData;
    let oneTimeMessageReplies = []
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo || '';
    let request =
    {
        cin: cin,
        contextType: "rules",
        messageType: ""
    }
    console.log('startmesagerule', request)
    try {
        const response = yield call(Post, `${urls.QUICK_REPLY_ENQUIRY_MESSSAGES}`, request)
        console.log('response', response)
        if (response && response?.data?.status) {
            let responseData = response?.data?.data || []
            const groupByMessageReplies = _.groupBy(responseData, 'messageType');
            oneTimeMessageReplies = groupByMessageReplies


            console.log('groupByDqata', groupByMessageReplies)
        }
        else {
            alertMessageData = {
                status: false,
                message: response?.message || "Unable to get Quick replies",
                isNewAlertModalType: true,
            }
        }
    }
    catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to get Quick Replies",
        }
    }
    yield put(actions?.getOneTimessageRepliesResponse(oneTimeMessageReplies, alertMessageData))
}
function* createMessageRuleWatcher() {
    yield takeLeading(types.CREATE_MESSAGE_RULE_REQUEST, createMessageRule)

}

function* createMessageRule(action) {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo;
    let { messageRule, objectState } = action?.payload;
    let messageRulesDataAfterAdd
    let alertMessageData
    let responseStatus = false
    let req =
        objectState === "DELETE"
            ? {
                cin: messageRule?.cin,
                flowId: messageRule?.flowItems?.flowId || "",
                flowName: messageRule?.flowItems?.flowName || '',
                keywords: messageRule?.keywords,
                masterPhoneNo: messageRule?.masterphoneNumber || '',
                matchingType: messageRule?.matchingType || '',
                objectState: objectState,
                replyItems: messageRule?.replyItems || [],
                ruleId: messageRule?.ruleId || '',
                ruleName: messageRule?.ruleName || '',
                ruleType: messageRule?.ruleType || '',
                totalReplies: messageRule?.totalReplies || 0,
                ruleStatus: messageRule?.ruleStatus || '',
            }
            : {
                cin: cin,
                flowId: messageRule?.addReplies[0]?.flowId || "",
                flowName: messageRule?.addReplies[0]?.flowName || '',
                keywords: messageRule?.keyWords || '',
                masterPhoneNo: messageRule?.masterphoneNumber?.wba_display_phone_number || '',
                matchingType: messageRule?.matchingType?.value || '',
                objectState: objectState,
                replyItems: messageRule?.addReplies?.length > 0 ? messageRule?.addReplies : [],
                ruleId: messageRule?.ruleId || "",
                ruleName: messageRule?.ruleName || '',
                ruleType: messageRule?.ruleType?.value || '',
                totalReplies: 0,
                ruleStatus: messageRule?.ruleStatus?.value || '',
            }
    console.log('checingCreaxt', { req, messageRule, objectState })

    try {
        const response = yield call(Post, `${urls.CREATE_MESSAGE_RULES}`, req)
        console.log('response', response)
        if (response && response.status) {
            responseStatus = response.status

            let { messageRulesData, alertMessagesData } = yield call(getAllMessageRulesData)
            if (!alertMessagesData) {

                messageRulesDataAfterAdd = messageRulesData
            }
            alertMessageData = {
                status: true,
                message: ` ${objectState === "DELETE"
                    ? "Deleted"
                    : objectState === "NEW"
                        ? "New Rule Created"
                        : "Rule Updated"
                    } Successfully.`,
                isNewAlertModalType: true,
            }
        }
    }
    catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to get Quick Replies",
        }

    }

    yield put(actions?.createOrUpdateMessageRulesResponse(responseStatus, messageRulesDataAfterAdd, alertMessageData))
}
function* getFlowTypeMessageRepliesWatcher() {
    yield takeLeading(types.GET_FLOW_MESSAGE_REPLIES_REQUEST, getFlowTypeMessageReplieeRequest)

}
function* getFlowTypeMessageReplieeRequest(action) {
    console?.log('actionnnnn', action)
    let alertMessageData
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo || ''

    let request = {
        "cin": cin,
        "masterPhoneNo": ''
    }
    console.log('reeeeeeeeeeeeee', request)
    let flowsData
    try {
        const response = yield call(Post, `${urls.FLOWS_TYPES_MESSAGES}`, request)
        console.log('responseeeeee', response, request)
        if (response?.data?.status) {
            flowsData = response?.data?.data
        }
        else {
            alertMessageData = {
                status: false,
                message: response?.message || "Unable to get Flows Data",
                isNewAlertModalType: true,
            }
        }
    }
    catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to get Flows Data",
        }
    }
    yield put(actions?.getFlowsMessageRepliesResponse(flowsData))
}

function* messageRulesSaga() {
    yield all([
        fork(getAllMessageRulesDataWatcher),
        fork(validatekeyWordsWatcher),
        fork(getOneTimeMessageRepliesWatcher),
        fork(createMessageRuleWatcher),
        fork(getFlowTypeMessageRepliesWatcher)
    ])
}

export default messageRulesSaga