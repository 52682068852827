import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put, select } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post, Delete } from "../../helpers/api_helper"
import { fileUploader } from "../helpers"
import { ACTION_TYPES, templateStatus } from "../../constants"

function* getAllListMessages() {
  let listMessagesData = []
  let alertMessagesData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    let req = {
      cin: cin,
      system_tag: "User",
      templateId: 0,
      template_category: ["List", "Reply Button"],
    }
    const response = yield call(Post, `${urls.GET_All_WHATS_APP_TEMPLATES}`, req)
    if (response && response.status) {
      listMessagesData = response.data.templates //?.filter(x => x.templateCategory === 'List')
    } else {
      alertMessagesData = {
        status: response.status ?? false,
        message: response?.message || "Some thing went worng",
      }
    }
  } catch (err) {
    alertMessagesData = {
      status: false,
      message: err?.message || "Failed to fetch List templates",
    }
  }
  return { listMessagesData, alertMessagesData }
}
function* getAllListTemplatesWatcher() {
  yield takeLatest(
    types.GET_ALL_LIST_MESSAGE_TEMPLATE_REQUEST,
    getAllListTemplatesRequest
  )
}
function* getAllListTemplatesRequest(action) {
  let wapListMessageData = []
  let alertMessageData
  try {
    let { listMessagesData, alertMessagesData } = yield call(getAllListMessages)
    wapListMessageData = listMessagesData
    alertMessageData = alertMessagesData
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.message || "Failed to fetch List templates",
    }
  }
  console.log("responseData=>", wapListMessageData, alertMessageData)
  yield put(
    actions.getAllListMessageTemplateResponse(wapListMessageData, alertMessageData)
  )
}

function* createOrUpdateListTemplateWatcher() {
  yield takeLatest(
    types.CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_REQUEST,
    createOrUpdateListRequest
  )
}

function* createOrUpdateListRequest(action) {
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  console.log("requestObj=>", cin)
  let listMessageTemplatesData = []
  let alertMessageData
  let responseStatus = false
  let { requestObj, objectState } = action.payload
  let req =
    objectState === "DELETE"
      ? requestObj
      : {
          attachments: "",
          cin: cin,
          objectState: objectState,
          status: "",
          templateCategory: requestObj.templateType,
          templateId: requestObj?.templateId || "",
          templateLanguage: "",
          templateMessage: {
            body: requestObj.body,
            footer: requestObj.footer,
            header: requestObj.header,
            button:
              requestObj.templateType === "Reply Button"
                ? requestObj?.replyButton
                : requestObj.button,
            components:
              requestObj.templateType === "Reply Button" ? "" : requestObj.sections,
          },
          templateName: requestObj.listMessageName,
          templateType: "",
          updatedFields: {
            status: "",
            template_attachment: "",
            whatsapp_template_id: "",
          },
          whatsappTemplateId: "",
        }
  console.log("requestObj=>", requestObj, req)
  try {
    listMessageTemplatesData = (yield select())["WhatsAppListMessageReducer"]
      ?.listMessageTemplateList
    let response = yield call(Post, urls.CREATE_OR_EDIT_WAP_TEMPLATE, req)
    if (response && response.status) {
      responseStatus = response.status
      let { listMessagesData, alertMessagesData } = yield call(getAllListMessages)
      if (!alertMessagesData) {
        listMessageTemplatesData = listMessagesData
        alertMessageData = {
          status: true,
          message: `${
            (objectState !== "DELETE"
              ? requestObj?.templateType
              : requestObj?.templateCategory) === "Reply Button"
              ? "Reply Button"
              : "List Message"
          } ${
            objectState === "DELETE"
              ? "Deleted"
              : objectState === "NEW"
              ? "Created"
              : "Updated"
          } Successfully.`,
          isNewAlertModalType: true,
        }
      } else {
        alertMessageData = alertMessagesData
      }
    } else {
      alertMessageData = {
        status: false,
        message:
          response?.data?.message ||
          `Unable to  ${
            objectState === "DELETE"
              ? "Deleted"
              : objectState === "NEW"
              ? "Created"
              : "Updated"
          } ${
            (objectState !== "DELETE"
              ? requestObj?.templateType
              : requestObj?.templateCategory) === "Reply Button"
              ? "Reply Buttons"
              : "List Message templates"
          }`,
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message:
        err?.data?.message ||
        `Unable to  ${
          objectState === "DELETE"
            ? "Deleted"
            : objectState === "NEW"
            ? "Created"
            : "Updated"
        } ${
          (objectState !== "DELETE"
            ? requestObj?.templateType
            : requestObj?.templateCategory) === "Reply Button"
            ? "Reply Buttons"
            : "List Message templates"
        }`,
    }
  }
  yield put(
    actions.createOrUpdateListTemplateResponse(
      responseStatus,
      listMessageTemplatesData,
      alertMessageData
    )
  )
}

function* whatsAppListMessageTemplatesSaga() {
  yield all([
    fork(getAllListTemplatesWatcher),
    fork(createOrUpdateListTemplateWatcher),
  ])
}
export default whatsAppListMessageTemplatesSaga
