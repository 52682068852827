import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING, LANDING_PAGE_LOADING } from "../common/actionTypes"

export const showModelForCreateOrUpateOrDelete = (actionType, actionData) => {
  return {
    type: types.SHOW_MODEL_FOR_CREATE_OR_UPDATE_DELETE,
    payload: { actionType, actionData },
  }
}

export const getAllListMessageTemplateRequest = () => ({
  type: types.GET_ALL_LIST_MESSAGE_TEMPLATE_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})

export const getAllListMessageTemplateResponse = (
  listMessageData,
  alertMessageData
) => ({
  type: types.GET_ALL_LIST_MESSAGE_TEMPLATE_RESPONSE,
  payload: listMessageData,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const createOrUpdateListTemplateRequest = (requestObj, objectState) => ({
  type: types.CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_REQUEST,
  payload: { requestObj, objectState },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createOrUpdateListTemplateResponse = (
  responseStatus,
  listMessageData,
  alertMessageData
) => ({
  type: types.CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_RESPONSE,
  payload: { responseStatus, listMessageData },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
