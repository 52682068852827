import * as types from "./actionTypes"
import { ACTION_LOADING, LAYOUT_ALERT_ACTION_REQUEST, LANDING_PAGE_LOADING } from "../common/actionTypes"

export const getCampaignsDataRequest = () => ({
  type: types.GET_CAMPAIGNS_DATA_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})

export const getCampaignsDataResponse = (
  campaignsData,
  cronJobsData,
  alertMessageData
) => ({
  type: types.GET_CAMPAIGNS_DATA_RESPONSE,
  payload: { campaignsData, cronJobsData },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const setCampaignsActionData = (
  actionType,
  actionData = null,
  isSelectExistingActionData = false
) => ({
  type: types.SET_CAMPAIGNS_ACTION_DATA,
  payload: { actionType, actionData, isSelectExistingActionData },
})

export const setCampaignStateByKey = (key, value) => ({
  type: types.SET_CAMPAIGN_STATE_BY_KEY,
  payload: { key, value },
})

export const setCampaignsFilter = (key, value) => ({
  type: types.SET_CAMPAIGNS_FILTER,
  payload: { key, value },
})

export const clearCampaignState = () => ({
  type: types.CLEAR_CAMPAIGN_STATE,
})

export const getCampaignMultiCallDataRequest = () => ({
  type: types.GET_CAMPAIGN_MULTI_DATA_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getAllContactsForCampaignRequest = contactKeyToFetch => ({
  type: types.GET_ALL_CONTACTS_FOR_CAMPAIGN_REQUEST,
  payload: contactKeyToFetch,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getAllContactsForCampaignResponse = (
  contactsData,
  alertMessageData
) => ({
  type: types.GET_ALL_CONTACTS_FOR_CAMPAIGN_RESPONSE,
  payload: contactsData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const getCampaignMultiCallDataResponse = (payload, alertMessageData) => ({
  type: types.GET_CAMPAIGN_MULTI_DATA_RESPONSE,
  payload,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const campaignCrudRequest = (objectState, contactsObj = null) => ({
  type: types.CAMPAIGN_CRUD_REQUEST,
  payload: { objectState, contactsObj },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const campaignCrudResponse = (
  responseStatus,
  campaignsData,
  contactsObj,
  alertMessageData
) => ({
  type: types.CAMPAIGN_CRUD_RESPONSE,
  payload: { responseStatus, campaignsData, contactsObj },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const setCampaignSelectedContacts = (
  selectedContacts,
  preservePrevState = false
) => ({
  type: types.SET_CAMPAIGN_SELECTED_CONTACTS,
  payload: { selectedContacts, preservePrevState },
})

export const fetchCampaignContactsRequest = (campaignId, returnContacts) => ({
  type: types.FETCH_CAMPAIGN_CONTACTS_REQUEST,
  payload: { campaignId, returnContacts },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const fetchCampaignContactsResponse = (
  campaignId,
  campaignContacts,
  alertMessageData
) => ({
  type: types.FETCH_CAMPAIGN_CONTACTS_RESPONSE,
  payload: { campaignId, campaignContacts },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const viewCampaignInsightsRequest = campaignId => ({
  type: types.VIEW_CAMPAIGN_INSIGHTS_REQUEST,
  payload: campaignId,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const viewCampaignInsightsResponse = (
  campaignInsightsData,
  alertMessageData
) => ({
  type: types.VIEW_CAMPAIGN_INSIGHTS_RESPONSE,
  payload: campaignInsightsData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const getFeedbackRequest = () => ({
  type: types.GET_FEEDBACK_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getFeedbackResponse = feedbackData => ({
  type: types.GET_FEEDBACK_RESPONSE,
  payload: feedbackData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})

export const createFeedbackRequest = requestObj => ({
  type: types.CREATE_FEEDBACK_REQUEST,
  payload: requestObj,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const createFeedbackResponse = (feedback, alertMessageData) => ({
  type: types.CREATE_FEEDBACK_RESPONSE,
  payload: feedback,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const getLatestCampaignDataRequest = campaignId => ({
  type: types.GET_LATEST_CAMPAIGN_DATA_REQUEST,
  payload: campaignId,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getLatestCampaignDataResponse = (campaignId, campaignData) => ({
  type: types.GET_LATEST_CAMPAIGN_DATA_RESPONSE,
  payload: { campaignId, campaignData },
  loadType: ACTION_LOADING,
  loadPayload: false,
})

export const resetContactAttributes = updatedPlaceholdersData => ({
  type: types.RESET_CONTACT_ATTRIBUTES,
  payload: { updatedPlaceholdersData },
})

export const fetchLatestPdpaDataRequest = callback => ({
  type: types.FETCH_LATEST_PDPA_DATA_REQUEST,
  payload: callback,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const fetchLatestPdpaDataResponse = updatedMarketOptOutMobileNumbers => ({
  type: types.FETCH_LATEST_PDPA_DATA_RESPONSE,
  payload: updatedMarketOptOutMobileNumbers,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
