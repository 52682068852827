import { ACTION_TYPES } from "../../constants"
import * as types from "./actionTypes"

const initialState = {
  actionType: null,
  actionData: null,
  emailTemplates: [],
}
const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EMAIL_TEMPLATES_ACTION_DATA:
      const { actionType, actionId } = action.payload
      state = {
        ...state,
        actionType,
        actionData: state.emailTemplates?.find(
          template => template.templateId === actionId
        ),
      }
      break
    case types.GET_EMAIL_TEMPLATES_RESPONSE:
      state = {
        ...state,
        emailTemplates: action.payload,
      }
      break
    case types.EMAIL_TEMPLATE_CRUD_RESPONSE:
      if (action.payload) {
        state = {
          ...state,
          emailTemplates: action.payload,
          actionType: ACTION_TYPES.SELECT,
          actionData: null,
        }
      }
      break
    case types.RESET_EMAIL_TEMPLATES:
      state = {
        ...state,
        actionType: ACTION_TYPES.SELECT,
        actionData: null,
      }
      break
    default:
      break
  }
  return state
}
export default emailTemplatesReducer
