import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING } from "../common/actionTypes"

export const getAdminAgentsRequest = () => {
  return {
    type: types.GET_ADMIN_AGENTS_REQUEST,
    payload: "",
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const getAdminAgentsResponse = (response, alertMessageData) => {
  return {
    type: types.GET_ADMIN_AGENTS_RESPONSE,
    payload: response,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

export const addAgentRequest = (
  values,
  resetFields,
  actionType,
  setIsAddAgentOpen,
  isFrom
) => {
  return {
    type: types.ADD_AGENT_REQUEST,
    payload: { values, resetFields, actionType, setIsAddAgentOpen, isFrom },
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const addAgentResponse = alertMessageData => {
  return {
    type: types.ADD_AGENT_RESPONSE,
    payload: {},
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

export const suspendAgentRequest = (
  reqObj,
  setIsSuspendAgentOpen,
  setAgentRecord
) => {
  return {
    type: types.SUSPEND_AGENT_REQUEST,
    payload: { reqObj, setIsSuspendAgentOpen, setAgentRecord },
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const suspendAgentResponse = alertMessageData => {
  return {
    type: types.SUSPEND_AGENT_RESPONSE,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}
export const deleteAgentRequest = (reqObj, setIsDeleteAgentOpen, setAgentRecord) => {
  return {
    type: types.DELETE_AGENT_REQUEST,
    payload: { reqObj, setIsDeleteAgentOpen, setAgentRecord },
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const deleteAgentResponse = alertMessageData => {
  return {
    type: types.DELETE_AGENT_RESPONSE,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

export const getUserRolesPermissionsRequest = role => {
  return {
    type: types.GET_USER_ROLES_PERMISSIONS_REQUEST,
    payload: { role },
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const getUserRolesPermissionsResponse = (
  rolesAndPermissions,
  alertMessageData
) => {
  return {
    type: types.GET_USER_ROLES_PERMISSIONS_RESPONSE,
    payload: { rolesAndPermissions },
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

export const updateRolesPremissionsRequest = (
  premissionsData,
  role,
  restoreDefault,
  permissionsCreatedBy,
  setIsUpdateOpenClicked,
  setRestoreDefaults
) => {
  return {
    type: types.UPDATE_ROLES_PREMISSIONS_REQUEST,
    payload: {
      premissionsData,
      role,
      restoreDefault,
      permissionsCreatedBy,
      setIsUpdateOpenClicked,
      setRestoreDefaults,
    },
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const updateRolesPremissionsResponse = alertMessageData => {
  return {
    type: types.UPDATE_ROLES_PREMISSIONS_RESPONSE,
    payload: {},
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}
