import * as types from "./actionTypes"

const initialState = {
  selectedChartType: "BarChart",
  initialSelectedValue: undefined,
  pricingAnalytics: undefined,
  conversationAnalytics: undefined,
  barGraphDataOptions: undefined,
  pieChartOptions: undefined,
}
const InsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CANCEL_ALL_PENDING_REQUESTS_FOR_INSIGHTS:
      state = {
        ...state,
        selectedChartType: "BarChart",
        initialSelectedValue: undefined,
        pricingAnalytics: undefined,
        conversationAnalytics: undefined,
        barGraphDataOptions: undefined,
        pieChartOptions: undefined,
      }
      break
    case types.GET_INSIGHTS_DATA_RESPONSE:
      state = {
        ...state,
        selectedChartType: action.payload.selectedChartType,
        initialSelectedValue: action.payload.requestObj,
        pricingAnalytics: action.payload.pricingAnalytics,
        conversationAnalytics: action.payload.conversationAnalytics,
        barGraphDataOptions: action.payload.barGraphDataOptions,
        pieChartOptions: action.payload.pieChartOptions,
      }
      break
    default:
      state = { ...state }
      break
  }

  return state
}
export default InsightsReducer
