import { set, isEmpty, isEqual, cloneDeep } from "lodash"
import * as types from "./actionTypes"
import { ACTION_TYPES } from "../../constants"
import {
  getSelectedContacts,
  getCampaignPlaceholdersDataByAttributes,
  getDefaultContactAttributes,
} from "../../pages/CampaignManagement/campaignManagementHelpers"

const resetStateForCampaignCrud = () => ({
  actionType: ACTION_TYPES.SELECT,
  actionData: null,
  isDuplicateCampaign: false,
  selectedContacts: [],
  selectedCampaignsContactsData: null,
  placeholdersData: null,
  uploadedContacts: null,
  attributesMap: [],
  updatedHeaderMediaUrl: "",
  updatedFileName: "",
  updatedFileType: "",
})

const initialState = {
  campaignsData: [],
  contactsData: [],
  contactsMap: null,

  contactsToRender: [],
  // Dont remove "attributesMap" from redux
  attributesMap: [],

  selectedContacts: [],
  campaignInsights: null,
  actionType: ACTION_TYPES.SELECT,
  actionData: null,
  filters: null,
  emailTemplates: [],
  whatsappTemplates: [],
  masterEmailIds: [],
  rulesData: [],
  pdpaData: [],
  updatedHeaderMediaUrl: "",
  updatedFileName: "",
  updatedFileType: "",
  selectedCampaignsContactsData: null,
  placeholdersData: null,
  marketOptOutMobileNumbers: [],
  isDuplicateCampaign: false,
  uploadedContacts: null,
  cronJobsMap: {},
  feedback: null,
}

function campaignManagementReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CAMPAIGNS_DATA_RESPONSE:
      const { cronJobsData } = action.payload
      const cronJobsMap = {}

      if (cronJobsData?.length > 0) {
        cronJobsData.forEach(({ campaignId, jobId, jobStatus }) => {
          cronJobsMap[Math.abs(campaignId)] = {
            jobId,
            jobStatus,
          }
        })
      }

      state = {
        ...state,
        campaignsData: action.payload.campaignsData,
        cronJobsMap,
      }
      break
    case types.SET_CAMPAIGNS_FILTER:
      const { key, value } = action.payload

      state = {
        ...state,
        filters: {
          ...state.filters,
          [key]: value,
        },
      }
      break
    case types.SET_CAMPAIGNS_ACTION_DATA:
      const {
        actionType,
        actionData,
        isSelectExistingActionData = false,
      } = action.payload
      state = {
        ...state,
        actionType,
        actionData: isSelectExistingActionData ? state.actionData : actionData,
      }
      break
    case types.SET_CAMPAIGN_STATE_BY_KEY:
      if (Array.isArray(action.payload.key) && Array.isArray(action.payload.value)) {
        action.payload.key?.forEach((key, idx) =>
          set(state, key, action.payload.value[idx])
        )
      } else if (typeof action.payload.key === "string") {
        set(state, action.payload.key, action.payload.value)
      }
      break
    case types.GET_CAMPAIGN_MULTI_DATA_RESPONSE:
      const {
        emailTemplates,
        whatsappTemplates,
        masterEmailIds,
        rulesData,
        pdpaData,
      } = action.payload
      let marketOptOutMobileNumbers = []

      if (pdpaData?.length > 0) {
        marketOptOutMobileNumbers = pdpaData?.map(item => item?.pdpaIdentity)
      }

      state = {
        ...state,
        emailTemplates:
          !isEmpty(state.emailTemplates) && isEmpty(emailTemplates)
            ? state.emailTemplates
            : emailTemplates,
        whatsappTemplates:
          !isEmpty(state.whatsappTemplates) && isEmpty(whatsappTemplates)
            ? state.whatsappTemplates
            : whatsappTemplates,
        masterEmailIds,
        rulesData,
        pdpaData,
        marketOptOutMobileNumbers,
      }
      break
    case types.GET_ALL_CONTACTS_FOR_CAMPAIGN_RESPONSE:
      const contactsMap = {}
      action.payload?.forEach(contact => {
        contactsMap[contact.id] = contact
      })

      state = {
        ...state,
        contactsData: action.payload,
        contactsMap,
      }
      break
    case types.SET_CAMPAIGN_SELECTED_CONTACTS:
      const { selectedContacts, preservePrevState } = action.payload

      state = {
        ...state,
        selectedContacts: preservePrevState
          ? [...(state.selectedContacts || []), ...selectedContacts]
          : selectedContacts,
      }
      break
    case types.CLEAR_CAMPAIGN_STATE:
      state = { ...initialState, campaignsData: null }
      break
    case types.CAMPAIGN_CRUD_RESPONSE:
      const { responseStatus, campaignsData, contactsObj } = action.payload

      if (responseStatus) {
        state = {
          ...state,
          campaignsData:
            isEmpty(campaignsData) && state.campaignsData
              ? state.campaignsData
              : campaignsData,
          selectedCampaignsContactsData:
            !isEqual(state.selectedCampaignsContactsData, contactsObj) &&
            !isEmpty(contactsObj)
              ? contactsObj
              : state.selectedCampaignsContactsData,
          ...resetStateForCampaignCrud(),
        }
      }
      break
    case types.FETCH_CAMPAIGN_CONTACTS_RESPONSE:
      const { campaignId, campaignContacts } = action.payload
      const index = state.campaignsData?.findIndex(
        campaign => campaign?.campaignId === campaignId
      )
      let updatedCampaigns = [...state.campaignsData]

      if (index !== -1) {
        const updatedCampaignItem = {
          ...updatedCampaigns[index],
          contacts: campaignContacts ?? [],
        }
        updatedCampaigns?.splice(index, 1, updatedCampaignItem)

        const modifiedContacts = getSelectedContacts(updatedCampaignItem)
        const placeholdersData =
          getCampaignPlaceholdersDataByAttributes(
            updatedCampaignItem?.contacts?.[0],
            updatedCampaignItem?.campaign_channel ??
              updatedCampaignItem?.campaignChannel
          ) || {}

        state = {
          ...state,
          campaignsData:
            isEmpty(updatedCampaigns) && state.campaignsData
              ? state.campaignsData
              : updatedCampaigns,
          selectedContacts: modifiedContacts,
          placeholdersData,
        }
      }
      break
    case types.VIEW_CAMPAIGN_INSIGHTS_RESPONSE:
      state = {
        ...state,
        campaignInsights: action.payload,
      }
      break
    case types.GET_FEEDBACK_RESPONSE:
      state = {
        ...state,
        feedback: action.payload,
      }
      break
    case types.CREATE_FEEDBACK_RESPONSE:
      state = {
        ...state,
        feedback: action.payload,
      }
      break
    case types.GET_LATEST_CAMPAIGN_DATA_RESPONSE:
      const { campaignId: requestedCampaignId, campaignData: updatedCampaignData } =
        action.payload || {}

      if (
        state.campaignsData?.length > 0 &&
        requestedCampaignId &&
        updatedCampaignData
      ) {
        const campaignIndex = state.campaignsData?.findIndex(
          campaign => campaign.campaignId === requestedCampaignId
        )

        if (campaignIndex !== -1) {
          const updatedCampaignsData = [...state.campaignsData]
          updatedCampaignsData.splice(campaignIndex, 1, updatedCampaignData)

          state = {
            ...state,
            campaignsData: updatedCampaignsData,
          }
        }
      }
      break
    case types.RESET_CONTACT_ATTRIBUTES:
      const { updatedPlaceholdersData } = action.payload
      console.log("updatedPlaceholdersData", updatedPlaceholdersData)
      if (state.contactsToRender?.length > 0) {
        const defaultAttributes = getDefaultContactAttributes(
          updatedPlaceholdersData?.placeholdersCount
        )
        const updatedSelectedContacts = state.contactsToRender.map(contact => ({
          ...contact,
          attributes: defaultAttributes,
        }))

        state = {
          ...state,
          contactsToRender: updatedSelectedContacts,
        }
      }
      break
    case types.FETCH_LATEST_PDPA_DATA_RESPONSE:
      state = {
        ...state,
        marketOptOutMobileNumbers: !isEmpty(action.payload)
          ? action.payload
          : state.marketOptOutMobileNumbers,
      }
      break
    default:
      break
  }
  return state
}
export default campaignManagementReducer
