import { call, put, all, fork, takeLeading, takeLatest } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as actions from "./actions"
import * as urls from "../../../helpers/url_helper"
import { Get, Post } from "../../../helpers/api_helper"
import jwt from "jwt-decode"
import moment from "moment"

const errors = {
  SUCCESS_TO_LOGIN: "Successfully logged in",
  UNABLE_TO_LOGIN: "Unable to login",
  FAILED_TO_LOGIN: "Failed to login",
}

const login = loginReqInfo => Post(urls.LOGIN_ACCOUNT, loginReqInfo, { auth: false })
const googleUserLogin = token =>
  Post(urls?.GOOGLE_USER.replace("{googleToken}", token + ""))

function* getLogin(action) {
  console.log("getLogin_start==>", action)
  const IsSocialLogin = false
  yield put(actions.loginResponse(IsSocialLogin, ""))
  yield put(actions.isLoginLoading(true))

  const loginInfo = {
    emailId: (action.payload?.loginData?.email || "").trim(),
    password: action.payload?.loginData?.password || "",
  }

  try {
    const response = yield call(login, loginInfo)
    console.log("getLogin_res==>", response)
    action.payload.setFieldValue("errorFlag", "")

    localStorage.setItem("isAuthenticated", true)

    localStorage.setItem(
      "authUserinfo",
      JSON.stringify(response?.data.loginUserDetails)
    )

    if (response?.data?.status) {
      yield put(
        actions.setSessionStateData(
          response?.data?.loginUserDetails,
          response?.headers.authorization
        )
      )

      if (
        response?.data?.loginUserDetails?.subscription?.[0]?.trailFlag &&
        !["active", "cancelled", "halted"].includes(
          response?.data?.loginUserDetails.subscription?.[0]?.status
        )
      ) {
        yield put(actions.setSubscriptionModalOpen(true))
      } else {
        yield put(actions.setSubscriptionModalOpen(false))
      }

      // const tokenData = jwt(response?.data?.headers.authorization);
      const reqObj = {
        cin: response?.data?.loginUserDetails?.ciNo,
        phoneNoId: 0,
        phone_no: "",
        wba_id: "",
        wba_phone_no_id: "",
      }
      const wbaDeatailsResponse = yield call(
        Post,
        urls.GET_WPA_PHONE_NUMBERS,
        reqObj
      )
      const wbaDetails = wbaDeatailsResponse?.data?.status
        ? wbaDeatailsResponse?.data
        : undefined

      if (wbaDetails) {
        yield put(
          actions.setSessionStateData(
            response?.data?.loginUserDetails,
            response?.headers.authorization,
            wbaDetails
          )
        )
      }

      const alertMessageData = {
        status: true,
        message: response?.data?.message || errors.SUCCESS_TO_LOGIN,
      }

      localStorage.setItem("authUser", JSON.stringify(response?.data?.userCreateDto))
      yield put(
        actions.loginResponse(
          response?.headers.authorization,
          response?.data?.userDetailsDto
        )
      )

      const subscriptionDate = moment(
        new Date(
          response?.data.loginUserDetails?.subscription?.[0]?.currentEndDt * 1000
        )
      ).format("YYYY-MM-DD")
      const currentDate = moment(new Date()).format("YYYY-MM-DD")
      const trialEndDt = moment(
        new Date(response?.data.loginUserDetails?.subscription?.[0]?.trialEndDt)
      ).format("YYYY-MM-DD")

      yield put(actions.isLoginLoading(false))

      if (response?.data.loginUserDetails?.subscription?.[0]?.trailFlag) {
        if (new Date(currentDate) < new Date(trialEndDt)) {
          action.payload.history.push("/dashboard")
        } else {
          action.payload.history.push("/subscriptionTrailExpiresError")
        }
      } else if (
        response?.data.loginUserDetails?.subscription?.[0]?.status === "halted"
      ) {
        action.payload.history.push("/SubscriptionHaltError")
      } else if (new Date(currentDate) < new Date(subscriptionDate)) {
        action.payload.history.push("/dashboard")
      } else {
        action.payload.history.push("/subscriptionError")
      }
      console.log(
        "wbaDeatailsResponse",
        currentDate,
        subscriptionDate,
        response?.data.loginUserDetails?.subscription?.[0]?.currentEndDt,
        wbaDeatailsResponse
      )
    } else {
      yield put(actions.isLoginLoading(false))
      action.payload.setFieldValue("errorFlag", response.data)
      const alertMessageData = {
        status: false,
        message: response?.data?.message || "Unable to Login",
      }
      // yield put(actions.loginAlertMessage(alertMessageData)); // stopped toaster for login
    }
  } catch (error) {
    yield put(actions.isLoginLoading(false))
    action.payload.setFieldValue("errorFlag", error?.data)
    console.log("data=>", error)
    const alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to Login",
    }
    yield put(actions.loginAlertMessage(alertMessageData))
  }
}

function* socialLogin({ payload: { data, history } }) {
  history.push("/dashboard")
  const IsSocialLogin = true
  yield put(actions.loginResponse(IsSocialLogin, ""))

  try {
    const response = yield call(googleUserLogin, data?.tokenId)
    if (response?.data?.isSuccess) {
      const tokenData = jwt(response?.data?.token)
      const alertMessageData = {
        status: true,
        message: response?.data?.reason || errors.SUCCESS_TO_LOGIN,
      }
      yield put(actions.loginAlertMessage(alertMessageData))
      yield put(actions.loginResponse(tokenData, response?.data?.token))
      history.push("/dashboard")
    } else {
      const alertMessageData = {
        status: false,
        message: response?.data?.reason || errors.UNABLE_TO_LOGIN,
      }
      yield put(actions.loginAlertMessage(alertMessageData))
    }
  } catch (error) {
    const alertMessageData = { status: false, message: errors.FAILED_TO_LOGIN }
    yield put(actions.loginAlertMessage(alertMessageData))
  }
}

function* logOut(action) {
  try {
    const response = yield call(Get, urls.LOGOUT_ACCOUNT)
    console.log("LogoutRes=>", response)
    if (response?.status) {
      const alertMessageData = {
        status: true,
        message: response?.message || errors.SUCCESS_TO_LOGIN,
      }
      // yield put(actions.loginAlertMessage(alertMessageData)); // stopped toaster for logout
    } else {
      const alertMessageData = {
        status: false,
        message: response?.message || errors.UNABLE_TO_LOGIN,
      }
      // yield put(actions.loginAlertMessage(alertMessageData));
    }
  } catch (error) {
    const alertMessageData = { status: false, message: errors.FAILED_TO_LOGIN }
    // yield put(actions.loginAlertMessage(alertMessageData));
  }
  action.payload?.push("/")
  localStorage.removeItem("authUser")
}

export function* watchlogin() {
  yield takeLatest(types.GET_LOGIN_REQUEST, getLogin)
  yield takeLatest(types.RESEND_OTP_REQUEST, getLogin)
  yield takeLatest(types.ACTION_LOGOUT, logOut)
  yield takeLatest(types.SOCIAL_LOGIN_REQUEST, socialLogin)
}

function* loginSaga() {
  yield all([fork(watchlogin)])
}

export default loginSaga
