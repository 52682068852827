import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Post, Put } from "../../helpers/api_helper"

function* createQuestionTypeTextNodeRequest(action) {
  let alertMessageData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined
    let req = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: action?.payload?.regexPattern,
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          expectedAnswers: [
            {
              expectedInput: "string",
              id: action?.payload?.sourceNodeId,
              isDefault: true,
              nodeResultId: action?.payload?.sourceNodeId,
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          questionType: action?.payload?.questionType || "",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }
    const response = yield call(Post, urls.CREATE_FLOW, req)
    if (response && response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Flow Created Successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Create Flow",
      }
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To Create Flow",
    }
  }
  yield put(actions?.createQuestionTypeTextNodeResponse(alertMessageData))
}
function* createQuestionTypeListNodeRequest(action) {
  const sectionId = action?.payload?.sectionDetails?.map(x => x)
  const sectionData = action?.payload?.sectionDetails || []
  const mappedSectionData = sectionData?.map(x =>
    x?.rows?.map(y => {
      const reqObj = {
        id: x?.title,
        expectedInput: y?.id,
        isDefault: false,
        nodeResultId: "main_question-xfudV",
      }

      return reqObj
    })
  )
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined
    let reqObjForQuestionType = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId || "",
          sourceNodeId: "string",
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          id: "main_question-xfudV",
          flowNodeType: "Question",
          questionType: "List",
          isStartNode: false,
          flowReplies: [
            {
              flowReplyType: "Text",
              messageOrder: 1,
              message: {
                body: "Please Select Colors",
              },
            },
          ],
          expectedAnswers: [
            {
              id: sectionId || "",
              expectedInput: rowId || "",
              isDefault: false,
              nodeResultId: action?.payload?.sourceNodeId,
            },
            // {
            //   id: action?.payload?.products?.at(1)?.id,
            //   expectedInput: action?.payload?.products?.at(1)?.description,
            //   isDefault: false,
            //   nodeResultId: "main_question-xfudV",
            // },
            // {
            //   id: action?.payload?.products?.at(2)?.id,
            //   expectedInput: action?.payload?.products?.at(2)?.description,
            //   isDefault: false,
            //   nodeResultId: "main_question-xfudV",
            // },
          ],
          answerValidation: {
            validateAnswerFlag: true,
            type: "String",
            minValue: "",
            maxValue: "",
            regexPattern: "",
            fallbackMessage: "",
            failsCount: "",
          },
          flowNodePosition: {
            posX: "11",
            posY: "40",
          },
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }
    // let req = {
    //     // created_ts: new Date(myDate).getTime()||"",
    //     cin: user_info?.ciNo || "",
    //     masterPhoneNo: user_info?.contactNo || "",
    //     flowName: "Brillius Support without agents",
    //     flowId: action?.payload?.sourceNodeId || "",
    //     objectState: "NEW",
    //     flowNodes: [
    //         {
    //             id: action?.payload?.sourceNodeId || "",
    //             flowNodeType: action?.payload?.flowNodeType || "",
    //             questionType: action?.payload?.questionType || "",
    //             isStartNode: true,
    //             flowReplies: [
    //                 {
    //                     flowReplyType: action?.payload?.flowReplyType || "",
    //                     messageOrder: 1,
    //                     // message: {
    //                     //     body: action?.payload?.message || ""
    //                     // }
    //                     message: {
    //                         header: {
    //                             "type": "text",
    //                             "text": "HEADER_TEXT"
    //                         },
    //                         body: {
    //                             "text": "BODY_TEXT"
    //                         },
    //                         footer: {
    //                             "text": "FOOTER_TEXT"
    //                         },
    //                         action: {
    //                             button: "BUTTON_TEXT",
    //                             sections: [
    //                                 {
    //                                     title: "SECTION_1_TITLE",
    //                                     rows: [
    //                                         {
    //                                             "id": "SECTION_1_ROW_1_ID",
    //                                             "title": "SECTION_1_ROW_1_TITLE",
    //                                             "description": "SECTION_1_ROW_1_DESCRIPTION"
    //                                         },
    //                                         {
    //                                             "id": "SECTION_1_ROW_2_ID",
    //                                             "title": "SECTION_1_ROW_2_TITLE",
    //                                             "description": "SECTION_1_ROW_2_DESCRIPTION"
    //                                         }
    //                                     ]
    //                                 },
    //                                 {
    //                                     title: "SECTION_2_TITLE",
    //                                     rows: [
    //                                         {
    //                                             id: "SECTION_2_ROW_1_ID",
    //                                             title: "SECTION_2_ROW_1_TITLE",
    //                                             description: "SECTION_2_ROW_1_DESCRIPTION"
    //                                         },
    //                                         {
    //                                             "id": "SECTION_2_ROW_2_ID",
    //                                             "title": "SECTION_2_ROW_2_TITLE",
    //                                             "description": "SECTION_2_ROW_2_DESCRIPTION"
    //                                         }
    //                                     ]
    //                                 }
    //                             ]
    //                         }
    //                     }
    //                 }

    //             ],
    //             expectedAnswers: [
    //                 {
    //                     id: "GVzYMNt",
    //                     expectedInput: "",
    //                     isDefault: false,
    //                     nodeResultId: "main_question-xfudV"
    //                 }
    //             ],
    //             answerValidation: {
    //                 validateAnswerFlag: action?.payload?.validateAnswerFlag,
    //                 type: "String",
    //                 minValue: action?.payload?.minChar || "",
    //                 maxValue: action?.payload?.maxChar || "",
    //                 regexPattern: action?.payload?.regexPattern || "",
    //                 fallbackMessage: action?.payload?.fallbackMessage || "",
    //                 failsCount: action?.payload?.failsCount || "",
    //             },
    //             flowNodePosition: {
    //                 posX: action?.payload?.posX || "",
    //                 posY: action?.payload?.posY || ""
    //             }
    //         }

    //     ],
    //     flowEdges: [
    //         {
    //             id: "reactflow__edge-main_question-HYgOf__WipRAOQ-main_message-uLJSU",
    //             sourceNodeId: action?.payload?.sourceNodeId,
    //             targetNodeId: "main_message-uLJSU"
    //         }
    //     ],
    // }
    // let req = {
    //   cin: user_info?.ciNo || "",
    //   flowDescription: "string",
    //   flowEdges: [
    //     {
    //       id: action?.payload?.sourceNodeId,
    //       sourceNodeId: action?.payload?.sourceNodeId,
    //       targetNodeId: "string",
    //     },
    //   ],
    //   flowId: action?.payload?.sourceNodeId || "",
    //   flowName: "Brillius Support without agents",
    //   flowNodes: [
    //     {
    //       answerValidation: {
    //         failsCount: 0,
    //         fallbackMessage: {},
    //         maxValue: action?.payload?.maxChar || "",
    //         minValue: action?.payload?.minChar || "",
    //         regex: "string",
    //         type: "string",
    //         validateAnswer: action?.payload?.validateAnswerFlag,
    //       },
    //       condition: [
    //         {
    //           additionalProp1: {},
    //           additionalProp2: {},
    //           additionalProp3: {},
    //         },
    //       ],
    //       expectedAnswers: [
    //         {
    //           expectedInput: "string",
    //           id: "string",
    //           isDefault: true,
    //           nodeResultId: "string",
    //         },
    //       ],
    //       flowNodePosition: {
    //         posX: action?.payload?.posX || "",
    //         posY: action?.payload?.posY || "",
    //       },
    //       flowNodeType: action?.payload?.flowNodeType || "",
    //       flowReplies: [
    //         {
    //           additionalProp1: {},
    //           additionalProp2: {},
    //           additionalProp3: {},
    //         },
    //       ],
    //       id: action?.payload?.sourceNodeId || "",
    //       isButtonInput: true,
    //       isStartNode: true,
    //       operationFields: {
    //         agentEmail: "string",
    //         agentId: "string",
    //         agentName: "string",
    //         operationType: "string",
    //         ticketStatus: "string",
    //       },
    //       questionType: action?.payload?.questionType || "",
    //       userInputVariable: "string",
    //     },
    //   ],
    //   masterPhoneNo: user_info?.contactNo || "",
    //   objectState: "NEW",
    // }

    console.log("jshjdja", req)
    // const response = yield call(Post, urls.CREATE_FLOW, reqObjForQuestionType)
    if (response) {
      nodeData = response
    }
  } catch (error) {}
  yield put(actions?.createQuestionTypeListNodeResponse(nodeData))
}

function* createEmailNodeRequest(action) {
  let alertMessageData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let reqObjFormEmail = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId || "",
          sourceNodeId: "string",
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          flowNodeType: action?.payload?.flowNodeType,
          flowReplies: [
            {
              flowReplyType: action?.payload?.flowReplyType || "",
              messageOrder: 1,
              message: {
                body: action?.payload?.message || "",
              },
            },
          ],
          expectedAnswers: [
            {
              id: "GVzYMNt",
              expectedInput: "",
              isDefault: false,
              nodeResultId: "main_question-xfudV",
            },
          ],
          answerValidation: {
            validateAnswer: action?.payload?.validateAnswerFlag,
            type: "String",
            minValue: "",
            maxValue: "",
            regex: "^[A-Za-z0-9._%+-]{3,50}@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
            fallbackMessage: {},
            failsCount: "",
          },
        },
        { questionType: "string", userInputVariable: "string" },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }

    const response = yield call(Post, urls.CREATE_FLOW, reqObjFormEmail)
    if (response && response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Flow Created Successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Create Flow",
      }
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To Create Flow",
    }
  }
  yield put(actions?.createEmailNodeResponse(alertMessageData))
}

function* createPhoneNumberNodeRequest(action) {
  let alertMessageData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let reqObjForPhone = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId || "",
          sourceNodeId: "string",
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          flowNodeType: action?.payload?.flowNodeType,
          flowReplies: [
            {
              flowReplyType: action?.payload?.flowReplyType || "",
              messageOrder: 1,
              message: {
                body: action?.payload?.message || "",
              },
            },
          ],
          expectedAnswers: [
            {
              id: "GVzYMNt",
              expectedInput: "",
              isDefault: false,
              nodeResultId: "main_question-xfudV",
            },
          ],
          answerValidation: {
            validateAnswer: action?.payload?.validateAnswerFlag,
            type: "String",
            minValue: "",
            maxValue: "",
            regex: "^[A-Za-z0-9._%+-]{3,50}@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
            fallbackMessage: {},
            failsCount: "",
          },
        },
        { questionType: "string", userInputVariable: "string" },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }

    const response = yield call(Post, urls.CREATE_FLOW, reqObjForPhone)
    if (response && response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Flow Created Successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Create Flow",
      }
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To Create Flow",
    }
  }
  yield put(actions?.createPhoneNumberNodeResponse(alertMessageData))
}

function* createNumberNodeRequest(action) {
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let reqObjForNumber = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: "string",
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          condition: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          expectedAnswers: [
            {
              expectedInput: "string",
              id: "string",
              isDefault: true,
              nodeResultId: "string",
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          flowReplies: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          operationFields: {
            agentEmail: "string",
            agentId: "string",
            agentName: "string",
            operationType: "string",
            ticketStatus: "string",
          },
          questionType: action?.payload?.questionType || "",
          userInputVariable: "string",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }

    console.log("jshjdja", reqObjForNumber)
    const response = yield call(Post, urls.CREATE_FLOW, reqObjForNumber)
    if (response) {
      numberResponseObject = response
    }
  } catch (error) {}
  yield put(actions?.createNumberNodeResponse(numberResponseObject))
}

function* createUserNameNodeRequest(action) {
  console.log("dsjahdhshf", action)
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let reqObjForUserName = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: "string",
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          condition: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          expectedAnswers: [
            {
              expectedInput: "string",
              id: "string",
              isDefault: true,
              nodeResultId: "string",
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          flowReplies: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          operationFields: {
            agentEmail: "string",
            agentId: "string",
            agentName: "string",
            operationType: "string",
            ticketStatus: "string",
          },
          questionType: action?.payload?.questionType || "",
          userInputVariable: "string",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }
    console.log("sjdsjkf", reqObjForUserName)

    const response = yield call(Post, urls.CREATE_FLOW, reqObjForUserName)
    if (response) {
      userNameResponseObject = response
    }
  } catch (error) {}
  yield put(actions?.createUserNameNodeResponse(userNameResponseObject))
}

function* createKeyPadOptionsNodeRequest(action) {
  console.log("dsjahdhshf", action)
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let req = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: "string",
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          condition: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          expectedAnswers: [
            {
              expectedInput: "string",
              id: "string",
              isDefault: true,
              nodeResultId: "string",
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          flowReplies: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          operationFields: {
            agentEmail: "string",
            agentId: "string",
            agentName: "string",
            operationType: "string",
            ticketStatus: "string",
          },
          questionType: action?.payload?.questionType || "",
          userInputVariable: "string",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }

    console.log("jshjdja", req)
    const response = yield call(Post, urls.CREATE_FLOW, req)
    if (response) {
      nodeData = response
    }
  } catch (error) {}
  yield put(actions?.createKeyPadOptionsNodeResponse(keyPadResponseObject))
}

function* createLocationNodeRequest(action) {
  let responseData
  let alertMessageData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let reqObjForLocation = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: "string",
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          condition: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          expectedAnswers: [
            {
              expectedInput: "string",
              id: "string",
              isDefault: true,
              nodeResultId: "string",
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          flowReplies: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          operationFields: {
            agentEmail: "string",
            agentId: "string",
            agentName: "string",
            operationType: "string",
            ticketStatus: "string",
          },
          questionType: action?.payload?.questionType || "",
          userInputVariable: "string",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }
    const response = yield call(Post, urls.CREATE_FLOW, reqObjForLocation)
    if (response && response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Flow Created Successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Create Flow",
      }
    }
  } catch (error) {}
  yield put(actions?.createLocationNodeResponse(alertMessageData))
}
function* getAssignToAgentNodeRequest(action) {
  let agentsData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined
    let reqObjForAgent = {
      agentId: "",
      agentStatus: "",
      cin: user_info?.ciNo,
      role: "",
    }
    const response = yield call(Post, urls.GET_ADMIN_AGENTS, reqObjForAgent)
    if (response?.status === 200) {
      agentsData = response?.data
    }
  } catch (error) {}
  yield put(actions?.getAssignToAgentNodeResponse(agentsData))
}

function* createConditionNodeRequest(action) {
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    console.log("jshjdja", req)
    if (response) {
      nodeData = response
    }
  } catch (error) {}
  yield put(actions?.createConditionNodeResponse(conditionResponseObject))
}

function* createKeyWordRequest(action) {
  console.log("ahjhghdg", action)

  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let createReqObj = {
      answerVariables: [
        {
          dataType: "string",
          variableName: action?.payload,
        },
      ],
      cin: user_info?.ciNo,
      createdBy: user_info?.emailId,
    }

    const response = yield call(Put, urls.SETTINGS_UPDATE_NOKEYWORD, createReqObj)
  } catch (err) {}
}

function* createTextMessageNodeRequest(action) {
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined

    let reqObjForTextMessage = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: "string",
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          condition: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          expectedAnswers: [
            {
              expectedInput: "string",
              id: "string",
              isDefault: true,
              nodeResultId: "string",
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          flowReplies: [
            {
              additionalProp1: {},
              additionalProp2: {},
              additionalProp3: {},
            },
          ],
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          operationFields: {
            agentEmail: "string",
            agentId: "string",
            agentName: "string",
            operationType: "string",
            ticketStatus: "string",
          },
          questionType: action?.payload?.questionType || "",
          userInputVariable: "string",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }

    const response = yield call(Post, urls.CREATE_FLOW, reqObjForTextMessage)
    if (response) {
      userNameResponseObject = response
    }
  } catch (error) {}
  yield put(actions?.createTextMessageNodeResponse(userNameResponseObject))
}

function* createAssignToAgentNodeRequest(action) {
  console.log("dsjdkskdfhksj", action)
  let alertMessageData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined
    let req = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId,
          sourceNodeId: action?.payload?.sourceNodeId,
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          answerValidation: {
            failsCount: 0,
            fallbackMessage: {},
            maxValue: action?.payload?.maxChar || "",
            minValue: action?.payload?.minChar || "",
            regex: action?.payload?.regexPattern,
            type: "string",
            validateAnswer: action?.payload?.validateAnswerFlag,
          },
          expectedAnswers: [
            {
              expectedInput: "string",
              id: action?.payload?.sourceNodeId,
              isDefault: true,
              nodeResultId: action?.payload?.sourceNodeId,
            },
          ],
          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          isStartNode: true,
          operationFields: {
            agentEmail: action?.payload?.selectAgent?.emailId,
            agentId: action?.payload?.selectAgent?.agentId,
            agentName: action?.payload?.selectAgent?.regName,
            operationType: action?.payload?.selectAgent?.role,
            ticketStatus: action?.payload?.selectAgent?.agentStatus,
          },
          flowNodeType: action?.payload?.flowNodeType || "",
          id: action?.payload?.sourceNodeId || "",
          isButtonInput: true,
          isStartNode: true,
          questionType: action?.payload?.questionType || "",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }
    const response = yield call(Post, urls.CREATE_FLOW, req)
    if (response && response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Flow Created Successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Create Flow",
      }
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To Create Flow",
    }
  }
  yield put(actions?.createAssignToAgentNodeResponse(alertMessageData))
}
function* createReplybuttonRequest(action) {
  let alertMessageData
  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined
    let reqObjForQuestionType = {
      cin: user_info?.ciNo || "",
      flowDescription: "string",
      flowEdges: [
        {
          id: action?.payload?.sourceNodeId || "",
          sourceNodeId: "string",
          targetNodeId: "string",
        },
      ],
      flowId: action?.payload?.sourceNodeId || "",
      flowName: "Brillius Support without agents",
      flowNodes: [
        {
          expectedAnswers: [
            {
              expectedInput: action?.payload?.product?.at(0)?.description,
              id: action?.payload?.product?.at(0)?.id,
              isDefault: true,
              nodeResultId: "string",
            },
            {
              expectedInput: action?.payload?.product?.at(1)?.description,
              id: action?.payload?.product?.at(1)?.id,
              isDefault: true,
              nodeResultId: "string",
            },
            {
              expectedInput: action?.payload?.product?.at(2)?.description,
              id: action?.payload?.product?.at(2)?.id,
              isDefault: true,
              nodeResultId: "string",
            },
          ],
          answerValidation: {
            validateAnswer: action?.payload?.validateAnswerFlag,
            type: "String",
            minValue: "",
            maxValue: "",
            regex: "",
            fallbackMessage: {},
            failsCount: "",
          },
          flowNodeType: action?.payload?.flowNodeType,
          flowReplies: [
            {
              flowReplyType: action?.payload?.flowReplyType || "",
              messageOrder: 1,
              message: {
                body: action?.payload?.message || "",
              },
            },
          ],

          flowNodePosition: {
            posX: action?.payload?.posX || "",
            posY: action?.payload?.posY || "",
          },
          id: "string",
          isButtonInput: true,
          isStartNode: true,
          operationFields: {
            agentEmail: "string",
            agentId: "string",
            agentName: "string",
            operationType: "string",
            ticketStatus: "string",
          },
          questionType: "string",
          userInputVariable: "string",
        },
      ],
      masterPhoneNo: user_info?.contactNo || "",
      objectState: "NEW",
    }
    const response = yield call(Post, urls.CREATE_FLOW, reqObjForQuestionType)
    if (response && response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Flow Created Successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Create Flow",
      }
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To Create Flow",
    }
  }
  yield put(actions?.createReplybuttonResponse(alertMessageData))
}

function* addVaribleRequest() {
  let alertMessageData
  let storeResponseData

  try {
    const localStorageUserData = localStorage?.getItem("authUserinfo")
    const user_info = localStorageUserData
      ? JSON.parse(localStorageUserData)
      : undefined
    let reqObj = {
      cin: user_info?.ciNo || "",
      masterPhoneNo: user_info?.contactNo || "",
    }
    const response = yield call(Post, urls.ENQUIRY_KEY_WORDS, reqObj)
    console.log("csdfsdfs", response)

    if (response && response?.data?.status) {
      storeResponseData = response?.data
      console.log("sdfffffffff", storeResponseData)
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Successfully Fetched keywords",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "",
    }
  }
  console.log("sdddddddd", storeResponseData)
  yield put(actions?.addVaribleResponse(storeResponseData))
}
function* rulesConfiguration() {
  yield takeLatest(
    types.CREATE_QUESTION_TYPE_TEXT_NODE_REQUEST,
    createQuestionTypeTextNodeRequest
  ),
    yield takeLatest(types.CREATE_REPLY_BUTTON_REQUEST, createReplybuttonRequest)
  yield takeLatest(
    types.CREATE_QUESTION_TYPE_LIST_NODE_REQUEST,
    createQuestionTypeListNodeRequest
  )
  yield takeLatest(types.CREATE_EMAIL_NODE_REQUEST, createEmailNodeRequest)
  yield takeLatest(
    types.CREATE_PHONE_NUMBER_NODE_REQUEST,
    createPhoneNumberNodeRequest
  )
  yield takeLatest(types.CREATE_NUMBER_NODE_REQUEST, createNumberNodeRequest)
  yield takeLatest(types.CREATE_CONDITION_NODE_REQUEST, createConditionNodeRequest)
  yield takeLatest(types.CREATE_USERNAME_NODE_REQUEST, createUserNameNodeRequest)
  yield takeLatest(
    types.CREATE_KEY_PAD_OPTIONS_NODE_REQUEST,
    createKeyPadOptionsNodeRequest
  )
  yield takeLatest(types.CREATE_LOCATION_NODE_REQUEST, createLocationNodeRequest)
  yield takeLatest(types.GET_ASSIGN_TO_AGENT_REQUEST, getAssignToAgentNodeRequest)
  yield takeLatest(
    types.CREATE_ASSIGN_TO_AGENT_NODE_REQUEST,
    createAssignToAgentNodeRequest
  )
  yield takeLatest(types.CREATE_KEYWORD_REQUEST, createKeyWordRequest)
  yield takeLatest(
    types.CREATE_TEXT_MESSAGE_NODE_REQUEST,
    createTextMessageNodeRequest
  )
  yield takeLatest(types.ADD_VARIABLE_REQUEST, addVaribleRequest)
}
function* flowConfigSaga() {
  yield all([fork(rulesConfiguration)])
}
export default flowConfigSaga
