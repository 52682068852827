import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLeading, all, fork, put, select } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post } from "../../helpers/api_helper"

function* getAllCodeConfigValuesWatcher() {
  yield takeLeading(types.GET_ALL_CODECONFIG_VALUES_REQUEST, getAllCodeConfigValues)
}
function* getAllCodeConfigValues(action) {
  let codeConfigValues = []
  let alertMessageData
  try {
    const response = yield call(Post, urls.CODE_ENQUIRY, action?.payload)
    codeConfigValues = response.data.data
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(
    actions.getAllCodeConfigValuesResponse(codeConfigValues, alertMessageData)
  )
}
function* getAllCodeValuesWatcher() {
  yield takeLeading(types.GET_ALL_CODE_VALUES_DATA_REQUEST, getAllCodeValues)
}
function* getAllCodeValues(action) {
  let codeValues = []
  let alertMessageData
  let userinfoobj = (yield select())["loginReducer"]?.sessionStateData
  const ciNumberval = userinfoobj?.ciNo
  let reqData = {
    cin: ciNumberval,
    codeTypeIds: [action?.payload?.codeTypeId || action],
    status: "",
    codeValueId: 0,
  }
  console.log("cncnvcvcnvncvv", reqData)
  try {
    const response = yield call(Post, urls.CODE_CODEVALUES, reqData)
    codeValues = response?.data?.data?.["codeValuesWithCodeTypes"]
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(actions.getAllCodeValuesResponse(codeValues, alertMessageData))
}
function* createOrUpdateOrDeleteWatcher() {
  yield takeLeading(
    types.CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_REQUEST,
    createOrUpdateOrDeleteRequest
  )
}
function* createOrUpdateOrDeleteRequest(action) {
  let createOrUpadteResponse
  let alertMessageData
  let { requestData, setIsDeleteModel } = action.payload
  try {
    let response = yield call(Post, urls.CREATE_CODE_CONFIG, requestData)
    if (response.data.status) {
      createOrUpadteResponse = response.data
      console.log("action.payload", requestData, requestData?.codeTypeId)
      yield call(getAllCodeValues, requestData?.codeTypeId)
      setIsDeleteModel && setIsDeleteModel({ isOpen: false, data: "" })
      alertMessageData = {
        status: true,
        message: response?.data?.message
          ? response?.data?.message
          : "codeConfig Created UnSuccesfull",
      }
    } else {
      console.log(
        "createOrUpdateOrDeleteCodeConfigResponse=>",
        response.data,
        response
      )
      createOrUpadteResponse = response.data
      alertMessageData = {
        status: false,
        message: response?.data?.message
          ? response?.data?.message
          : "codeConfig Created UnSuccesfull",
      }
    }
  } catch (err) {
    console.log("err", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  console.log(
    "createOrUpdateOrDeleteCodeConfigResponse=>",
    createOrUpadteResponse,
    alertMessageData
  )
  yield put(
    actions.createOrUpdateOrDeleteCodeConfigResponse(
      createOrUpadteResponse,
      alertMessageData
    )
  )
}
function* codeConfigSaga() {
  yield all([
    fork(getAllCodeConfigValuesWatcher),
    fork(getAllCodeValuesWatcher),
    fork(createOrUpdateOrDeleteWatcher),
  ])
}
export default codeConfigSaga
