import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put, select } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post, Delete } from "../../helpers/api_helper"
import { fileUploader } from "../helpers"
import { ACTION_TYPES, templateStatus } from "../../constants"

function* getAllQrCodes() {
  let qrCodeList = []
  let alertMessagesData
  try {
    const sessionStateData = (yield select())["loginReducer"]
    let request = {
      cin: sessionStateData?.sessionStateData?.ciNo,
      mobileNumbers:
        sessionStateData?.wbaDetails?.wba_phone_numbers?.map(
          x => x.wba_display_phone_number
        ) || [],
    }
    console.log("sessionStateData", sessionStateData)
    const response = yield call(Post, `${urls.GET_ALL_QR_CODES}`, request)
    if (response && response?.data?.status) {
      qrCodeList = response?.data?.qrCodegetResponses
    } else {
      alertMessagesData = {
        status: response.status ?? false,
        message: response?.data?.message || "Some thing went worng",
      }
    }
  } catch (err) {
    alertMessagesData = {
      status: false,
      message: err?.data?.message || "Failed to fetch QR Code templates",
    }
  }
  return { qrCodeList, alertMessagesData }
}
function* getQrCodeTemplatesWatcher() {
  yield takeLatest(
    types.GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_REQUEST,
    getQrCodeTemplatesRequest
  )
}
function* getQrCodeTemplatesRequest() {
  let qrCodeListData = []
  let alertMessageData
  try {
    let { qrCodeList, alertMessagesData } = yield call(getAllQrCodes)
    qrCodeListData = qrCodeList
    alertMessageData = alertMessagesData
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed to fetch QR Code templates",
    }
  }
  yield put(actions.getQrCodeTemplateResponse(qrCodeListData, alertMessageData))
}
function* createOrUpdateQRcodeTemplateWatcher() {
  yield takeLatest(
    types.CREATE_OR_UPDATE_QR_CODE_TEMPLATE_REQUEST,
    createOrUpdateQRCodeRequest
  )
}

function* createOrUpdateQRCodeRequest(action) {
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  let qrCodeListData = []
  let alertMessageData
  let responseStatus = false
  let { requestObj, objectState } = action.payload
  console.log("createOrUpdateQRCodeRequest=>", objectState)
  let req =
    objectState === ACTION_TYPES.ADD
      ? {
        cin: cin,
        masterPhoneNO: requestObj?.masterNo?.wba_display_phone_number,
        qrcodeCategory: "",
        qrcodeName: requestObj?.qrCodeName,
        whatsappPhoneNoId: requestObj?.masterNo?.wba_phone_no_id,
      }
      : {
        cin: cin,
        id: requestObj?.id,
        masterPhoneNO: requestObj?.masterNo?.wba_display_phone_number,
        qrCodeName: requestObj?.qrCodeName,
        qrcodeCategory: "",
        whatsappPhoneNoId: requestObj?.masterNo?.wba_phone_no_id,
      }
  try {
    qrCodeListData = (yield select())["QrcodeListReducer"]?.qrCodeList
    let response =
      objectState === ACTION_TYPES.ADD
        ? yield call(
          Post,
          `${urls.CREATE_QR_CODE}?generateQrImage=${requestObj?.imageFormat?.value}&prefilledMessage=${requestObj?.message}`,
          req
        )
        : yield call(
          Post,
          `${urls.UPDATE_QR_CODE}?code=${requestObj?.code}&prefilledMessage=${requestObj?.message}`,
          req
        )
    if (response && response.status) {
      responseStatus = response.status
      let { qrCodeList, alertMessagesData } = yield call(getAllQrCodes)
      if (!alertMessagesData) {
        qrCodeListData = qrCodeList
        alertMessageData = {
          status: true,
          message:
            response?.data?.message || "Sucessfully to created QR Code templates",
          isNewAlertModalType: true,
        }
      } else {
        alertMessageData = alertMessagesData
      }
    }
  } catch (err) {
    console.error(err)
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to QR Code templates",
    }
  }
  yield put(
    actions.createOrUpdateQRCodeTemplateResponse(
      responseStatus,
      qrCodeListData,
      alertMessageData
    )
  )
}
function* deleteQRcodeTemplateWatcher() {
  yield takeLatest(types.DELETE_QR_CODE_TEMPLATE_REQUEST, deleteQRCodeRequest)
}

function* deleteQRCodeRequest(action) {
  let qrCodeListData = []
  let alertMessageData
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  try {
    qrCodeListData = (yield select())["QrcodeListReducer"]?.qrCodeList
    const sessionStateData = (yield select())["loginReducer"]?.wbaDetails
    const wbaPhoneNoId = sessionStateData?.wba_phone_numbers?.find(
      x => x.wba_phone_no === action.payload?.masterPhoneNo
    )?.wba_phone_no_id
    console.log(
      "sessionStateData",
      sessionStateData?.wba_phone_numbers?.find(
        x => x.wba_phone_no === action.payload?.masterPhoneNo
      )?.wba_phone_no_id
    )
    const response = yield call(
      Delete,
      `${urls.DELETE_QR_CODE}?cin=${cin}&code=${action.payload.code}&prefilledMessage=${action.payload.prefilled_message}&qrcodeId=${action.payload.qrcodeId}&whatsappPhoneNoId=${wbaPhoneNoId}`
    )
    if (response && response.status) {
      const { qrCodeList, alertMessagesData } = yield call(getAllQrCodes)
      if (!alertMessagesData) {
        qrCodeListData = qrCodeList
        alertMessageData = {
          status: true,
          message: response?.data?.message || "Sucessfully delete QR Code templates",
          isNewAlertModalType: true,
        }
      } else {
        alertMessageData = alertMessagesData
      }
    } else {
      qrCodeListData = qrCodeList
      alertMessageData = {
        status: false,
        message: response?.data?.message || "UnSucessfully to delete QR Code templates",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to delete whatApp templates",
    }
  }
  yield put(actions.deleteQRCodeTemplateResponse(qrCodeListData, alertMessageData))
}
function* whatsAppQrCodeSaga() {
  yield all([
    fork(getQrCodeTemplatesWatcher),
    fork(createOrUpdateQRcodeTemplateWatcher),
    fork(deleteQRcodeTemplateWatcher),
  ])
}
export default whatsAppQrCodeSaga
