import moment from "moment"
import * as Yup from "yup"
import * as _ from "lodash"
import * as XLSX from "xlsx"
import * as echarts from "echarts"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import store from "../../store/index"
import {
  customContentValidation,
  defultContentObjectValidate,
} from "../../helpers/formikValidations"
import { getMatches, exportDataIntoCsvOrExcel } from "../../helpers/helperUtils"
import {
  CAMPAIGN_CHANNEL_MAP,
  CODE_VALUES_MAP,
  FREQUENCY_MAP,
  MONTH_NAMES,
  DAYS_SORTER,
  END_DATE_TYPES_MAP,
  MOMENT_DATE_FORMAT_TYPES,
  CAMPAIGN_ATTRIBUTE_TYPES,
  CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP,
  MAX_CHARS_FOR_CAMPAIGN_NAME,
  CAMPAIGN_TYPE_MAP,
  SCHEDULED_CAMPAIGN_SAFE_BUFFER_TIME_IN_MINS,
  ACCEPTED_IMAGE_FILE_TYPES,
  ACCEPTED_DOCUMENT_FILE_TYPES,
  ACCEPTED_VIDEO_FILE_TYPES,
  FILE_TYPES_MAP,
  WHATSAPP_MAX_FILE_SIZE_MAP,
} from "../../constants"
import { isEmpty } from "lodash"

export const getAttachmentsCount = attachmentsIds => {
  const defaultAttachmentsCount = 0

  if (attachmentsIds) {
    return attachmentsIds?.split(",").length
  }
  return defaultAttachmentsCount
}

export const getCurrentTime = () => moment().format("h:mm a")

export const getCreateAndUpdateCampaignValidationSchema = (
  campaignsData,
  isEditMode
) => {
  return Yup.object().shape({
    campaignName: Yup.lazy(campaignName => {
      if (campaignName && campaignsData?.length && !isEditMode) {
        const isDuplicateCampaignName =
          campaignsData?.findIndex(
            campaign => campaign?.campaign_name?.trim() === campaignName.trim()
          ) !== -1

        if (isDuplicateCampaignName) {
          // ignore the below regex, it should be a random word to make the required functionality work.
          return Yup.string()
            .required()
            .matches(new RegExp(`/^![uagstssrsxsdxscagvgcag]$/`), {
              message: "Campaign Name Already Exists",
            })
        } else
          return customContentValidation(
            "This field is required",
            {
              patternType: "alphanumricwithoutspaceandallspecial",
              message: "alphanumricwithoutspaceandallspecial",
              spacialChar: null,
            },
            MAX_CHARS_FOR_CAMPAIGN_NAME,
            3
          )
      } else {
        return customContentValidation(
          "This field is required",
          {
            patternType: "alphanumricwithoutspaceandallspecial",
            message: "alphanumricwithoutspaceandallspecial",
            spacialChar: null,
          },
          MAX_CHARS_FOR_CAMPAIGN_NAME,
          3
        )
      }
    }),
    campaignChannel: defultContentObjectValidate(
      "This field is required"
    ).nullable(),
    messageTemplate: defultContentObjectValidate(
      "This field is required"
    ).nullable(),
    masterPhoneNumber: Yup.mixed().when("campaignChannel", {
      is: selectedChannel =>
        selectedChannel?.value === CAMPAIGN_CHANNEL_MAP.whatsapp,
      then: defultContentObjectValidate("This field is required").nullable(),
      otherwise: Yup.string().notRequired(),
    }),
    masterEmail: Yup.mixed().when("campaignChannel", {
      is: selectedChannel => selectedChannel?.value === CAMPAIGN_CHANNEL_MAP.email,
      then: defultContentObjectValidate("This field is required").nullable(),
      otherwise: Yup.string().notRequired(),
    }),
    campaignType: defultContentObjectValidate("This field is required").nullable(),
    remarks: Yup.lazy(value => {
      if (value !== undefined) {
        return customContentValidation(
          "This field is required",
          {
            patternType: "alphanumricwithoutspaceandallspecial",
            message: "alphanumricwithoutspaceandallspecial",
            spacialChar: null,
          },
          250,
          1
        )
      }
      return Yup.string().notRequired()
    }),
    scheduledDateAndTime: Yup.mixed().when("campaignType", {
      is: selectedCampaignType =>
        selectedCampaignType?.value === CAMPAIGN_TYPE_MAP.scheduled,
      then: customContentValidation("This field is required"),
      otherwise: customContentValidation(),
    }),
  })
}

export const getPlaceholdersData = (channel, template) => {
  console.log("__getPlaceholdersData", { channel, template })
  const defaultActivePlaceholdersCount = 0

  const placeholdersData = {
    isWhatsappTemplate: false,
    placeholdersCount: defaultActivePlaceholdersCount,
    headersPlaceholdersCount: defaultActivePlaceholdersCount,
    bodyPlaceholdersCount: defaultActivePlaceholdersCount,
  }

  if (channel === CAMPAIGN_CHANNEL_MAP.whatsapp) {
    placeholdersData.isWhatsappTemplate = true
    const components = template?.templateMessage?.components || []

    if (components.length > 0) {
      placeholdersData.headersPlaceholdersCount =
        getMatches(components.find(comp => comp.type === "HEADER")?.text || "")
          ?.length ?? defaultActivePlaceholdersCount
      placeholdersData.bodyPlaceholdersCount =
        getMatches(components.find(comp => comp.type === "BODY")?.text || "")
          .length ?? defaultActivePlaceholdersCount

      console.log("__components_", { components, placeholdersData })
      placeholdersData.placeholdersCount =
        placeholdersData.headersPlaceholdersCount +
        placeholdersData.bodyPlaceholdersCount
    }
  } else if (channel === CAMPAIGN_CHANNEL_MAP.email) {
    placeholdersData.isWhatsappTemplate = false
    const matches = getMatches(template?.emailBody || "")
    placeholdersData.placeholdersCount = matches?.length
  }

  console.log("_getPlaceholdersData", placeholdersData)
  return placeholdersData
}

const getIsAllAttributesFilledStatus = (record, recordKeys) => {
  if (record && recordKeys?.length > 0) {
    return recordKeys.every(key => {
      // if (key === CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.PHONE_NUMBER)
      //   return record[key]?.length >= 10
      // else
      return record[key]?.length > 0
    })
  }
  return false
}

export const validateAndUploadCampaignContacts = event => {
  return new Promise((resolve, reject) => {
    let excelSheetData = [],
      _excelSheetNames,
      alertMessageData,
      records = []

    try {
      const file = event.target?.files?.[0]

      if (file?.name) {
        const extension = file.name.substr(
          file.name.lastIndexOf(".") + 1,
          file.name.length
        )

        if (extension == "xlsx" || extension == "xls") {
          const reader = new FileReader()

          reader.onload = e => {
            const arrayBuffer = e.target.result,
              data = new Uint8Array(arrayBuffer),
              arr = new Array()
            for (let i = 0; i !== data.length; ++i) {
              arr[i] = String.fromCharCode(data[i])
            }

            const bstr = arr.join("")
            const wb = XLSX.read(bstr, { type: "binary" })
            _excelSheetNames = []
            excelSheetData = []
            _excelSheetNames.push(wb.SheetNames[0])
            let wsname = wb.SheetNames[0]
            let ws = wb.Sheets[wsname]
            let headers = []
            let range = XLSX.utils.decode_range(ws["!ref"] ? ws["!ref"] : "")
            let C,
              R = range.s.r
            for (C = range.s.c; C <= range.e.c; ++C) {
              let cell =
                ws[
                  XLSX.utils.encode_cell({ c: C, r: R })
                ] /* find the cell in the first row */
              let hdr = "" // <-- replace with your desired default
              if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
              headers.push((hdr + "").trim())
            }
            console.log("headers=>", headers, wb.SheetNames[0])

            let index3 = headers.findIndex(x => x == null || x == "")
            let index4 = _.uniq(
              _.filter(headers, (v, i, a) => a.findIndex(x => v == x) !== i)
            )
            if (index3 == -1 && index4.length == 0) {
              let data1 = XLSX.utils.sheet_to_json(ws, {
                header: 0,
                defval: "",
                raw: false,
              })
              excelSheetData.push({ value: data1, viewValue: wsname })
            } else {
              _excelSheetNames.pop()
            }

            if (_excelSheetNames.length == 1 && excelSheetData) {
              let columndata = excelSheetData.filter(
                x => x.viewValue == _excelSheetNames[0]
              )[0].value
              if (columndata.length > 0) {
                const keys = Object.keys(columndata[0])
                const uploadedContactsArr = columndata.map(data =>
                  _.pick(data, keys)
                )
                const recordKeys = Object.keys(uploadedContactsArr[0])
                const isValidRecords = uploadedContactsArr.every(record =>
                  getIsAllAttributesFilledStatus(record, recordKeys)
                )

                if (isValidRecords) {
                  records = uploadedContactsArr
                } else {
                  records = []
                  alertMessageData = {
                    status: false,
                    message:
                      "Please fill all the required fields, Also make sure the mobile number's entered are valid",
                  }
                }
              } else {
                alertMessageData = {
                  status: false,
                  message:
                    "Please upload the file with valid contacts data as per the selected template",
                }
              }
            } else {
              alertMessageData = {
                status: false,
                message: "Invalid data found in the uploaded excel file",
              }
            }

            resolve({ records, alertMessageData })
          }
          reader.readAsArrayBuffer(file)
        } else {
          alertMessageData = {
            status: false,
            message: "Invalid Format.Please upload XLSX file type only",
          }
        }
      }
    } catch (err) {
      alertMessageData = {
        status: false,
        message: err.message ?? "Unable to parse the uploaded excel",
      }
      reject({ records, alertMessageData })
    }
  })
}
export const getDropdownOptionsFromCodeValues = codeValues => {
  let occupationOptions = [],
    staffFlagOptions = [],
    customerTypeOptions = [],
    categoryOptions = [],
    statusOptions = []

  if (codeValues?.length > 0) {
    codeValues.forEach((codeValue, idx, arr) => {
      const code = Object.keys(codeValue)[0]
      const options =
        arr[idx][code]?.map(option => ({
          label: option.valueName,
          value: option.valueName,
          obj: option,
        })) || []

      switch (code) {
        case CODE_VALUES_MAP.CATEGORY:
          categoryOptions.push(...options)
          break
        case CODE_VALUES_MAP.CUSTOMER_TYPE:
          customerTypeOptions.push(...options)
          break
        case CODE_VALUES_MAP.OCCUPATION:
          occupationOptions.push(...options)
          break
        case CODE_VALUES_MAP.STAFF_FLAG:
          staffFlagOptions.push(...options)
          break
        case CODE_VALUES_MAP.STATUS:
          statusOptions.push(...options)
          break
      }
    })
  }

  return {
    occupationOptions,
    staffFlagOptions,
    customerTypeOptions,
    categoryOptions,
    statusOptions,
  }
}

const getParameterObj = (attribute, includeAttrType = false, isDivide = false) => {
  console.log("______attribute", isDivide, attribute)

  switch (attribute?.type) {
    case CAMPAIGN_ATTRIBUTE_TYPES.TEXT:
      const obj = {
        type: attribute?.type || "",
        text: attribute?.text || "",
      }

      if (includeAttrType) {
        obj["attributeType"] = attribute?.attributeType || ""
      }

      return obj
    case CAMPAIGN_ATTRIBUTE_TYPES.CURRENCY:
      return {
        type: attribute?.type || "",
        currency: {
          ...attribute?.currency,
          amount_1000: isDivide
            ? attribute?.currency?.amount_1000 / 1000
            : attribute?.currency?.amount_1000 * 1000,
        },
      }
    case CAMPAIGN_ATTRIBUTE_TYPES.DATE_TIME:
      return {
        type: attribute?.type || "",
        date_time: attribute?.date_time,
      }
    default:
      console.error("getParameterObj_default_case", { attribute })
      return {}
  }
}

export const getContactAttributesForApi = attributes => {
  let isUpdatedHeaderMediaUrlAdded = false
  const updatedHeaderMediaUrl =
    store?.getState()?.campaignManagementReducer?.updatedHeaderMediaUrl
  const updatedFileType =
    store?.getState()?.campaignManagementReducer?.updatedFileType

  const headerAttrObj = {
    type: "header",
    parameters: [],
  }
  const bodyAttrObj = {
    type: "body",
    parameters: [],
  }
  if (updatedHeaderMediaUrl && !isUpdatedHeaderMediaUrlAdded) {
    isUpdatedHeaderMediaUrlAdded = true

    headerAttrObj.parameters.push({
      type: updatedFileType,
      [updatedFileType]: {
        link: updatedHeaderMediaUrl,
      },
    })
  }
  attributes?.forEach(attribute => {
    if (attribute?.isHeaderPlaceholder) {
      headerAttrObj.parameters.push(getParameterObj(attribute))
    } else {
      bodyAttrObj.parameters.push(getParameterObj(attribute))
    }

    // media update is only for whatsapp header attributes

    if (updatedHeaderMediaUrl && !isUpdatedHeaderMediaUrlAdded) {
      isUpdatedHeaderMediaUrlAdded = true

      headerAttrObj.parameters.push({
        type: updatedFileType,
        [updatedFileType]: {
          link: updatedHeaderMediaUrl,
        },
      })
    }
  })

  return [headerAttrObj, bodyAttrObj].filter(obj => obj.parameters.length > 0)
}

export const getContactAttributesFromApi = attributes => {
  const framedAttributes = []

  attributes?.forEach(attribute => {
    const isHeaderPlaceholder = attribute?.type === "header"
    attribute?.parameters?.forEach(param => {
      const attr = { ...getParameterObj(param, true, true) }

      if (!isEmpty(attr)) {
        attr.isHeaderPlaceholder = isHeaderPlaceholder
        framedAttributes.push(attr)
      }
    })
  })

  return framedAttributes
}

export const getCampaignPlaceholdersDataByAttributes = (
  contact,
  campaignChannel
) => {
  const placeholdersData = {}

  if (campaignChannel === CAMPAIGN_CHANNEL_MAP.whatsapp) {
    const headerPlaceholdersCount =
      contact?.attributes?.filter(attr => attr.isHeaderPlaceholder === true)
        ?.length || 0
    const bodyPlaceholdersCount =
      contact?.attributes?.filter(attr => attr.isHeaderPlaceholder === false)
        ?.length || 0

    placeholdersData.isWhatsappTemplate = true
    placeholdersData.headersPlaceholdersCount = headerPlaceholdersCount
    placeholdersData.bodyPlaceholdersCount = bodyPlaceholdersCount
    placeholdersData.placeholdersCount =
      headerPlaceholdersCount + bodyPlaceholdersCount
  } else {
    placeholdersData.isWhatsappTemplate = false
    placeholdersData.headersPlaceholdersCount = 0
    placeholdersData.bodyPlaceholdersCount = 0
    placeholdersData.placeholdersCount =
      contact?.attributes?.filter(attr => attr.type === "text")?.length || 0
  }

  return placeholdersData
}

export const downloadContactUploadsExcel = (placeholdersData, contactsToRender) => {
  try {
    let excelData = []
    const isWhatsappTemplate = placeholdersData?.isWhatsappTemplate

    const defaultColumns = [
      CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.NAME,
      "",
      CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.SEND_MESSAGE,
    ]
    defaultColumns[1] = isWhatsappTemplate
      ? CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.PHONE_NUMBER
      : CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.EMAIL_ID

    const columns = [...defaultColumns]

    if (isWhatsappTemplate) {
      if (placeholdersData?.headersPlaceholdersCount >= 1) {
        const whatsappHeaderAttributes = Array.from({
          length: placeholdersData.headersPlaceholdersCount,
        })?.map((_, attributeIdx) => {
          const attributeId = attributeIdx + 1
          return `Header {{"${attributeId}"}}`
        })
        columns.push(...whatsappHeaderAttributes)
      }
      if (placeholdersData?.bodyPlaceholdersCount >= 1) {
        const whatsappBodyAttributes = Array.from({
          length: placeholdersData.bodyPlaceholdersCount,
        })?.map((_, attributeIdx) => {
          const attributeId = attributeIdx + 1
          return `Body {{"${attributeId}"}}`
        })
        columns.push(...whatsappBodyAttributes)
      }
    } else {
      if (placeholdersData?.placeholdersCount >= 1) {
        const emailAttributes = Array.from({
          length: placeholdersData?.placeholdersCount,
        })?.map((_, attributeIdx) => {
          const attributeId = attributeIdx + 1
          return `Attribute {{"${attributeId}"}}`
        })
        columns.push(...emailAttributes)
      }
    }

    if (contactsToRender?.length === 0) {
      const contactItem = {}

      columns.forEach(column => {
        contactItem[column] = ""
      })

      excelData.push(contactItem)
    } else {
      excelData = contactsToRender?.map(contact => {
        const contactItem = {
          [CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.NAME]:
            contact?.contactName || "",
          [defaultColumns[1]]: isWhatsappTemplate
            ? contact?.contactNo
            : contact?.emailId || "",
          [CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP.SEND_MESSAGE]:
            contact?.isMarketOptOutContact === true ? "no" : "yes",
        }

        contact?.attributes?.forEach((attr, idx) => {
          contactItem[columns[defaultColumns.length + idx]] =
            getCampaignContactAttributeValue(attr)
        })

        return contactItem
      })
    }

    console.log("___exportDataIntoCsvOrExcel", { columns, excelData })
    exportDataIntoCsvOrExcel({
      fileName: "Campaign Contacts Sample Upload.xlsx",
      fileData: excelData,
      fileType: "xlsx",
    })
  } catch (err) {
    console.error("_downloadContactUploadsExcel_catch", err)
  }
}

export const getContactAttributesByParams = contact => {
  const attributes = []

  contact?.attributes?.forEach(attr => {
    attr?.parameters?.forEach(param => {
      attributes.push({
        type: attr?.type === "header",
        value: param?.text,
        param,
      })
    })
  })

  return attributes
}

export const getSelectedContacts = campaignItem => {
  return (
    campaignItem?.contacts?.map(contact => ({
      contactId: contact?.contactId || contact?.id || 0,
      contactName: contact?.contact_name || "",
      contactNo: contact?.contact_phone_no || "",
      emailId: contact?.contact_email_address || "",
      attributes: contact?.attributes,
    })) || []
  )
}

// Recurring Campaign helpers
export const getDateNumberSuffix = number => {
  if (number > 3 && number < 21) return "th"
  switch (number % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

export const getSelectedEveryType = state => {
  const selectedRepeatedValue = state.repeat?.value
  const selectedEveryValue = state.every?.value
  const selectedOn = state.on

  switch (selectedRepeatedValue) {
    case FREQUENCY_MAP.DAILY:
      return `${selectedEveryValue ? `${selectedEveryValue} days` : "day"}`
    case FREQUENCY_MAP.WEEKLY:
      let sortedDayNames = ""
      if (Array.isArray(selectedOn) && selectedOn.length > 0) {
        const sortedDayNamesArr = selectedOn
          .slice()
          .sort((a, b) => DAYS_SORTER[a] - DAYS_SORTER[b])
        sortedDayNames = sortedDayNamesArr.join(", ")
      }
      return `${
        Number(selectedEveryValue) > 1 ? `${selectedEveryValue} week's` : "week"
      } ${sortedDayNames ? `on ${sortedDayNames}` : ""}`
    case FREQUENCY_MAP.MONTHLY:
      let monthSuffix = ""

      if (state.isPossibleMonthDaysSelected && selectedOn?.value) {
        const selectedOnValue = selectedOn.value
        monthSuffix = `${selectedOnValue} ${getDateNumberSuffix(
          selectedOnValue
        )} day of month`
      }
      if (state.isStarterDaysByMonthSelected && selectedOn?.value && !monthSuffix) {
        monthSuffix = `${selectedOn.label} of month`
      }
      return `${monthSuffix ? `${monthSuffix} for every` : ""} ${
        Number(selectedEveryValue) > 1 ? `${selectedEveryValue} month's` : "month"
      }`
    case FREQUENCY_MAP.YEARLY:
      const selectedMonth = state.every?.value ? state.every?.label : ""
      const selectedDay =
        state.isPossibleMonthDaysSelected && selectedOn?.value
          ? selectedOn.value
          : state.isStarterDaysByMonthSelected && selectedOn?.value
          ? selectedOn?.label
          : ""
      return selectedMonth || selectedDay
        ? `${selectedMonth} ${selectedDay}`
        : "year"
  }
}

export const getStartingFrom = selectedStartDate => {
  if (selectedStartDate) {
    const date = selectedStartDate?.getDate()
    const month = MONTH_NAMES[selectedStartDate?.getMonth() || 0].label
    const year = selectedStartDate?.getFullYear()

    return `starting ${date} ${month} ${year}`
  }
  return ""
}

export const getEndingDate = state => {
  const selectedEndType = state.endType?.value
  const selectedEndDate = state.end

  if (selectedEndDate) {
    const date = selectedEndDate?.getDate()
    const month = MONTH_NAMES[selectedEndDate?.getMonth() || 0].label
    const year = selectedEndDate?.getFullYear()
    const preffix =
      selectedEndType === END_DATE_TYPES_MAP.ON_THIS_DAY ? "on" : "after"

    return `${state.start ? "and" : ""} ends ${preffix} ${date} ${month} ${year}`
  }
  return ""
}

export const getDateObjFromStr = (
  dateStr,
  formatType = MOMENT_DATE_FORMAT_TYPES["MM/DD/YYYY"]
) => {
  if (dateStr) {
    try {
      const formatedDateStr = moment(dateStr).format(formatType)
      const timestamp = Date.parse(formatedDateStr)
      return new Date(timestamp)
    } catch (err) {
      console.error("_getDateObjFromStr_catch", err)
      return dateStr
    }
  }
  return dateStr
}

export const getStrFromDateObj = (
  dateObj,
  formatType = MOMENT_DATE_FORMAT_TYPES["MM/DD/YYYY"]
) => {
  if (dateObj) {
    try {
      return moment(dateObj).format(formatType)
    } catch (err) {
      console.error("_getStrFromDateObj_catch", err)
      return dateObj
    }
  }
  return dateObj
}

export const getCampaignContactAttributeValue = (
  attribute,
  isCampCreateOrUPdte = false
) => {
  const attrObj = attribute

  switch (attrObj?.type) {
    case CAMPAIGN_ATTRIBUTE_TYPES.TEXT:
      return typeof attrObj?.text === "object"
        ? attrObj?.text?.codeValueDispVal
        : attrObj?.text
    case CAMPAIGN_ATTRIBUTE_TYPES.DATE_TIME:
      return attrObj?.date_time?.fallback_value
    case CAMPAIGN_ATTRIBUTE_TYPES.CURRENCY:
      if (attrObj?.currency?.amount_1000 && attrObj?.currency?.code) {
        return `${
          isCampCreateOrUPdte
            ? attrObj.currency.amount_1000
            : attrObj.currency.amount_1000
        } ${attrObj.currency.code}`
      }
      return ""
    default:
      return (
        attrObj?.text || attrObj?.customValue || attrObj?.isHeaderPlaceholder || ""
      )
  }
}

// Campaign Insights helpers
export const getPercentageValue = value => {
  const defaultValue = 0

  if (value) {
    const valueType = typeof value

    if (valueType === "number") return value
    else if (valueType === "string") return Number(value)
    else return defaultValue
  }
  return defaultValue
}

const itemMap = {
  INTERESTED: "Interested",
  NOT_INTERESTED: "Not Interested",
  STOP_PROMOTIONS: "Stop Promotions",
  OTHERS: "Others",
}
export const getItemColor = item => {
  switch (item?.text) {
    case itemMap.INTERESTED:
      return "#68A61D"
    case itemMap.NOT_INTERESTED:
      return "#12B886"
    case itemMap.STOP_PROMOTIONS:
      return "#007ED6"
    case itemMap.OTHERS:
      return "#FF7300"
    default:
      if (item.type === itemMap.OTHERS) return "#FF7300"
      else return item?.text || item?.type
  }
}

export const renderInsightsBarChart = percentages => {
  const chartDom = document.getElementById("insightsBarChart")
  const myChart = echarts.init(chartDom)
  const option = {
    xAxis: {
      type: "category",
      data: ["Total", "Sent", "Delivered", "Read", "Replied", "Failed"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [
          {
            value: getPercentageValue(percentages?.total),
            itemStyle: {
              color: "#2F2FABE5",
            },
          },
          {
            value: getPercentageValue(percentages?.sent),
            itemStyle: {
              color: "#0B9854",
            },
          },
          {
            value: getPercentageValue(percentages?.delivered),
            itemStyle: {
              color: "#0470B1",
            },
          },
          {
            value: getPercentageValue(percentages?.read),
            itemStyle: {
              color: "#00A5EE",
            },
          },
          {
            value: getPercentageValue(percentages?.replied),
            itemStyle: {
              color: "#7928C5",
            },
          },
          {
            value: getPercentageValue(percentages?.failed),
            itemStyle: {
              color: "#E85959",
            },
          },
        ],
        type: "bar",
      },
    ],
  }

  option && myChart.setOption(option)
}

export const renderInsightsPieChart = replies => {
  const chartDom = document.getElementById("insightsPieChart")
  const myChart = echarts.init(chartDom)
  const option = {
    title: {
      text: replies?.totalReplies ? `${replies.totalReplies} Replied` : "",
      left: "center",
      top: "center",
      textStyle: {
        fontSize: 30,
      },
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "Stats",
        type: "pie",
        radius: "55%",
        center: ["40%", "50%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data: replies?.totals?.map(item => ({
          value: item?.total,
          name: item?.text || item?.type,
          itemStyle: { color: getItemColor(item) },
        })),
        // data: [
        //     { value: 1048, name: 'Interested', itemStyle: { color: '#68A61D' } },
        //     { value: 735, name: 'Not Interested', itemStyle: { color: '#12B886' } },
        //     {
        //         value: 580,
        //         name: 'Stop Promotions',
        //         itemStyle: { color: '#007ED6' }
        //     },
        //     { value: 484, name: 'Others', itemStyle: { color: '#FF7300' } }
        // ],
      },
    ],
  }

  option && myChart.setOption(option)
}

export const getCampaignAttrTypeStrByAttrMap = attributesMap => {
  if (attributesMap?.length) {
    return (
      attributesMap
        ?.map(attr => {
          if (attr?.objectKey) {
            return attr.objectKey
          } else if (
            attr?.currency &&
            attr.currency?.amount_1000 &&
            attr.currency?.code
          ) {
            return `currency_${attr.currency.amount_1000} ${attr.currency.code}`
          } else if (attr?.dateTime?.fallback_value) {
            return `dateTime_${attr.dateTime.fallback_value}`
          } else if (attr?.custom) {
            return attr?.custom
          }
        })
        ?.toString() || ""
    )
  }
  return ""
}

export const getCurrentDateObj = () => new Date()

export const getScheduledCampaignMaxTime = () => {
  const dateObj = getCurrentDateObj()
  const currentTime = dateObj.getTime()
  const hoursToAdd = 24 - dateObj.getHours()
  const maxTime = new Date(currentTime + hoursToAdd * 60 * 60 * 1000)
  return maxTime
}

export const getDefaultContactAttributes = placeholdersCount => {
  return Array.from({ length: placeholdersCount || 0 }, () => {
    return {
      type: CAMPAIGN_ATTRIBUTE_TYPES.TEXT,
      text: "",
      attributeType: "",
      currency: {
        fallback_value: "VALUE",
        code: "",
        amount_1000: "",
      },
      date_time: {
        fallback_value: "",
      },
      customValue: "",
    }
  })
}

const getDatePickerHoursOptions = () => {
  const date = new Date()
  const hours = date.getHours()

  const restrictedTillHour = hours + 1 === 24 ? 23 : hours + 1
  const restrictedTillMinutes = restrictedTillHour === 23 ? 59 : 0
  return setHours(setMinutes(date, restrictedTillMinutes), restrictedTillHour)
}

export const getMinTimeForScheduledCampaign = scheduledDateAndTime => {
  if (scheduledDateAndTime) {
    const dateTime = moment(scheduledDateAndTime).format("MM/DD/YYYY")
    const timestamp = Date.parse(dateTime)
    const dateObject = new Date(timestamp)
    const currentDate = new Date()

    if (
      dateObject.getFullYear() === currentDate.getFullYear() &&
      dateObject.getMonth() === currentDate.getMonth() &&
      dateObject.getDay() === currentDate.getDay()
    ) {
      return getDatePickerHoursOptions()
    }
    return setHours(setMinutes(currentDate, 0), 24)
  }
  return getDatePickerHoursOptions()
}

export const getMaxTimeForScheduledCampaign = () =>
  setHours(setMinutes(new Date(), 59), 23)

export const getIsSafeUpdateTime = campaignRunDate => {
  if (campaignRunDate) {
    const startTime = moment(
      new Date(),
      MOMENT_DATE_FORMAT_TYPES["YYYY-MM-DD HH:mm:ss"]
    )
    const endTime = moment(
      campaignRunDate,
      MOMENT_DATE_FORMAT_TYPES["YYYY-MM-DD HH:mm:ss"]
    )

    const minutesDiff = endTime.diff(startTime, "minutes")
    return minutesDiff >= SCHEDULED_CAMPAIGN_SAFE_BUFFER_TIME_IN_MINS
  }
  return true
}

export const getFileExtension = file =>
  file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length)

export const getFileType = file => {
  const extension = getFileExtension(file)

  if (ACCEPTED_IMAGE_FILE_TYPES.includes(extension)) {
    return FILE_TYPES_MAP.IMAGE
  } else if (ACCEPTED_DOCUMENT_FILE_TYPES.includes(extension)) {
    return FILE_TYPES_MAP.DOCUMENT
  } else if (ACCEPTED_VIDEO_FILE_TYPES.includes(extension)) {
    return FILE_TYPES_MAP.VIDEO
  }
}

export const getMaxFileSizeByExtension = extension => {
  const extensionType = ACCEPTED_IMAGE_FILE_TYPES.includes(extension)
    ? FILE_TYPES_MAP.IMAGE
    : ACCEPTED_VIDEO_FILE_TYPES.includes(extension)
    ? FILE_TYPES_MAP.VIDEO
    : ACCEPTED_DOCUMENT_FILE_TYPES.includes(extension)
    ? FILE_TYPES_MAP.DOCUMENT
    : ""

  switch (extensionType) {
    case FILE_TYPES_MAP.IMAGE:
      return WHATSAPP_MAX_FILE_SIZE_MAP.IMAGE
    case FILE_TYPES_MAP.VIDEO:
      return WHATSAPP_MAX_FILE_SIZE_MAP.VIDEO
    case FILE_TYPES_MAP.DOCUMENT:
      return WHATSAPP_MAX_FILE_SIZE_MAP.DOCUMENT
    default:
      return WHATSAPP_MAX_FILE_SIZE_MAP.DEFAULT
  }
}
