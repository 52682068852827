import * as types from "./actionTypes"
import {
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTION_LOADING,
} from "../../common/actionTypes"

export const resetLoginSate = () => ({
  type: types.RESET_LOGIN_STATE,
})
export const logOut = history => {
  return {
    type: types.ACTION_LOGOUT,
    payload: history,
    loadType: ACTION_LOADING,
    loadPayload: false,
  }
}
export const loginRequest = (loginData, history, setFieldValue) => ({
  type: types.GET_LOGIN_REQUEST,
  payload: { loginData, history, setFieldValue },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const loginResponse = (token, userDto) => ({
  type: types.GET_LOGIN_RESPONSE,
  payload: { token, userDto },
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const loginAlertMessage = alertMessageData => ({
  type: types.GET_LOGIN_ALERT_MESSAGE,
  // payload:alertMessageData,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const verificationRequest = (loginData, otp, history) => ({
  type: types.GET_VERIFICATION_REQUEST,
  payload: { loginData, otp, history },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const verificationResponse = userData => ({
  type: types.GET_VERIFICATON_RESPONSE,
  payload: userData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const verificationAlertMessage = alertMessageData => ({
  type: types.GET_VERIFICATION_ALERT_MESSAGE,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const reSendOtpRequest = userInfo => ({
  type: types.RESEND_OTP_REQUEST,
  payload: userInfo,
  // loadType: ACTION_LOADING,
  // loadPayload: true
})
export const reSendOtpResponse = alertMessageData => ({
  type: types.RESEND_OTP_RESPONSE,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const reSendOtpAlertMessage = alertMessageData => ({
  type: types.RESEND_OTP_ALERT_MESSAGE,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})

export const isLoginModelOpen = isOpenLoginModel => ({
  type: types.IS_LOGIN_MODEL_OPEN_OR_CLOSE,
  payload: isOpenLoginModel,
})
export const isVerificationModelOpen = isOpenVerificationModel => ({
  type: types.IS_VERIFICATION_MODEL_OPEN_OR_CLOSE,
  payload: isOpenVerificationModel,
})

export const socialLogin = (data, history) => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  payload: { data, history },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const setSessionStateData = (sessionState, token, wbaDetails = undefined) => {
  return {
    type: types.SET_SESSION_STATE_DATA,
    payload: { sessionState, token, wbaDetails },
  }
}
export const setSubscriptionModalOpen = isSubscriptionModalOpen => ({
  type: types.IS_SESSION_MODEL_OPEN_OR_CLOSE,
  payload: isSubscriptionModalOpen,
})

export const isLoginLoading = isLoginLoading => ({
  type: types.IS_LOGIN_LOADING,
  payload: isLoginLoading,
})
