import * as XLSX from "xlsx"
import * as _ from "lodash"
import { templateStatus } from "../constants/index"
// import { ExportToCsv } from 'export-to-csv';

export const DownloadExcel = (data, downloadName, mappingData = []) => {
  let ws = XLSX.utils.json_to_sheet(data)
  let ws2 = XLSX.utils.json_to_sheet(mappingData)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
  XLSX.utils.book_append_sheet(wb, ws2, "Mapping")
  const rangeKey = ws["!ref"] ? ws["!ref"] : ""
  if (rangeKey !== "") {
    const range = XLSX.utils.decode_range(rangeKey)
    for (let C = range.s.r; C <= range.e.c; ++C) {
      let address = XLSX.utils.encode_col(C) + "1" // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v
    }
  }
  XLSX.writeFile(wb, downloadName)
  return true
}
export const getFilteredDataAfterSearchData = (data, filterObj) => {
  // Iterate over all keys in the filter object
  const filterKeys = Object.keys(filterObj)

  // Apply filtering for each key
  filterKeys.forEach(filter => {
    // Split the filter key into nested properties if necessary
    const filterParts = filter.split(".")
    const filterValue = filterObj[filter]?.toLowerCase()

    if (
      filterValue === "select" ||
      filterValue === "" ||
      filterValue === undefined
    ) {
      // If the filter value is "Select" or empty, no filtering should be applied
      return
    }

    data = data.filter(item => {
      // Navigate through the item using the filter parts
      let value = item
      for (const part of filterParts) {
        value = value?.[part]
        if (value === undefined) return false
      }

      // Compare the value with the filter value (case-insensitive)
      return value?.toLowerCase().includes(filterValue)
    })
  })

  return data
}

export const getFilteredDataAfterSearchDataForMulipleKeys = (data, filterObj) => {
  if (filterObj) {
    const keysData = Object.keys(filterObj)
    return data.filter(x => {
      let accptCount = 0
      keysData.forEach(y => {
        if (filterObj[y] == "All") {
          accptCount++
          return data
        } else {
          if (typeof filterObj[y] === "string") {
            if (
              x?.[y]
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(filterObj[y]?.toLowerCase()) !== -1
            )
              accptCount++
          } else {
            const subkeysData = Object.keys(filterObj[y])
            if (
              (subkeysData?.[0] &&
                x?.[subkeysData?.[0]]
                  ?.toString()
                  ?.toLowerCase()
                  ?.indexOf(filterObj[y][subkeysData?.[0]]?.toLowerCase()) !== -1) ||
              (subkeysData?.[1] &&
                x?.[subkeysData?.[1]]
                  ?.toString()
                  ?.toLowerCase()
                  ?.indexOf(filterObj[y][subkeysData?.[1]]?.toLowerCase()) !== -1) ||
              (subkeysData?.[2] &&
                x?.[subkeysData?.[2]]
                  ?.toString()
                  ?.toLowerCase()
                  ?.indexOf(filterObj[y][subkeysData?.[2]]?.toLowerCase()) !== -1)
            )
              accptCount++
          }
        }
      })
      return keysData?.length === accptCount
    })
  } else {
    return data
  }
}
export const matchTemplateVariableCountRegEx = new RegExp(
  /((?<!\w){{\w+)|((?<!\w ){{\w+)/gm
)
const matchPlaceHolderTextRegEx = new RegExp(
  /((?<!\w){{\w+}}+)|((?<!\w ){{\w+}}+)/gm
)
export const getTemplateVariableNextCount = str => {
  const matches = str.match(matchTemplateVariableCountRegEx)
  if (matches && matches.length) {
    return Math.max(...matches.map(matchStr => Number(matchStr.substr(-1)))) + 1
  }
  return 1
}
export const getAllPlaceHoldersFromText = str => {
  const matches = str.match(matchPlaceHolderTextRegEx)
  return matches
}
export const getAllPlaceHoldersFromTextForBodyText = str => {
  const matches = str.match(/ {{[a-zA-Z1-9_]+\}} |{{[a-zA-Z1-9_]+\}} /gm)
  return matches
}

export const getStatusClassNames = status => {
  switch (status.toUpperCase()) {
    case templateStatus.PENDING:
      return "pending"
    case templateStatus.NOT_STARTED:
      return "notstarted"
    case templateStatus.SENT:
      return "reject-icon"
    case templateStatus.REJECTED:
      return "rejected"
    case templateStatus.INPROGESS:
      return "inprogress"
    case templateStatus.Started:
      return "started"
    case templateStatus.APPROVED:
      return "approve"
    case templateStatus.INREVIEW:
      return "inreview"
    case templateStatus.PENDING_DELETION:
      return "pending"
    default:
      return ""
  }
}
export const getTemplatesStatus = status => {
  switch (status.toUpperCase()) {
    case templateStatus.PENDING:
      return "Pending"
    case templateStatus.NOT_STARTED:
      return templateStatus.NOT_STARTED
    case templateStatus.SENT:
      return templateStatus.SENT
    case templateStatus.REJECTED:
      return "Rejected"
    case templateStatus.INPROGESS:
      return "In progress"
    case templateStatus.Started:
      return "Started"
    case templateStatus.INREVIEW:
      return "In Review"
    case templateStatus.APPROVED:
      return "Approved"
    case templateStatus.PENDING_DELETION:
      return "Pending Deletion"
    default:
      return ""
  }
}
export const getTextAvatar = text => {
  if (text) {
    const avatar = text?.split(" ")
    if (avatar.length > 1) {
      return avatar[0][0] + avatar[1][0]
    } else if (avatar.length === 1) {
      return avatar[0][0] + avatar[0][1]
    } else {
      return "PN"
    }
  }
  return "PN"
}

export const getSubscriptionStatus = status => {
  let statusData = {
    active: "active",
    pending: "pending",
    created: "created",
    expired: "expired",
    halted: "halted",
  }
  return statusData[status] || ""
}
