import * as types from "./actionTypes"
const initialState = {
  agentDetails: undefined,
  getAgentsLoading: false,
  rolesAndPermissions: undefined,
}
const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADMIN_AGENTS_REQUEST:
      state = {
        ...state,
        getAgentsLoading: true,
      }
      break
    case types.GET_ADMIN_AGENTS_RESPONSE:
      state = {
        ...state,
        agentDetails: action?.payload,
        getAgentsLoading: false,
      }
      break
    case types.GET_USER_ROLES_PERMISSIONS_REQUEST:
      state = {
        ...state,
      }
      break
    case types.GET_USER_ROLES_PERMISSIONS_RESPONSE:
      state = {
        ...state,
        rolesAndPermissions: action?.payload?.rolesAndPermissions,
      }
      break

    default:
      state = { ...state }
  }
  return state
}
export default userManagementReducer
