import * as types from "./actionTypes"
import * as actions from "./actions"
import {
  call,
  takeLatest,
  all,
  fork,
  put,
  select,
  takeLeading,
} from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post, Delete } from "../../helpers/api_helper"
import { fileUploader } from "../helpers"
import { ACTION_TYPES, templateStatus } from "../../constants"
import { setSessionStateData } from "../auth/login/actions"
import { getAdminAgentsResponse } from "store/actions"

function* getProfileDetails() {
  let profileDetails = []
  let alertMessagesData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const response = yield call(Post, `${urls.GET_PROFILE_DETAILS}?cin=${cin}`)
    console.log("responase=>", response)
    if (response && response.status) {
      profileDetails = response.data
    } else {
      alertMessagesData = {
        status: response.status ?? false,
        message: response?.data?.message || "Unable To Get Profile Details",
      }
    }
  } catch (err) {
    alertMessagesData = {
      status: false,
      message: err?.data?.message || "Failed to fetch Profile Details",
    }
  }
  return { profileDetails, alertMessagesData }
}
function* getProfileDetailsWatcher() {
  yield takeLatest(types.GET_PROFILE_DETAILS_REQUEST, getProfileDetailsRequest)
}
function* getProfileDetailsRequest() {
  let { profileDetails, alertMessagesData } = yield call(getProfileDetails)
  console.log("getProfileDetailsRequest=>", profileDetails, alertMessagesData)
  yield put(actions.getProfileDetilsResponse(profileDetails, alertMessagesData))
}
function* uploadFileDataWatcher() {
  yield takeLatest(types.UPLOADING_PROFILE_FILE_DATA_REQUEST, uploadFileDataRequest)
}
function* uploadFileDataRequest(action) {
  let fileDataResponse, alertMessageData
  console.log("filesResponse=>", action)
  let { requestData } = action.payload
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    // url, data, auth = true, isUpload = false
    const { filesResponse, fileResponseAlertMessageData } = yield call(
      Post,
      fileUploader,
      requestData,
      `${urls.EMAIL_TEMPLATE_ATTACHMENTS_UPLOAD}?cin=${cin}`,
      true
    )
    console.log("request", filesResponse)

    console.log("filesResponse=>", filesResponse, fileResponseAlertMessageData)
    fileDataResponse = filesResponse
    if (fileDataResponse?.[0]?.status) {
      alertMessageData = {
        status: fileDataResponse?.[0]?.status ?? false,
        message: fileDataResponse?.[0]?.message || "Some thing went worng",
      }
    } else alertMessageData = fileResponseAlertMessageData
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to upload the File",
    }
  }
  yield put(actions.uploadingProfileFileResponse(fileDataResponse, alertMessageData))
}
function* changePasswordWatcher() {
  yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePassWordDataRequest)
}
function* changePassWordDataRequest(action) {
  let resonseData, alertMessageData
  let { requestData, setFieldValue, setFieldTouched } = action.payload
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData
    let requestObj = {
      cin: cin.ciNo,
      emailId: cin.emailId,
      newPassword: requestData.newPassword,
      oldPassword: requestData.currentPassWord,
    }
    const response = yield call(Post, `${urls.CHANGE_PASSWORD}`, requestObj)
    console.log("responase=>", response)
    if (response && response.data.status) {
      resonseData = response.data
      setFieldValue("currentPassWord", "")
      setFieldValue("newPassword", "")
      setFieldValue("retypePassWord", "")
      setFieldTouched("currentPassWord", false)
      setFieldTouched("newPassword", false)
      setFieldTouched("retypePassWord", false)
      alertMessageData = {
        status: response?.data?.status ?? true,
        message: response?.data?.message || "Password updated sucessfully",
      }
    } else {
      alertMessageData = {
        status: response?.data?.status ?? false,
        message: response?.data?.message || "Unable To Change Password",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed To Update the PassWord",
    }
  }
  yield put(actions.changepasswordResponse(resonseData, alertMessageData))
}
function* updateProfileDetailsWatcher() {
  yield takeLatest(types.UPDATE_PROFILE_DETAILS_REQUEST, updateProfileRequest)
}
function* updateProfileRequest(action) {
  let updatedResponse, alertMessageData, actionType
  console.log("updateProfileRequest", action.payload)
  let { requestData, profilePic } = action.payload
  let sessionStateData = (yield select())["loginReducer"]
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData
    let requestObj = profilePic
      ? { ...requestData, cin: cin.ciNo }
      : {
          addressCountry: requestData?.country?.valueName || "",
          address_type: requestData?.addrsstype?.refCodeValueCd || "",
          brandName: requestData?.organization || "",
          businessType: requestData?.businessType?.label || "",
          cin: cin.ciNo,
          city: requestData?.city || "",
          companyTag: requestData?.aboutCompany || "",
          companyWebsite: requestData?.companyWebsite || "",
          country: requestData?.country?.valueName || "",
          countryCode: requestData?.country?.codeValueCd || "",
          district: requestData?.district || "",
          emailId: requestData?.emailId || "",
          firstName: requestData?.firstName || "",
          jobTitle: requestData?.jobtitle || "",
          lastName: requestData?.lastName || "",
          message: "success",
          mobileNo: requestData?.mobile || "",
          postal_code: requestData?.postalCode || "",
          profileId: requestData?.profileId || "",
          profilePicUrl: requestData?.profilePicUrl || "",
          state: requestData?.state || "",
          status: true,
          streetLine1: requestData?.streetline1 || "",
          streetLine2: requestData?.streetline2 || "",
        }
    console.log("values=>", requestObj)
    const response = yield call(Post, `${urls.UPDATE_PROFILE_DETAILS}`, requestObj)
    if (response && response.data.status) {
      let { profileDetails, alertMessagesData } = yield call(getProfileDetails)
      updatedResponse = profileDetails
      actionType = undefined
      if (!alertMessagesData) {
        alertMessageData = {
          status: response.status ?? true,
          message: response?.data?.message || "Update ProfileDetails sucessfully",
        }
      } else {
        alertMessageData = alertMessagesData
      }
      yield put(
        setSessionStateData(
          {
            ...sessionStateData?.sessionStateData,
            profilePicUrl: requestData?.profilePicUrl,
          },
          sessionStateData?.authToken,
          sessionStateData?.wbaDetails
        )
      )
      yield put(actions.uploadingProfileFileResponse(undefined, undefined))
    } else {
      actionType = profilePic ? ACTION_TYPES.ADD : ACTION_TYPES.EDIT
      alertMessageData = {
        status: response.status ?? false,
        message: response?.data?.message || "Unable To update ProfileDetails",
      }
    }
  } catch (err) {
    console.log("err", err)
    actionType = profilePic ? ACTION_TYPES.ADD : ACTION_TYPES.EDIT
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed To Update the ProfileDeatils",
    }
  }
  yield put(
    actions.updateProfileDetailsResponse(
      updatedResponse,
      actionType,
      alertMessageData
    )
  )
}
function* getWhatsBusinessPhoneNoDetailsWatcher() {
  yield takeLatest(
    types.GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_REQUEST,
    getWhatsBusinessPhoneNoDetailsRequest
  )
}
function* getWhatsBusinessPhoneNoDetailsRequest(action) {
  let wbaPhoneNumbersDetails = []
  let alertMessageData
  let requestData = action?.payload
  console.log("wba_phone_no_id", action?.wba_phone_no_id, requestData)
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData
    if (requestData?.length) {
      for (let i = 0; i < requestData?.length; i++) {
        const response = yield call(
          Post,
          `${urls.PHONE_NO_ID_DETAILS}?cin=${cin.ciNo}&phoneNoId=${
            requestData?.[i]?.wba_phone_no_id || action?.wba_phone_no_Id
          }`
        )
        console.log("responseData=>", response)
        if (response.data.status) {
          wbaPhoneNumbersDetails = [...wbaPhoneNumbersDetails, response.data.data]
          console.log("responseData=>", wbaPhoneNumbersDetails)
        } else {
          wbaPhoneNumbersDetails = wbaPhoneNumbersDetails
          alertMessageData = {
            status: false,
            message: response?.message || "Unable To Get The WBA Account Detials",
          }
        }
      }
    } else {
      const response = yield call(
        Post,
        `${urls.PHONE_NO_ID_DETAILS}?cin=${cin.ciNo}&phoneNoId=${action?.wba_phone_no_id}`
      )
      console.log("responseData=>", response)
      if (response.data.status) {
        wbaPhoneNumbersDetails = [...wbaPhoneNumbersDetails, response.data.data]
        console.log("responseData=>", wbaPhoneNumbersDetails)
      } else {
        wbaPhoneNumbersDetails = wbaPhoneNumbersDetails
        alertMessageData = {
          status: false,
          message: response?.message || "Unable To Get The WBA Account Detials",
        }
      }
    }
  } catch (err) {
    console.log("error", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed To get the WBA Account Details",
    }
  }
  yield put(
    actions.getWAPProfileAccountDetilsResponse(
      wbaPhoneNumbersDetails,
      alertMessageData
    )
  )
}
function* getWhatsPhoneNoDetailsWatcher() {
  yield takeLatest(
    types.GET_ALL_CREATED_PHONE_NUMBERS_REQUEST,
    getWhatsPhoneNoDetailsRequest
  )
}
function* getWhatsPhoneNoDetailsRequest(action) {
  let alertMessageData, phoneNumbersList
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData
    let reqObj = {
      cin: cin?.ciNo,
      phoneNoId: 0,
      phone_no: "",
      wba_id: "",
      wba_phone_no_id: "",
    }
    let wbaDeatailsResponse = yield call(Post, urls.GET_WPA_PHONE_NUMBERS, reqObj)
    if (wbaDeatailsResponse?.data?.status) {
      phoneNumbersList = wbaDeatailsResponse?.data?.status
        ? wbaDeatailsResponse?.data?.wba_phone_numbers?.map(x => ({
            ...x,
            cin: wbaDeatailsResponse?.data?.cin,
            email_id: wbaDeatailsResponse?.data?.email_id,
            message: wbaDeatailsResponse?.data?.message,
            phoneNoId: wbaDeatailsResponse?.data?.phoneNoId,
            status: wbaDeatailsResponse?.data?.status,
            wbaAppId: wbaDeatailsResponse?.data?.wbaAppId,
            wba_access_token: wbaDeatailsResponse?.data?.wba_access_token,
            wba_id: wbaDeatailsResponse?.data?.wba_id,
          }))
        : undefined
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Get The WBA Account Detials",
      }
    }
  } catch (err) {
    console.log("error", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed To get the WBA Account Details",
    }
  }
  yield put(actions.getAllPhoneNumbersResponse(phoneNumbersList, alertMessageData))
}
function* testWABAACcountDetailsRequest(action) {
  let testWABADetsils, alertMessageData
  try {
    let response = yield call(Post, urls.TEST_WABA_ACCOUNT_DETAILS, action?.payload)
    console.log("testWABAACcountDetailsRequest=>", response)
    testWABADetsils = response
  } catch (err) {
    console.log("error", err)
    testWABADetsils = err?.data
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed To get the WBA Account Details",
    }
  }
  yield put(actions.testWABAPhoneNumbersResponse(testWABADetsils, alertMessageData))
}
function* testWABAACcountDetails() {
  yield takeLeading(
    types.TEST_WABA_ACCOUNT_DETIALS_REQUEST,
    testWABAACcountDetailsRequest
  )
}
function* createOrUpdatePhoneRequest(action) {
  let createOrUpdateResponse, alertMessageData
  try {
    let response = yield call(
      Post,
      urls.UPDATE_ACCOUNT_DETAILS,
      action?.payload?.requestData
    )
    console.log("testWABAACcountDetailsRequest=>", response)
    if (response?.status) {
      createOrUpdateResponse = response
      yield call(getWhatsBusinessPhoneNoDetailsRequest, {
        wba_phone_no_id: action?.payload?.requestData?.wba_phone_no_Id,
      })
      yield put(
        actions.openModelForAddingOrUpdatingPhoneNumbers(undefined, undefined)
      )
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "unable To update the WBA Account Details",
      }
    }
  } catch (err) {
    console.log("error", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed To get the WBA Account Details",
    }
  }
  yield put(
    actions.createOrEditWABAPhoneNumbersResponse(
      createOrUpdateResponse,
      alertMessageData
    )
  )
}
function* createOrUpdateWABAAccoutPhoneNumbers() {
  yield takeLeading(
    types.CREATE_OR_EDIT_WABA_PHONE_NUMBERS_REQUEST,
    createOrUpdatePhoneRequest
  )
}
function* getAdminAgent(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("getAdminAgent_user_info", user_info)
  let agentsDetails
  let alertMessageData

  try {
    const reqData = {
      agentStatus: "",
      cin: user_info?.ciNo,
      role: "",
    }
    const response = yield call(Post, urls.GET_ADMIN_AGENTS, reqData)
    console.log("getAdminAgent_response", response)
    if (response && response?.data?.status) {
      agentsDetails = response?.data
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Get Agent Detials",
      }
    }
  } catch (error) {
    console.log("getAdminAgent_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To get Agent Details",
    }
  }
  return { agentsDetails, alertMessageData }
}
function* getAdminApi() {
  let alertMessageData
  let agentsDetails
  try {
    const response = yield call(getAdminAgent)
    console.log("getAdminApi_response", response)
    let resData = response?.agentsDetails
    let messageData = response?.alertMessageData
    if (!messageData) {
      agentsDetails = resData
    } else {
      alertMessageData = messageData
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To get Agent Details",
    }
  }
  console.log("saffsaafsafs", agentsDetails, alertMessageData)
  yield put(getAdminAgentsResponse(agentsDetails, alertMessageData))
}
function* updateUserAvailabilityStatus(action) {
  console.log("updateUserAvailabilityStatus", action)
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  const setAnchorElStatus = action?.payload?.setAnchorElStatus
  let alertMessageData
  console.log("fsaafsasffafsa", user_info)
  try {
    const reqData = {
      cin: user_info?.ciNo,
      agentAvailability: action?.payload?.status,
    }
    const url = `${urls.UPDATE_USER_AVAILABILITY_STATUS?.replace(
      "{agentId}",
      user_info?.id
    )}/?cin=${user_info?.ciNo}&agentAvailability=${action?.payload?.status}`
    console.log("updateUserAvailabilityStatus_url", url, reqData)
    const response = yield call(Post, url, {})
    console.log("updateUserAvailabilityStatus_response", response)
    if (response && response?.data?.status) {
      yield call(getAdminApi)
      setAnchorElStatus(null)
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Status updated successfully",
      }
    } else {
      setAnchorElStatus(null)
      alertMessageData = {
        status: false,
        message: response?.data?.message || "Unable to update status",
      }
    }
  } catch (error) {
    setAnchorElStatus(null)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to update status",
    }
  }
  yield put(actions.updateUserAvailabilityStatusResponse(alertMessageData))
}
function* updateUserAvailabilityStatusWatcher() {
  yield takeLatest(
    types.UPDATE_USER_AVAILABILITY_STATUS_REQUEST,
    updateUserAvailabilityStatus
  )
}
function* updateAgentProfile(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined

  const userAgentData =
    (yield select())["userManagementReducer"]?.agentDetails?.agents || []

  const agent_user = userAgentData?.find(x => x?.agentId === user_info?.id)
  const fieldValues = action?.payload?.values
  console.log("updateAgentProfile", { agent_user, fieldValues })

  const setIsUpdateProfile = action?.payload?.setIsUpdateProfile
  let alertMessageData
  try {
    const reqData = {
      agentId: fieldValues?.agentId,
      firstName: fieldValues?.firstName,
      lastName: fieldValues?.lastName,
      emailId: fieldValues?.emailId,
      mobileNo: fieldValues?.mobileNo,
      countryCode: fieldValues?.countryCode?.value,
      department: fieldValues?.department,
      jobTitle: fieldValues?.jobTitle,

      agentStatus: agent_user?.agentStatus,
      cin: agent_user?.cin,
      createdBy: agent_user?.createdBy,
      objectState: "UPDATE",
      role: agent_user?.role,
    }
    console.log("updateRolesPremissions_reqData", reqData)
    const response = yield call(Post, urls.ADD_AGENTS, reqData)
    console.log("updateAgentProfile_response", response)
    if (response && response?.data?.status) {
      yield call(getAdminApi)
      setIsUpdateProfile(false)
      alertMessageData = {
        status: true,
        message: response?.message || "Profile updated successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to update Profile",
      }
    }
  } catch (error) {
    console.log("updateRolesPremissions_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to update Profile",
    }
  }
  yield put(actions.updateAgentProfileRespone(alertMessageData))
}
function* updategentProfileWatcher() {
  yield takeLatest(types.UPDATE_AGENT_PROFILE_REQUEST, updateAgentProfile)
}

function* uploadProfileFileWatcher() {
  yield takeLatest(types.UPLOAD_FILE_REQUEST, uploadFile)
}

function* uploadFile(action) {
  try {
    const { cin, file } = action.payload
    // let { requestData, profilePic } = action.payload
    // Prepare form data
    const formData = new FormData()
    formData.append("cin", cin)
    formData.append("file", file)

    // Use the provided Post function to make the request
    const response = yield call(Post, "file/upload", formData, { isUpload: true })

    yield put(actions.uploadFileSuccess(response.data))
  } catch (error) {
    yield put(actions.uploadFileFailure(error))
  }
}

function* profilesSaga() {
  yield all([
    fork(uploadFileDataWatcher),
    fork(getProfileDetailsWatcher),
    fork(changePasswordWatcher),
    fork(updateProfileDetailsWatcher),
    fork(getWhatsBusinessPhoneNoDetailsWatcher),
    fork(getWhatsPhoneNoDetailsWatcher),
    fork(testWABAACcountDetails),
    fork(createOrUpdateWABAAccoutPhoneNumbers),
    fork(updateUserAvailabilityStatusWatcher),
    fork(updategentProfileWatcher),
    fork(uploadProfileFileWatcher),
  ])
}
export default profilesSaga
