import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING } from "../common/actionTypes"

export const getAllReplyContentDataRequest = () => ({
    type: types.GET_ALL_REPLY_CONTENT_DATA_REQUEST,
    loadType: ACTION_LOADING,
    loadPayload: true
})

export const getAllReplyContentDataResponse = (allReplyContentData, alertMessageData) => ({
    type: types.GET_ALL_REPLY_CONTENT_DATA_RESPONSE,
    payload: allReplyContentData,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData
})

export const setSelectedReplyContent = (selectedContentType) => ({
    type: types.SET_SELECTED_REPLY_TYPE_FOR_VIEW,
    payload: selectedContentType
})

export const openModelBasedOnReplyType = (replyType, actionData) => ({
    type: types.OPEN_MODEL_BASED_ON_REPLY_TYPE,
    payload: { replyType, actionData }

})
export const createOrUpdateReplyItemRequest = (replyItem, objectState) => ({
    type: types.CREATE_REPLY_ITEM_REQUEST,
    payload: { replyItem, objectState },
    loadType: ACTION_LOADING,
    loadPayload: true
})
export const createOrUpdateReplyContentResponse = (responseStatus, previousReplyItemsData, alertMessageData) => ({
    type: types.CREATE_REPLY_ITEM_RESPONSE,
    payload: { responseStatus, previousReplyItemsData },
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
})
