import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put, select } from "redux-saga/effects"
import { MultiCallsRequest } from "../../helpers/api_helper"
import * as urls from "../../helpers/url_helper"
import moment from "moment"
import { conversation_direction, conversation_type } from "../../constants/index"
import _ from "lodash"

function* getChartsDataWacther() {
  yield takeLatest(types.GET_INSIGHTS_DATA_REQUEST, getChartsDataRequest)
}
function* getChartsDataRequest(actionData) {
  let pricingAnalytics,
    conversationAnalytics,
    alertMessageData,
    barGraphDataOptions,
    pieChartOptions
  let { requestObj, selectedChartType, apiRequest } = actionData.payload
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  let requestObjectData = {
    cin: cin,
    endDate:
      new Date(
        requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0
      ).getTime() / 1000,
    phoneNumber: requestObj?.phoneNumber?.wba_display_phone_number,
    productType: "",
    startDate:
      new Date(
        requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth(),
        1
      ).getTime() / 1000,
  }
  if (apiRequest) {
    try {
      let multiCallsArr = [
        {
          method: "POST",
          url: `${urls.GET_INSIGHTS_FOR_ALL_MESSAGES}`,
          body: requestObjectData,
        },
        {
          method: "POST",
          url: `${urls.GET_INSIGHTS_FOR_CONVERSTIONS}`,
          body: requestObjectData,
        },
      ]
      let response = yield call(MultiCallsRequest, multiCallsArr)
      if (response && response[0].status === 200 && response[0].status === 200) {
        pricingAnalytics = response?.[0]?.data?.analytics?.data_points?.map(x => ({
          ...x,
          start: new Date(x.start * 1000).getDate(),
          end: new Date(x.end * 1000).getDate(),
        }))
        conversationAnalytics =
          response?.[1]?.data?.conversation_analytics?.data?.[0]?.data_points?.map(
            x => ({
              ...x,
              start: new Date(x.start * 1000).getDate(),
              end: new Date(x.end * 1000).getDate(),
            })
          )
      } else {
        alertMessageData = {
          status: false,
          message: response?.data?.message || "Unable to Get the Insights Data",
        }
      }
    } catch (err) {
      console.log("errorMEssage=>", err)
      alertMessageData = {
        status: false,
        message: err?.data?.message || "Failed to Get the Insights Data",
      }
    }
  } else {
    conversationAnalytics = (yield select())["InsightsReducer"]
      ?.conversationAnalytics
    pricingAnalytics = (yield select())["InsightsReducer"]?.pricingAnalytics
  }
  barGraphDataOptions = yield call(
    getBarCahrtOrLinrChartData,
    requestObj,
    requestObj?.conversionSelectValue?.value !== 2
      ? conversationAnalytics
      : pricingAnalytics,
    selectedChartType
  )
  pieChartOptions = yield call(getPichartData, conversationAnalytics)
  yield put(
    actions.getInsightsResponse(
      requestObj,
      selectedChartType,
      pricingAnalytics,
      conversationAnalytics,
      barGraphDataOptions,
      pieChartOptions,
      alertMessageData
    )
  )
}
// To Get the Bar and Line Cahrts data
function* getBarCahrtOrLinrChartData(
  selectedValues,
  pricingOrConversationChartInfo,
  selectedChartType
) {
  let minDate = new Date(
    selectedValues?.dateRangeSelection?.getFullYear(),
    selectedValues?.dateRangeSelection?.getMonth(),
    1
  ).getDate()
  let maxDate = new Date(
    selectedValues?.dateRangeSelection?.getFullYear(),
    selectedValues?.dateRangeSelection?.getMonth()+1,0
    // selectedValues?.dateRangeSelection?.getDate()

  ).getDate();
  let chartxaxisData = Array.from(
    {
      length:
        new Date(
          selectedValues?.dateRangeSelection.getFullYear(),
          selectedValues?.dateRangeSelection.getMonth() + 1,0
          // selectedValues?.dateRangeSelection.getDate()
        ).getDate()
      //   1 +
      // 1,
    },
    (_, i) =>
      `${moment(selectedValues?.dateRangeSelection).format("MMM")} ${1 + i * 1}`
  )
  console.log("minData=>",chartxaxisData,maxDate,minDate,selectedValues?.dateRangeSelection?.getDate())

  let userOrRecivedOrFree = []
  let businessOrDelivedOrPaid = []
  let sendOrTotalOrApproximate = []
  let barchartOrLineChartOptions =
    selectedChartType === "BarChart"
      ? {
          // color: ["rgba(11, 2, 31, 0.7)", "rgba(142, 208, 255, 0.8)", "rgba(200, 231, 254, 0.8)"],
          color: ["#1BA9EA", "#0268FF"],
          width: "100%",
          grid: {
            left: "1%",
            // right: '4%',
            // bottom: '3%',
            containLabel: true,
          },
          tooltip: {
            trigger: "item",
            axisPointer: { type: "shadow" },
            textStyle: { fontSize: 18 },
          },
          legend: {
            data:
              selectedValues?.conversionSelectValue?.value === 1
                ? [
                    "User Initiated",
                    "Business initiated",
                    // "Total Conversations",
                  ]
                : selectedValues?.conversionSelectValue?.value === 2
                ? ["Received", "Delivered", "Sent"]
                : selectedValues?.conversionSelectValue?.value === 3
                ? ["Free", "Paid", "Approximate"]
                : [
                    "User Initiated",
                    "Business initiated",
                    // "Total Conversations",
                  ],
            itemGap: 20,
            bottom: 10,
            itemWidth: 30,
            itemHeight: 30,
            radius: 0,
            textStyle: {
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: 400,
              color: "#1E2A4A",
            },
          },
          xAxis: {
            type: "category",
            fontFamily: "Roboto",
            name: "",
            nameTextStyle: {
              fontSize: 14,
              color: "#888888",
            },
            axisLine: {
              lineStyle: {
                color: "#f6f6f6",
              },
            },
            axisLabel: {
              color: "#1E2A4A",
              interval: 0,
              rotate: 80, //If the label names are too long you can manage this by rotating the label.
            },
            data: Array.from(
              {
                length:
                  new Date(
                    selectedValues?.dateRangeSelection.getFullYear(),
                    selectedValues?.dateRangeSelection.getMonth() + 1,
                    0
                  ).getDate() /
                    1 +
                  1,
              },
              (_, i) =>
                `${moment(selectedValues?.dateRangeSelection).format("MMM")} ${
                  1 + i * 1
                }`
            ),
          },
          yAxis: {
            type: "value",
            fontFamily: "Roboto",
            name: "",
            // nameLocation: "middle",
            // nameGap: 40,
            axisLabel: {
              color: "#1E2A4A",
            },
            nameTextStyle: {
              fontSize: 18,
              color: "#1E2A4A",
            },
            splitLine: {
              lineStyle: {
                color: "#f6f6f6",
              },
            },
          },
          series: [
            {
              name:
                selectedValues?.conversionSelectValue?.value === 1
                  ? "User Initiated"
                  : selectedValues?.conversionSelectValue?.value === 2
                  ? "Received"
                  : selectedValues?.conversionSelectValue?.value === 3
                  ? "Free"
                  : "User Initiated",
              data: [],
              type: "bar",
              stack: "Ad",
              showBackground: false,
              showSymbol: false,
              legendHoverLink: false,
              tooltip: {
                formatter: params => {
                  return (
                    params.name +
                    "<br/>" +
                    params.seriesName +
                    " : " +
                    params.value +
                    ""
                  )
                },
                borderColor: "#ffffff",
              },
              barWidth: 15,
            },
            {
              name:
                selectedValues?.conversionSelectValue?.value === 1
                  ? "Business initiated"
                  : selectedValues?.conversionSelectValue?.value === 2
                  ? "Delivered"
                  : selectedValues?.conversionSelectValue?.value === 3
                  ? "Paid"
                  : "Business initiated",
              data: [],
              type: "bar",
              stack: "Ad",
              showBackground: false,
              showSymbol: false,
              legendHoverLink: false,
              tooltip: {
                formatter: params => {
                  return (
                    params.name +
                    "<br/>" +
                    params.seriesName +
                    " : " +
                    params.value +
                    ""
                  )
                },
                borderColor: "#ffffff",
              },
              barWidth: 15,
            },
            {
              name:
                selectedValues?.conversionSelectValue?.value === 1
                  ? "Total Conversations"
                  : selectedValues?.conversionSelectValue?.value === 2
                  ? "Sent"
                  : selectedValues?.conversionSelectValue?.value === 3
                  ? "Approximate"
                  : "Total Conversations",
              data: [],
              type: "bar",
              stack: "Ad",
              showBackground: false,
              showSymbol: false,
              legendHoverLink: false,
              tooltip: {
                formatter: params =>
                  params.name +
                  "<br/>" +
                  params.seriesName +
                  " : " +
                  params.value +
                  "",
                borderColor: "#ffffff",
              },
              barWidth: 10,
            },
          ],
        }
      : {
          legend: {
            data:
              selectedValues?.conversionSelectValue?.value === 1
                ? [
                    "User Initiated",
                    "Business initiated",
                    // "Total Conversations",
                  ]
                : selectedValues?.conversionSelectValue?.value === 2
                ? ["Received", "Delivered", "Sent"]
                : selectedValues?.conversionSelectValue?.value === 3
                ? ["Free", "Paid", "Approximate"]
                : [
                    "User Initiated",
                    "Business initiated",
                    // "Total Conversations",
                  ],
            itemGap: 10,
            bottom: 10,
            itemWidth: 13,
            itemHeight: 13,
            textStyle: {
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: 400,
              color: "#1E2A4A",
            },
          },
          xAxis: {
            type: "category",
            data: Array.from(
              {
                length:
                  (new Date(
                    selectedValues?.dateRangeSelection.getFullYear(),
                    selectedValues?.dateRangeSelection.getMonth() + 1,
                    0
                  ).getDate() -
                    1) /
                    1 +
                  1,
              },
              (_, i) =>
                `${moment(selectedValues?.dateRangeSelection).format("MMM")} ${
                  1 + i * 1
                }`
            ),
          },
          yAxis: {
            type: "value",
          },
          series: [
            { data: [], type: "line" },
            { data: [], type: "line" },
            { data: [], type: "line" },
          ],
        }
  for (let x = minDate; x <= maxDate; x++) {
    let chartData =
      selectedValues?.conversionSelectValue?.value !== 2
        ? pricingOrConversationChartInfo?.filter(y => y.start == x)
        : pricingOrConversationChartInfo?.find(y => y.start == x)
    if (selectedValues?.conversionSelectValue?.value === 2) {
      userOrRecivedOrFree.push(chartData?.received ?? 0)
      sendOrTotalOrApproximate.push(chartData?.sent ?? 0)
      businessOrDelivedOrPaid.push(chartData?.delivered ?? 0)
    } else if (selectedValues?.conversionSelectValue?.value === 1) {
      if (chartData?.length > 0) {
        let business = chartData
          .filter(
            X =>
              X?.conversation_direction === conversation_direction.BUSINESS_INITIATED
          )
          ?.map(y => y?.conversation || 0) ?? [0]
        let users = chartData
          .filter(
            X => X?.conversation_direction === conversation_direction.USER_INITIATED
          )
          ?.map(y => y?.conversation || 0) ?? [0]
        let total = [[...business, ...users].reduce((x, y) => x + y, 0)]
        userOrRecivedOrFree = [
          ...userOrRecivedOrFree,
          ...(users?.length > 0 ? users : [0]),
        ]
        businessOrDelivedOrPaid = [
          ...businessOrDelivedOrPaid,
          ...(business?.length > 0 ? business : [0]),
        ]
        sendOrTotalOrApproximate = [...sendOrTotalOrApproximate, ...total]
      } else {
        businessOrDelivedOrPaid = [...businessOrDelivedOrPaid, 0]
        sendOrTotalOrApproximate = [...sendOrTotalOrApproximate, 0]
        userOrRecivedOrFree = [...userOrRecivedOrFree, 0]
      }
    } else if (selectedValues?.conversionSelectValue?.value === 3) {
      if (chartData?.length > 0) {
        let freeTier = chartData
          .filter(X => X?.conversation_type === conversation_type.FREE_TIER)
          ?.map(y => y?.cost || 0)
        let paid = chartData
          .filter(X => X?.conversation_type === conversation_type.PAID)
          ?.map(y => y?.cost || 0)
        let total = [[...freeTier, ...paid].reduce((x, y) => x + y, 0)]
        userOrRecivedOrFree = [
          ...userOrRecivedOrFree,
          ...(freeTier?.length > 0 ? freeTier : [0]),
        ]
        businessOrDelivedOrPaid = [
          ...businessOrDelivedOrPaid,
          ...(paid?.length > 0 ? paid : [0]),
        ]
        sendOrTotalOrApproximate = [...sendOrTotalOrApproximate, ...total]
      } else {
        businessOrDelivedOrPaid = [...businessOrDelivedOrPaid, 0]
        sendOrTotalOrApproximate = [...sendOrTotalOrApproximate, 0]
        userOrRecivedOrFree = [...userOrRecivedOrFree, 0]
      }
    } else {
      businessOrDelivedOrPaid = []
      userOrRecivedOrFree = []
      sendOrTotalOrApproximate = []
    }
  }
  barchartOrLineChartOptions.xAxis.data = chartxaxisData
  barchartOrLineChartOptions.series[0].data = userOrRecivedOrFree
  barchartOrLineChartOptions.series[1].data = businessOrDelivedOrPaid
  // barchartOrLineChartOptions.series[2].data = sendOrTotalOrApproximate
  return barchartOrLineChartOptions
}
// To get the Pie chart data
function* getPichartData(chartInfo) {
  let groupByValuesForConversation = _(chartInfo)
    .groupBy("conversation_direction")
    .map((objs, key) => ({
      [key]: _.sumBy(objs, "conversation") / objs.length,
    }))
    .value()
  let pieChartOptions = {
    // color: ["#F0F0F0", "#0B021F", "#1BA9EA"],
    color: ["#F0F0F0", "#0268FF", "#1BA9EA"],
    tooltip: {
      trigger: "item",
      showContent: true,
      formatter: function (params) {
        return `${params.seriesName}<br />
                ${params.name}: (${params.percent}%)<br />
                `
      },
    },
    legend: {
      bottom: 0,
      left: "center",
      itemWidth: 30,
      itemHeight: 30,
      textStyle: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: 400,
        color: "#1E2A4A",
      },
    },
    series: [
      {
        name: "Percentages for Conversations",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "inside",
          formatter: function (value) {
            return `${JSON.stringify(value.percent)}%`
          },
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "italic",
          },
        },
        labelLine: {
          show: true,
        },
        data: [
          {
            value:
              [
                groupByValuesForConversation?.find(x => x.BUSINESS_INITIATED)
                  ?.BUSINESS_INITIATED ?? 0,
                groupByValuesForConversation?.find(x => x.USER_INITIATED)
                  ?.USER_INITIATED ?? 0,
              ]?.reduce((x, y) => x + y, 0) ?? 0,
            name: "Total Conversations",
          },
          {
            value:
              groupByValuesForConversation?.find(x => x.BUSINESS_INITIATED)
                ?.BUSINESS_INITIATED ?? 0,
            name: "Business Initiated",
          },
          {
            value:
              groupByValuesForConversation.find(x => x.USER_INITIATED)
                ?.USER_INITIATED ?? 0,
            name: "User Initiated",
          },
        ],
      },
    ],
  }
  return pieChartOptions
}
function* insightsSaga() {
  yield all([fork(getChartsDataWacther)])
}
export default insightsSaga
