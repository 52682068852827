import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes"

const initialState = {
  contactListData: [],
  actionType: ACTION_TYPES.SELECT,
  actionData: undefined,
  codeValues: [],
  selectedType: 0,
  bulkuploadResponse: [],
  postalCodeData: undefined,
  totalPages: 0,
  isOpenOrClose: false,
  message: "",
}

const createContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_CONTACTS_RESPONSE:
      state = {
        ...state,
        contactListData: action.payload.responseData,
        totalPages: action.payload.totalPages,
      }
      break
    case types.SHOW_MODEL_FOR_CREATE_UPDATE_DELETE:
      state = {
        ...state,
        actionType: action.payload.actionType,
        actionData: action.payload.actionData,
      }
      break
    case types.GET_SEARCH_VALUE_RESPONSE:
      state = {
        ...state,
        contactListData: action.payload,
      }
      break
    case types.GET_CODE_VALUE_FOR_ENQUIRES_RESPONSE:
      state = {
        ...state,
        codeValues: action.payload,
      }
      break
    case types.CREATE_OR_UPDATE_DELETE_CONTACT_RESPONSE:
      if (action.payload?.status) {
        state = {
          ...state,
          actionType: ACTION_TYPES.SELECT,
        }
      }
      break
    case types.DELETE_CONTACT_LIST_DATE_RESPONSE:
      if (action.payload?.status) {
        state = {
          ...state,
          actionType: ACTION_TYPES.SELECT,
        }
      }
      break
    case types.SELECT_VALUES_FOR_UPLOAD_OR_DOWNLOAD:
      state = {
        ...state,
        selectedType: action.payload,
      }
      break
    case types.CREATE_BULK_UPLOAD_CONTACT_RESPONSE:
      state = {
        ...state,
        bulkuploadResponse: action.payload,
      }
      break
    case types.GET_POSTAL_CODED_BASED_DATA_RESPONSE:
      state = {
        ...state,
        postalCodeData: action.payload,
      }
      break
    case types.OPEN_MODEL_FOR_SHOWING_UPLOAGING_STATUS:
      state = {
        ...state,
        isOpenOrClose: action.payload.isOpenOrClose,
        message: action.payload.message,
      }
      break
    case types.RESET_SHOW_MODEL_FOR_CREATE_UPDATE_DELETE:
      state = {
        ...state,
        actionType: initialState.actionType,
        message: initialState.actionData,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}
export default createContactReducer
