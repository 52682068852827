import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put, select } from "redux-saga/effects"
import { MultiCallsRequest } from "../../helpers/api_helper"
import * as urls from "../../helpers/url_helper"
import moment from "moment"
import { conversation_direction, conversation_type } from "../../constants/index"
import _ from "lodash"
// Crypto Redux States

function* getChartsDataRequest(actionData) {
  console.log("getChartsDataRequest", actionData);
  let pricingAnalytics,
    conversationAnalytics,
    alertMessageData
  let { requestObj } = actionData.payload
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  let requestObjectData = {
    cin: cin,
    endDate:
      new Date(
        requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0
      ).getTime() / 1000,
    phoneNumber: requestObj?.phoneNumber?.wba_display_phone_number,
    productType: "",
    startDate:
      new Date(
        requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth(),
        1
      ).getTime() / 1000,
  }
  try {
    let multiCallsArr = [
      {
        method: "POST",
        url: `${urls.GET_INSIGHTS_FOR_ALL_MESSAGES}`,
        body: requestObjectData,
      },
      {
        method: "POST",
        url: `${urls.GET_INSIGHTS_FOR_CONVERSTIONS}`,
        body: requestObjectData,
      },
    ]
    let response = yield call(MultiCallsRequest, multiCallsArr)
    if (response && response[0].status === 200 && response[0].status === 200) {
      pricingAnalytics = response?.[0]?.data?.analytics?.data_points?.map(x => ({
        ...x,
        start: new Date(x.start * 1000).getDate(),
        end: new Date(x.end * 1000).getDate(),
      }))
      conversationAnalytics =
        response?.[1]?.data?.conversation_analytics?.data?.[0]?.data_points?.map(
          x => ({
            ...x,
            start: new Date(x.start * 1000).getDate(),
            end: new Date(x.end * 1000).getDate(),
          })
        )
    } else {
      alertMessageData = {
        status: false,
        message: response?.data?.message || "Unable to Get the Insights Data",
      }
    }
  } catch (err) {
    console.log("errorMEssage=>", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed to Get the Insights Data",
    }
  }
  let totalConversations = conversationAnalytics?.map(x => x?.conversation)?.reduce((x, y) => x + y, 0) || 0;
  let serviceConversations = conversationAnalytics?.filter(z => z?.conversation_category == conversation_direction.SERVICE)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0;
  let marketingConverstions = conversationAnalytics?.filter(z => z?.conversation_category === conversation_direction.MARKETING)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0;
  let utilityConversations = conversationAnalytics?.filter(z => z?.conversation_category === conversation_direction.UTILITY)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0;
  let autenticationConversations = conversationAnalytics?.filter(z => z?.conversation_category === conversation_direction.AUTENTICATION)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0;
  let paidConversions = conversationAnalytics
    ?.filter(X => X?.conversation_type === conversation_type.PAID)
    ?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0);
  let approximateCharges = 0;
  let conversationWigetData = { totalConversations, serviceConversations, marketingConverstions, utilityConversations, autenticationConversations, paidConversions, approximateCharges }
  let conversationLineChartAnalytics = yield call(lineGraphForConversationInsights, conversationAnalytics, requestObj);
  let conversationSummaryGraphData = yield call(lineGraphForConversationSummary, conversationAnalytics);
  let conversarionMessageInsightsLineChart = yield call(lineGraphForMessageInsights, pricingAnalytics, requestObj);
  let conversarionMessageInsightsBarChart = yield call(barGraphForMessageInsights, pricingAnalytics);
  let paidConversationsBarGraphData = yield call(paidConversationBarGraph, conversationAnalytics, requestObj);
  let paidConversationPieGraphData = yield call(paidConversationPieGraphSummary, conversationAnalytics);
  console.log("getDashboardResponse=>", { totalConversations, serviceConversations, marketingConverstions, utilityConversations, autenticationConversations, paidConversions, paidConversationsBarGraphData, paidConversationPieGraphData })
  yield put(actions?.getDashboardResponse(conversationLineChartAnalytics, conversationSummaryGraphData, conversarionMessageInsightsLineChart, conversarionMessageInsightsBarChart, conversationWigetData, paidConversationsBarGraphData, paidConversationPieGraphData, alertMessageData))
}
function* lineGraphForConversationSummary(conversationAnalytics) {

  let marketing = conversationAnalytics
    ?.filter(
      X =>
        X?.conversation_category === conversation_direction.MARKETING
    )
    ?.map(y => y?.conversation || 0) ?? [0]
  let utility = conversationAnalytics
    ?.filter(
      X => X?.conversation_category === conversation_direction.UTILITY
    )
    ?.map(y => y?.conversation || 0) ?? [0]
  let autentication = conversationAnalytics
    ?.filter(
      X => X?.conversation_category === conversation_direction.AUTENTICATION
    )
    ?.map(y => y?.conversation || 0) ?? [0]
  let servoce = conversationAnalytics
    ?.filter(
      X => X?.conversation_category === conversation_direction.SERVICE
    )
    ?.map(y => y?.conversation || 0) ?? [0];
  let data = {
    labels: ['Marketing', 'Utility', 'Authentication', 'Service'],
    datasets: [
      {
        label: "Marketing",
        data: [marketing?.reduce((x, y) => x + y, 0)],
        borderColor: "#FF69B4",
        backgroundColor: "#FF69B4"
      },
      {
        label: "Utility",
        data: [0, utility?.reduce((x, y) => x + y, 0)],
        borderColor: "#E697FF",
        backgroundColor: "#E697FF"
      },
      {
        label: "Authentication",
        data: [0, 0, autentication?.reduce((x, y) => x + y, 0)],
        borderColor: "#0AE9D2",
        backgroundColor: "#0AE9D2"
      },
      {
        label: "Service",
        data: [0, 0, 0, servoce?.reduce((x, y) => x + y, 0)],
        borderColor: "#87DB47",
        backgroundColor: "#87DB47"
      }
    ]
  };
  console.log("Service=>Service", data);
  return data
}
function* lineGraphForConversationInsights(conversionsData, requestObj) {
  console.log("lineGraphForConversationInsights=>", conversionsData);
  const labels = Array.from(
    {
      length: new Date(requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0).getDate() / 1,
    },
    (_, i) => `${moment(new Date(requestObj?.dateRangeSelection)).format("MMM")} ${1 + i * 1}`
  );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  let minDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth(),
    1
  ).getDate()
  let maxDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth() + 1, 0
    // selectedValues?.dateRangeSelection?.getDate()
  ).getDate();
  console.log("lineGraphForConversationInsights=>123456789", moment(new Date(requestObj?.dateRangeSelection)).format("MMM"), minDate, maxDate, new Date(requestObj?.dateRangeSelection), labels);
  const data = {
    labels,
    datasets: [
      {
        label: "Marketing",
        data: [],
        borderColor: "#FF0080",
        backgroundColor: "#FF0080"
      },
      {
        label: "Utility",
        data: [],
        borderColor: "#A155B9",
        backgroundColor: "#A155B9"
      },
      {
        label: "Authentication",
        data: [],
        borderColor: "#00B6A4",
        backgroundColor: "#00B6A4"
      },
      {
        label: "Service",
        data: [],
        borderColor: "#87DB47",
        backgroundColor: "#87DB47"
      }
    ]
  };
  let utilityData = [];
  let marketingData = [];
  let serviceData = [];
  let autenticationData = [];
  for (let x = minDate; x <= maxDate; x++) {
    console.log(`lineGraphForConversationInsights=>2`, x, conversionsData?.filter(y => y.start == x));
    let chartData = conversionsData?.filter(y => y.start == x);
    if (chartData?.length > 0) {
      let servoce = [chartData?.filter(z => z?.conversation_category == conversation_direction.SERVICE)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0)] ?? [0];
      let marketing = [chartData?.filter(z => z?.conversation_category === conversation_direction.MARKETING)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0)] ?? [0];
      let utility = [chartData?.filter(z => z?.conversation_category === conversation_direction.UTILITY)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0)] ?? [0]
      let autentication = [chartData?.filter(z => z?.conversation_category === conversation_direction.AUTENTICATION)?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0)] ?? [0]

      utilityData = [...utilityData, ...(utility?.length > 0 ? utility : [0])]
      marketingData = [...marketingData, ...(marketing?.length > 0 ? marketing : [0]),]
      autenticationData = [...autenticationData, ...(autentication?.length > 0 ? autentication : [0]),]
      serviceData = [...serviceData, ...(servoce?.length > 0 ? servoce : [0]),]
    } else {
      utilityData = [...utilityData, 0];
      autenticationData = [...autenticationData, 0];
      marketingData = [...marketingData, 0];
      serviceData = [...serviceData, 0]
    }
    console.log(`serviceData=>data`, { data });
  }
  data.datasets[0].data = marketingData;
  data.datasets[1].data = utilityData;
  data.datasets[2].data = autenticationData;
  data.datasets[3].data = serviceData;
  return data
}
function* lineGraphForMessageInsights(conversionsData, requestObj) {
  console.log("lineGraphForConversationInsights=>", conversionsData);
  // const labels = Array.from(
  //   {
  //     length: new Date().getDate() / 1 + 1,
  //   },
  //   (_, i) => `${moment(new Date()).format("MMM")} ${1 + i * 1}`
  // );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  const labels = Array.from(
    {
      length: new Date(requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0).getDate() / 1,
    },
    (_, i) => `${moment(new Date(requestObj?.dateRangeSelection)).format("MMM")} ${1 + i * 1}`
  );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  let minDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth(),
    1
  ).getDate()
  let maxDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth() + 1, 0
    // selectedValues?.dateRangeSelection?.getDate()
  ).getDate();
  console.log("lineGraphForConversationInsights=>1", minDate, maxDate, labels);
  const data = {
    labels,
    datasets: [
      {
        label: "Sent",
        data: [],
        borderColor: "#FF0080",
        backgroundColor: "#FF0080",
        fill: true
      },
      {
        label: "Delivered",
        data: [],
        borderColor: "#2DD220",
        backgroundColor: "#2DD220",
        fill: true
      },
      {
        label: "Received",
        data: [],
        borderColor: "#C200FF",
        backgroundColor: "#C200FF",
        fill: true
      }
    ]
  };
  let sentData = [];
  let receivedData = [];
  let deliveredata = [];
  for (let x = minDate; x <= maxDate; x++) {
    let chartData = conversionsData?.find(y => y.start == x);
    console.log("lineGraphForConversationInsights=>1", x, chartData);
    receivedData.push(chartData?.received ?? 0)
    sentData.push(chartData?.sent ?? 0)
    deliveredata.push(chartData?.delivered ?? 0)
  }
  data.datasets[0].data = sentData;
  data.datasets[1].data = deliveredata;
  data.datasets[2].data = receivedData;
  console.log(`lineGraphForMessageInsights`, data);
  return data
}
function* barGraphForMessageInsights(conversationAnalytics) {
  let sent = conversationAnalytics?.map(x => x?.sent || 0);
  let received = conversationAnalytics?.map(x => x?.received || 0);
  let delivered = conversationAnalytics?.map(x => x?.delivered || 0);

  let data = {
    labels: ['Sent', 'Delivered', 'Received'],
    datasets: [
      {
        label: "Sent",
        data: [sent?.reduce((x, y) => x + y, 0)],
        borderColor: "#F94144",
        backgroundColor: "#F94144"
      },
      {
        label: "Delivered",
        data: [0, delivered?.reduce((x, y) => x + y, 0)],
        borderColor: "#F9C74F",
        backgroundColor: "#F9C74F"
      },
      {
        label: "Received",
        data: [0, 0, received?.reduce((x, y) => x + y, 0)],
        borderColor: "#90BE6D",
        backgroundColor: "#90BE6D"
      }
    ]
  };
  console.log("Service=>Service", { data, sent, received, delivered });
  return data
}
function* paidConversationBarGraph(conversionsData, requestObj) {
  console.log("paidConversationsLineGraphData=>", conversionsData);
  // const labels = Array.from(
  //   {
  //     length: new Date().getDate() / 1 + 1,
  //   },
  //   (_, i) => `${moment(new Date()).format("MMM")} ${1 + i * 1}`
  // );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  const labels = Array.from(
    {
      length: new Date(requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0).getDate() / 1,
    },
    (_, i) => `${moment(new Date(requestObj?.dateRangeSelection)).format("MMM")} ${1 + i * 1}`
  );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  let minDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth(),
    1
  ).getDate()
  let maxDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth() + 1, 0
    // selectedValues?.dateRangeSelection?.getDate()
  ).getDate();
  console.log("lineGraphForConversationInsights=>1", minDate, maxDate, labels);
  const data = {
    labels,
    datasets: [
      {
        label: "Free Messages",
        data: [],
        borderColor: "#2DD220",
        backgroundColor: "#2DD220",
        fill: true
      },
      {
        label: "Paid Messages",
        data: [],
        borderColor: "#FF0080",
        backgroundColor: "#FF0080",
        fill: true
      }
    ]
  };
  let freeConversionData = [];
  let paidConversionData = [];
  for (let x = minDate; x <= maxDate; x++) {
    let chartData = conversionsData?.filter(y => y.start == x);
    console.log("lineGraphForConversationInsights=>1", x, chartData
      ?.filter(X => X?.conversation_type === conversation_type.FREE_TIER), chartData);
    if (chartData?.length > 0) {
      let freeTier = [chartData
        ?.filter(X => X?.conversation_type === conversation_type.FREE_TIER)
        ?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0] ?? [0]
      let paid = [chartData
        ?.filter(X => X?.conversation_type === conversation_type.PAID)
        ?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0] ?? 0
      freeConversionData = [
        ...freeConversionData,
        ...(freeTier?.length > 0 ? freeTier : [0]),
      ]
      paidConversionData = [
        ...paidConversionData,
        ...(paid?.length > 0 ? paid : [0]),
      ]
    } else {
      freeConversionData = [...freeConversionData, 0]
      paidConversionData = [...paidConversionData, 0]
    }
  }
  data.datasets[0].data = freeConversionData;
  data.datasets[1].data = paidConversionData;
  console.log(`lineGraphForMessageInsights`, data);
  return data
}
function* paidConversationPieGraphSummary(conversionsData) {
  let freeTier = conversionsData
    ?.filter(X => X?.conversation_type === conversation_type.FREE_TIER)
    ?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0;
  let paid = conversionsData
    ?.filter(X => X?.conversation_type === conversation_type.PAID)
    ?.map(y => y?.conversation || 0)?.reduce((x, y) => x + y, 0) ?? 0
  return {
    labels: ['Free Conversations', 'Paid Conversation'],
    datasets: [
      {
        data: [freeTier, paid],
        backgroundColor: [
          '#1DDD8D',
          '#FF0080',
        ],
        borderColor: [
          '#1DDD8D',
          '#FF0080'
        ],
        borderWidth: 1,
      },
    ],
  };
}

export function* watchGetChartsData() {
  yield takeLatest(types.GET_DASHBOARD_DATA_REQUEST, getChartsDataRequest)
}
export function* watchDashBoardTicekInfo() {
  yield takeLatest(types.GET_DASHBOARD_TICKET_INFO_REQUEST, getDashBoardRquestData)
}
function* getDashBoardRquestData(action) {
  console.log('getDashBoardRquestData', action);
  let ticketSummaryData, resolutions, ticketsDataPoints, messageDataPoints, messageVolumeSummary, messageVolumeSummaryPercentage, agentDataPoints, alertMessageData;
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo;
  let requestObj = action.payload
  let requestObjectData = {
    cin: cin,
    endDate:
      moment(new Date(
        requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0
      )).format('YYYY-MM-DD HH:mm:ss'),
    masterPhoneNo: requestObj?.phoneNumber?.wba_display_phone_number,
    startDate:
      moment(new Date(
        requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth(),
        1
      )).format('YYYY-MM-DD HH:mm:ss'),
  }
  console.log('requestObjectData', moment(new Date(
    requestObj?.dateRangeSelection.getFullYear(),
    requestObj?.dateRangeSelection.getMonth() + 1,
    0
  )).format('YYYY-MM-DD HH:mm:ss'))
  try {
    let multiCallsArr = [
      {
        method: "POST",
        url: `${urls.DASHBOARD_TICKET_SUMMARY}`,
        body: requestObjectData,
      },
      {
        method: "POST",
        url: `${urls.DASHBOARD_MESSAGE_VOLUME}`,
        body: requestObjectData,
      },
      {
        method: "POST",
        url: `${urls.DASHBOARD_AGENT_PERFOERMENCE}`,
        body: requestObjectData,
      },
    ]
    let response = yield call(MultiCallsRequest, multiCallsArr);
    console.log('response=>', response);
    if (response && response[0].status === 200 && response[1].status === 200 && response[2].status) {
      ticketSummaryData = response[0].data.ticketSummary;
      resolutions = response[0].data.resolutions;
      ticketsDataPoints = response[0].data.ticketsDataPoints;
      messageDataPoints = response[1].data.messageDataPoints;
      messageVolumeSummary = response[1].data.messageVolumeSummary;
      messageVolumeSummaryPercentage = response[1].data.messageVolumeSummaryPercentage;
      agentDataPoints = response[2].data.agentDataPoints
    } else {
      alertMessageData = {
        status: false,
        message: response?.data?.message || "Unable to Get the Insights Data",
      }
    }
  } catch (err) {
    console.log("errorMEssage=>", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed to Get the Insights Data",
    }
  }
  let barChartTicketSummary = yield call(ticketSummaryBarChartData, ticketSummaryData);
  let ticketInfoStatusChartdata = yield call(ticketInfoStatusChart, ticketsDataPoints, requestObj);
  let messageDataPointsChartdata = yield call(messageDataPointsChart, messageDataPoints, requestObj);
  let messageSummaryPieChart = yield call(getMessagrSummaryPieChart, messageVolumeSummary)
  console.log('getDashBoardRquestData=>', ticketSummaryData, ticketsDataPoints, resolutions, messageDataPointsChartdata);
  yield put(actions.getDashboardTicketInfoResponse(ticketSummaryData, ticketsDataPoints, resolutions, barChartTicketSummary, ticketInfoStatusChartdata, messageSummaryPieChart, messageDataPointsChartdata, messageVolumeSummaryPercentage, agentDataPoints, alertMessageData));
}
function* ticketSummaryBarChartData(chatInfo) {
  let data = {
    labels: ['Open', 'Pending', 'Closed', 'Resolved by Agent', "Resolved by Bot", "Expired"],
    datasets: [
      {
        label: "Open",
        data: [chatInfo?.open || 0, 0, 0, 0, 0],
        borderColor: "#FF0080",
        backgroundColor: "#FF0080"
      },
      {
        label: "Pending",
        data: [0, chatInfo?.pending || 0, 0, 0, 0, 0],
        borderColor: "#165BAA",
        backgroundColor: "#165BAA"
      },
      {
        label: "Closed",
        data: [0, 0, chatInfo?.closed || 0, 0, 0, 0],
        borderColor: "#10DA00",
        backgroundColor: "#10DA00"
      },
      {
        label: "Resolved by Agent",
        data: [0, 0, 0, chatInfo?.resolvedByAgent || 0, 0, 0],
        borderColor: "#C200FF",
        backgroundColor: "#C200FF"
      },
      {
        label: "Resolved by Bot",
        data: [0, 0, 0, 0, chatInfo?.resolvedByBot || 0, 0],
        borderColor: "#0AE9D2",
        backgroundColor: "#0AE9D2"
      },
      {
        label: "Expired",
        data: [0, 0, 0, 0, 0, chatInfo?.expired || 0],
        borderColor: "#FF0000",
        backgroundColor: "#FF0000"
      }
    ]
  };
  return data;
}
function* messageDataPointsChart(chartInfo, requestObj) {
  // const labels = Array.from(
  //   {
  //     length: new Date().getDate() / 1 + 1,
  //   },
  //   (_, i) => `${moment(new Date()).format("MMM")} ${1 + i * 1}`
  // );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  const labels = Array.from(
    {
      length: new Date(requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0).getDate() / 1,
    },
    (_, i) => `${moment(new Date(requestObj?.dateRangeSelection)).format("MMM")} ${1 + i * 1}`
  );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  let minDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth(),
    1
  ).getDate()
  let maxDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth() + 1, 0
    // selectedValues?.dateRangeSelection?.getDate()
  ).getDate();
  console.log("lineGraphForConversationInsights=>1", minDate, maxDate, labels);
  const data = {
    labels,
    datasets: [
      {
        label: "Campaigns",
        fill: true,
        data: [],
        borderColor: "#FF0080",
        backgroundColor: "#FF0080"
      },
      {
        label: "Bot",
        fill: true,
        data: [],
        borderColor: "#FFC643",
        backgroundColor: "#FFC643"
      },
      {
        label: "Agents",
        fill: true,
        data: [],
        borderColor: "#C200FF",
        backgroundColor: "#C200FF"
      },
      {
        label: "User Replies",
        fill: true,
        data: [],
        borderColor: "#1DDD8D",
        backgroundColor: "#1DDD8D"
      }
    ]
  };
  let campaigns = [];
  let bot = [];
  let agent = [];
  let userReplies = [];
  for (let x = minDate; x <= maxDate; x++) {
    console.log(`lineGraphForConversationInsights=>2`, minDate, chartInfo?.filter(y => new Date(new Date(y.startDate)).getDate() == x));
    let chartData = chartInfo?.filter(y => new Date(new Date(y.startDate)).getDate() == x);
    if (chartData?.length > 0) {
      let campaignData = [chartData?.map(y => y?.campaign || 0)?.reduce((x, y) => x + y, 0)] ?? [0];
      let botData = [chartData?.map(y => y?.bot || 0)?.reduce((x, y) => x + y, 0)] ?? [0];
      let agentData = [chartData?.map(y => y?.agent || 0)?.reduce((x, y) => x + y, 0)] ?? [0]
      let userMessagesData = [chartData?.map(y => y?.userMessages || 0)?.reduce((x, y) => x + y, 0)] ?? [0]

      campaigns = [...campaigns, ...(campaignData?.length > 0 ? campaignData : [0])]
      bot = [...bot, ...(botData?.length > 0 ? botData : [0]),]
      agent = [...agent, ...(agentData?.length > 0 ? agentData : [0]),]
      userReplies = [...userMessagesData, ...(userMessagesData?.length > 0 ? userMessagesData : [0]),]
    } else {
      campaigns = [...campaigns, 0]
      bot = [...bot, 0]
      agent = [...agent, 0]
      userReplies = [...userReplies, 0]
    }
    console.log(`serviceData=>data`, { data });
  }
  data.datasets[0].data = campaigns;
  data.datasets[1].data = bot;
  data.datasets[2].data = agent;
  data.datasets[3].data = userReplies;
  return data;
}
function* ticketInfoStatusChart(chartInfo,requestObj) {
  // const labels = Array.from(
  //   {
  //     length: new Date().getDate() / 1 + 1,
  //   },
  //   (_, i) => `${moment(new Date()).format("MMM")} ${1 + i * 1}`
  // );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  const labels = Array.from(
    {
      length: new Date(requestObj?.dateRangeSelection.getFullYear(),
        requestObj?.dateRangeSelection.getMonth() + 1,
        0).getDate() / 1,
    },
    (_, i) => `${moment(new Date(requestObj?.dateRangeSelection)).format("MMM")} ${1 + i * 1}`
  );
  // let minDate = new Date(1).getDate()
  // let maxDate = new Date().getDate();
  let minDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth(),
    1
  ).getDate()
  let maxDate = new Date(
    requestObj?.dateRangeSelection?.getFullYear(),
    requestObj?.dateRangeSelection?.getMonth() + 1, 0
    // selectedValues?.dateRangeSelection?.getDate()
  ).getDate();
  console.log("lineGraphForConversationInsights=>1", minDate, maxDate, labels);
  const data = {
    labels,
    datasets: [
      {
        label: "Open",
        data: [],
        borderColor: "#FF0080",
        backgroundColor: "#FF0080"
      },
      {
        label: "Pending",
        data: [],
        borderColor: "#165BAA",
        backgroundColor: "#165BAA"
      },
      {
        label: "Closed",
        data: [],
        borderColor: "#10DA00",
        backgroundColor: "#10DA00"
      },
      {
        label: "Resolved by Agent",
        data: [],
        borderColor: "#C200FF",
        backgroundColor: "#C200FF"
      },
      {
        label: "Resolved by Bot",
        data: [],
        borderColor: "#0AE9D2",
        backgroundColor: "#0AE9D2"
      },
      {
        label: "Expired",
        data: [],
        borderColor: "#FF0000",
        backgroundColor: "#FF0000"
      }
    ]
  };
  let open = [];
  let pending = [];
  let closed = [];
  let resolvedByAgent = [];
  let resolvedByBot = [];
  let expired = [];
  for (let x = minDate; x <= maxDate; x++) {
    console.log(`lineGraphForConversationInsights=>2`, minDate, chartInfo?.filter(y => new Date(new Date(y.startDate)).getDate() == x));
    let chartData = chartInfo?.filter(y => new Date(new Date(y.startDate)).getDate() == x);
    if (chartData?.length > 0) {
      let openData = [chartData?.map(y => y?.open || 0)?.reduce((x, y) => x + y, 0)] ?? [0];
      let pendindData = [chartData?.map(y => y?.pending || 0)?.reduce((x, y) => x + y, 0)] ?? [0];
      let closedData = [chartData?.map(y => y?.closed || 0)?.reduce((x, y) => x + y, 0)] ?? [0]
      let resolvedByAgentData = [chartData?.map(y => y?.resolvedByAgent || 0)?.reduce((x, y) => x + y, 0)] ?? [0]
      let resolvedByBotData = [chartData?.map(y => y?.resolvedByBot || 0)?.reduce((x, y) => x + y, 0)] ?? [0]
      let expiredData = [chartData?.map(y => y?.expired || 0)?.reduce((x, y) => x + y, 0)] ?? [0]

      open = [...open, ...(openData?.length > 0 ? openData : [0])]
      pending = [...pending, ...(pendindData?.length > 0 ? pendindData : [0]),]
      closed = [...closed, ...(closedData?.length > 0 ? closed : [0]),]
      resolvedByAgent = [...resolvedByAgent, ...(resolvedByAgentData?.length > 0 ? resolvedByAgentData : [0]),]
      resolvedByBot = [...resolvedByBot, ...(resolvedByBotData?.length > 0 ? resolvedByBotData : [0]),]
      expired = [...expired, ...(expiredData?.length > 0 ? expiredData : [0]),]
    } else {
      open = [...open, 0]
      pending = [...pending, 0]
      closed = [...closed, 0]
      resolvedByAgent = [...resolvedByAgent, 0]
      resolvedByBot = [...resolvedByBot, 0]
      expired = [...expired, 0]
    }
    console.log(`serviceData=>data`, { data });
  }
  data.datasets[0].data = open;
  data.datasets[1].data = pending;
  data.datasets[2].data = closed;
  data.datasets[3].data = resolvedByAgent;
  data.datasets[4].data = resolvedByBot;
  data.datasets[5].data = expired;
  return data;
}
function* getMessagrSummaryPieChart(chartInfo) {
  return {
    labels: ['Campaigns', 'Bot', 'Agents', 'User Replies'],
    datasets: [
      {
        data: [chartInfo?.campaign, chartInfo?.bot, chartInfo?.agent, chartInfo?.userMessages],
        backgroundColor: [
          '#FF0080',
          '#C200FF',
          '#FFC643',
          '#1DDD8D'
        ],
        borderColor: [
          '#FF0080',
          '#C200FF',
          '#FFC643',
          '#1DDD8D'
        ],
        borderWidth: 1,
      },
    ],
  };
}
function* dashboardSaga() {
  yield all(
    [fork(watchGetChartsData),
    fork(watchDashBoardTicekInfo)])
}

export default dashboardSaga
