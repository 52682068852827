import * as types from "./actionTypes"
import {
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTION_LOADING,
} from "../../common/actionTypes"

export const setResetSignUp = () => ({
  type: types.RESET_SIGNUP_STATE,
})
export const createAccountRequest = (requestData, history, resetForm) => ({
  type: types.CREATE_ACCOUNT_REQUEST,
  payload: { requestData, history, resetForm },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createAccountResponse = responseData => ({
  type: types.CREATE_ACCOUNT_REQUEST,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const signUpAlertMessage = alertMessageData => ({
  type: types.SIGNUP_ALERT_MESSAGE,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
