import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING, LANDING_PAGE_LOADING } from "../common/actionTypes"

export const getAllMessageRulesDataRequest = () => ({
    type: types.GET_MESSAGE_RULES_REQUEST,
    loadType: LANDING_PAGE_LOADING,
    loadPayload: true
})

export const getAllMessageRulesResopnse = (messageRuleData, alertMessageData) => ({
    type: types.GET_MESSAGE_RULES_RESPONSE,
    payload: messageRuleData,
    loadType: LANDING_PAGE_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData
})

export const cancleAllPendingRequestinMessageRules = () => ({
    type: types.CANCEL_ALL_PENDING_REQUEST_MESSAGE_RULES
})

export const setMessageRulesModelOpen = (actionType, actionData, isEdit = false) => {
    return {
        type: types.SET_MESSAGERULES_MODEL_OPEN,
        payload: { actionType, actionData, isEdit },

    }
}
export const setopenKeyWordsModel = (actionType, data) => {
    return {
        type: types.SET_KEY_WORDS_MODEL_OPEN,
        payload: { actionType, data }
    }
}
export const validateKeywordRequest = (allKeywords, setFieldValue, currentkeyWord) => {
    return {
        type: types.VALIDATE_KEYWORD_REQUEST,
        payload: { allKeywords, setFieldValue, currentkeyWord },
        loadType: ACTION_LOADING,
        loadPayload: true
    }
}

export const validateKeywordResponse = (alertMessageData, failedMessage, keyWOrdModelCheck) => ({
    type: types.VALIDATE_KEYWORD_RESPONSE,
    payload: { failedMessage, keyWOrdModelCheck },
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData
})
export const UpdateKeyWordsAfterRemoving = (updatedKeyWords) => ({
    type: types.UPDATE_KEYWORDS_IN_RULES_AFTER_DELETE,
    payload: updatedKeyWords
})

export const getOneTimeMessageRepliesRequest = () => ({
    type: types.GET_ONE_TIME_MESSAGE_REPLIES_REQUEST,
    loadType: ACTION_LOADING,
    loadPayload: true

})

export const getOneTimessageRepliesResponse = (oneTimeRplyTypes, alertMessageData) => ({
    type: types.GET_ONE_TIME_MESSAGE_REPLIES_RESPONSE,
    payload: oneTimeRplyTypes,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData

})

export const selectedQuickRepliesData = (selectedQuickReplies) => ({
    type: types.SELECTED_QUICK_REPLIES,
    payload: selectedQuickReplies
})
export const selectedFlowsData = (selectedFlowsReplies) => ({
    type: types.SELECTED_FLOW_REPLIES,
    payload: selectedFlowsReplies
})


export const createOrUpdateMessageRulesRequest = (messageRule, objectState) => ({
    type: types.CREATE_MESSAGE_RULE_REQUEST,
    payload: { messageRule, objectState },
    loadType: ACTION_LOADING,
    loadPayload: true
})
export const createOrUpdateMessageRulesResponse = (responseStatus, previousMessagesData, alertMessageData) => ({
    type: types.CREATE_MESSAGE_RULE_RESPONSE,
    payload: { responseStatus, previousMessagesData },
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
})

export const getFlowsMessageRepliesRequest = () => ({
    type: types.GET_FLOW_MESSAGE_REPLIES_REQUEST,
    loadType: ACTION_LOADING,
    loadPayload: true

})

export const getFlowsMessageRepliesResponse = (flowTypesData, alertMessageData) => ({
    type: types.GET_FLOW_MESSAGE_REPLIES_RESPONSE,
    payload: flowTypesData,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData

})






