export const GET_REFERRALS = "UserReferral/GetByUserId/{userId}"

// AUTH
export const CREATE_ACCOUNT = "auth/signup"
export const LOGIN_ACCOUNT = "auth/login"
export const LOGOUT_ACCOUNT = "auth/logout"
export const RESET_PASSWORD = "auth/reset"
export const FORGET_PASSWORD = "auth/forget"
export const NEW_PASSWORD = "auth/reset"
export const SEND_VERIFICATION_KEY = "auth/validate"
export const GOOGLE_USER = ""
export const CREATE_NEW_PASSWORD = ""

// Contact Managemant Apis
export const GET_ALL_CONTACTS = "contact/search-all"
export const GET_SEARCHED_CONTACTS = "contact/search"
export const CONTACT_CREATE = "contact/create"
export const CONTACT_BULK_UPLOAD = "contact/bulk"

// CodeConfiguraton Apis
export const CODE_ENQUIRY = "code/enquiry"
export const CODE_CODEVALUES = "code/codevalues"
export const CREATE_CODE_CONFIG = "code/create"

// Email Templates
export const GET_EMAIL_TEMPLATES = "email/template/enquiry"
export const EMAIL_TEMPLATE_CRUD = "email/template/create"
export const EMAIL_TEMPLATE_ATTACHMENTS_UPLOAD = "file/upload"
export const EMAIL_TEMPLATE_ATTACHMENTS_DOWNLOAD = "file/download"

//WhatsApp Templates
export const GET_All_WHATS_APP_TEMPLATES = "whatsapp/getall"
export const DELETE_WHATS_APP_TEMPELATE = "whatsapp/delete_template"
export const CREATE_OR_EDIT_WAP_TEMPLATE = "whatsapp/create_template"
export const UPLOAD_WHATS_APP_TEMPLATE_STATUS = "whatsapp/upload_template"
export const GET_WPA_PHONE_NUMBERS = "whatsapp/account-details"
export const UPLOAD_WHATS_APP_MEDIA = "whatsapp/upload_media"
export const IS_CHECK_FOR_TEMPLATE_IS_TAGED_OR_NOT = "whatsapp/template-tagged"

// Campaign Management
export const GET_CAMPAIGNS = "allcampaigns"
export const CREATE_CAMPAIGN = "campaigns"
export const VIEW_CAMPAIGN_INSIGHTS = "campaignsummary"

// QrCode Templates
export const GET_ALL_QR_CODES = "message_qrdls/getall"
export const CREATE_QR_CODE = "message_qrdls/create"
export const UPDATE_QR_CODE = "message_qrdls/update"
export const DELETE_QR_CODE = "message_qrdls/deleteqrcode"

// Pdpa
export const GET_CAMPAIGN_PDPA_DATA = "pdpadata"

// Insights Modules
export const GET_INSIGHTS_FOR_ALL_MESSAGES = "analytics/get-Analytics"
export const GET_INSIGHTS_FOR_CONVERSTIONS = "analytics/getCon-Analytics"

// Profile Module
export const GET_PROFILE_DETAILS = "user/profile-enquiry"
export const UPDATE_PROFILE_DETAILS = "user/update-profile"
export const CHANGE_PASSWORD = "user/change"
export const PHONE_NO_ID_DETAILS = "analytics/phone-no-id-details"

// https://www.backend-api.notifyy.io/v3/api/campaigns

// Cron Job
export const GET_ALL_JOBS = "allJobs"
export const CREATE_CRON_JOB = "createNewJob"
export const UPDATE_CRON_JOB = "updateJob"
export const PAUSE_CRON_JOB = "pauseJob"
export const RESUME_CRON_JOB = "resumeJob"
export const DELETE_CRON_JOB = "delete"

// Feedback
export const GET_FEEDBACKS = "feedback/get"
export const CREATE_FEEDBACK = "feedback/create"

// Whatsapp web
export const GET_WHATSAPP_CHAT_CONVERSATION = "web/chat-conversation"
export const GET_WHATSAPP_CHAT_LIST = "web/chat-numbers"
export const MEDIA_UPLOAD = "file/upload"
export const MEDIA_DOWNLOAD = "file/download"
export const ENABLE_CHAT = "analytics/enable-chat"
export const GET_WHATSAPP_SCHEDULED_MESSAGES = "schedule/messages"
export const SCHEDULE_WHATSAPP_MESSAGE = "schedule/message"
export const ADD_UPDATE_DELETE_QUICK_REPLY = "web/quick-replies-create"

// subscriptions
export const GET_ALL_PLANS = "subscription/plans"
export const CREATE_SUBSCRIPTION_LINK = "subscription/link"
export const CREATE_SUBSCRIPTION_ON_NOTIFY_SYSTEM = "subscription/create"
export const GET_ALL_SUBSCRIPTIONS = "subscription/enquiry"
export const CANCEL_SUBSCRIPTIONS = "subscription/cancel"
export const GET_ALL_INVOICE = "subscription/invoice"

//message rules
export const CREATE_MESSAGE_RULES = "rule/create"
export const GET_RULES_ENQUIRY = "rule/enquiry"
export const ENQUIRY_KEY_WORDS = "rule/keywords"
// export const REPLY_ENQUIRY_MESSSAGES = "web/replies-enquiry";
export const QUICK_REPLY_ENQUIRY_MESSSAGES = "web/quick-replies-enquiry"
export const FLOWS_TYPES_MESSAGES = "rule/flow/enquiry"
// User Managemant
export const GET_ADMIN_AGENTS = "agent/agent_enquiry"
export const ADD_AGENTS = "agent/create"
export const GET_ROLES_PERMISSIONS = "agent/permission-enquiry"
export const UPDATE_ROLES_PERMISSIONS = "agent/permission"

// Flows
export const CREATE_FLOW = "rule/flow/create"

// To Add/Update Test the WABA Account Details
export const TEST_WABA_ACCOUNT_DETAILS = "admin/test-wba-account"
export const UPDATE_ACCOUNT_DETAILS = "admin/update-wba-details"

// default settings
export const SETTINGS_UPDATE_NOKEYWORD = "settings/update-answer-variable"

// Availability status
export const UPDATE_USER_AVAILABILITY_STATUS = "agent/update/{agentId}"
// DashBoards
export const DASHBOARD_TICKET_SUMMARY = "dashboard/tickets-summary"
export const DASHBOARD_MESSAGE_VOLUME = "dashboard/messages-volume"
export const DASHBOARD_AGENT_PERFOERMENCE = "dashboard/agent-performance"
