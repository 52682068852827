import {
  ACTION_LOADING,
  LAYOUT_ALERT_ACTION_REQUEST,
  LANDING_PAGE_LOADING
} from "store/common/actionTypes"
import * as types from "./actionTypes"

export const cancelAllPendingRequestForSubscriptions = () => ({
  type: types.CANCLE_ALL_PENDING_REQUEST_OF_SUBSCRIPTIONS,
})
// To Get The Subscription Model Open
export const setModelForSubscriptionModel = (actionType, actionData) => ({
  type: types.SET_MODEL_FOR_SUBSCRIPTIONS_REQUEST,
  payload: { actionData, actionType },
})
// To Get all the subscriptions plans
export const getAllSubscriptionPlansRequest = (isLandingPage = false) => ({
  type: types.GET_ALL_SUBSCRIPTION_PLANS_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: isLandingPage,
})
export const getAllSubscriptionsResponse = (response, alertMessageData) => ({
  type: types.GET_ALL_SUBSCRIPTION_PLANS_RESPONSE,
  payload: response,
  loadPayload: false,
  loadType: LANDING_PAGE_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

// To Create Or Update the subscription

export const createOrUpdateSubscriptionRequest = (actionType, requestObj) => ({
  type: types.CREATE_OR_UPDATE_SUBSCRIPTIONS_REQUEST,
  payload: { actionType, requestObj },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createOrUpdateSubscriptionResponse = (response, alertMessageData) => ({
  type: types.CREATE_OR_UPDATE_SUBSCRIPTIONS_RESPONSE,
  payload: response,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});

export const getAllCreateSubscriptionsRequest = () => ({
  type: types.GET_ALL_CREATED_SUBSCRIPTION_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
});

export const getAllCreateSubscriptionsResponse = (reponseData, alertMessageData) => ({
  type: types.GET_ALL_CREATED_SUBSCRIPTION_RESPONSE,
  payload: reponseData,
  loadPayload: false,
  loadType: LANDING_PAGE_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});

export const cancelSubscriptionRequest = (requestData) => ({
  type: types.CANCEL_SUBSCRIPTION_REQUEST,
  payload: requestData,
  loadType: ACTION_LOADING,
  loadPayload: true
});
export const cancelSubscriptionResponse = (reponseData, alertMessageData) => ({
  type: types.CANCEL_SUBSCRIPTION_RESPONSE,
  payload: reponseData,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});
export const showModelForFeedBackRequest = (action = false) => ({
  type: types.SHOW_MODE_FOR_FEED_BACK_REQUEST,
  payload: action
});

export const submitFeedBackRequest = (action) => ({
  type: types.SUBMIT_FEED_BACK_REQUEST,
  payload: action,
  loadPayload: true,
  loadType: ACTION_LOADING
});
export const submitFeedBackResponse = (response, alertMessageData) => ({
  type: types.SUBMIT_FEED_BACK_RESPONSE,
  payload: response,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});

export const getAllInvoicesRequest = (request) => ({
  type: types.GET_ALL_SUBSCRIPTION_INVOICES_REQUEST,
  payload: request,
  loadPayload: true,
  loadType: ACTION_LOADING
});
export const getAllInvoiceResponse = (response, alertMessageData) => ({
  type: types.GET_ALL_SUBSCRIPTION_INVOICES_RESPONSE,
  payload: response,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});

export const createGstNumberForSubscriptionRequest = (requestObj) => ({
  type: types.ADD_GST_NUMBER_TO_SUBSCRIPTION_REQUEST,
  payload: requestObj,
  loadPayload: true,
  loadType: ACTION_LOADING
});
export const createGstNumberForSubscriptionResponse = (response, alertMessageData) => ({
  type: types.ADD_GST_NUMBER_TO_SUBSCRIPTION_RESPONSE,
  payload: response,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});