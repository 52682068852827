export const CANCEL_ALL_PENDING_REQUEST_FOR_PROFILE =
  "CANCEL_ALL_PENDING_REQUEST_FOR_PROFILE"

export const SHOW_MODEL_FOR_UPDATE = "SHOW_MODEL_FOR_UPDATE"
export const GET_PROFILE_DETAILS_REQUEST = "GET_PROFILE_DETAILS_REQUEST"
export const GET_PROFILE_DETAILS_RESPONSE = "GET_PROFILE_DETAILS_RESPONSE"
export const UPLOADING_PROFILE_FILE_DATA_REQUEST =
  "UPLOADING_PROFILE_FILE_DATA_REQUEST"
export const UPLOADING_PROFILE_FILE_DATA_RESPONSE =
  "UPLOADING_PROFILE_FILE_DATA_RESPONSE"
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_RESPONSE = "CHANGE_PASSWORD_RESPONSE"
export const UPDATE_PROFILE_DETAILS_REQUEST = "UPDATE_PROFILE_DETAILS_REQUEST"
export const UPDATE_PROFILE_DETAILS_RESPONSE = "UPDATE_PROFILE_DETAILS_RESPONSE"
export const GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_REQUEST =
  "GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_REQUEST"
export const GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_RESPONSE =
  "GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_RESPONSE"

export const OPEN_MODEL_FOR_CREATE_OR_UPDATE_PHONE_NUMBER =
  "OPEN_MODEL_FOR_CREATE_OR_UPDATE_PHONE_NUMBER"
export const GET_ALL_CREATED_PHONE_NUMBERS_REQUEST =
  "GET_ALL_CREATED_PHONE_NUMBERS_REQUEST"
export const GET_ALL_CREATED_PHONE_NUMBERS_RESPONSE =
  "GET_ALL_CREATED_PHONE_NUMBERS_RESPONSE"
export const CREATE_OR_EDIT_WABA_PHONE_NUMBERS_REQUEST =
  "CREATE_OR_EDIT_WABA_PHONE_NUMBERS_REQUEST"
export const CREATE_OR_EDIT_WABA_PHONE_NUMBERS_RESPONSE =
  "CREATE_OR_EDIT_WABA_PHONE_NUMBERS_RESPONSE"

export const TEST_WABA_ACCOUNT_DETIALS_REQUEST = "TEST_WABA_ACCOUNT_DETIALS_REQUEST"
export const TEST_WABA_ACCOUNT_DETIALS_RESPONSE =
  "TEST_WABA_ACCOUNT_DETIALS_RESPONSE"
export const UPDATE_USER_AVAILABILITY_STATUS_REQUEST =
  "UPDATE_USER_AVAILABILITY_STATUS_REQUEST"
export const UPDATE_USER_AVAILABILITY_STATUS_RESPONSE =
  "UPDATE_USER_AVAILABILITY_STATUS_RESPONSE"
export const UPDATE_AGENT_PROFILE_REQUEST = "UPDATE_AGENT_PROFILE_REQUEST"
export const UPDATE_AGENT_PROFILE_RESPONSE = "UPDATE_AGENT_PROFILE_RESPONSE"
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
