import * as types from "./actionTypes"

const INIT_STATE = {
  forgotPassword: undefined,
  email: "",
  changePasswordResponse: false,
}

const forgotPasswordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.CANCEL_ALL_PENTDING_ACTIVITIES:
      state = {
        ...state,
        forgotPassword: undefined,
        email: "",
        changePasswordResponse: false,
      }
      break
    case types.RESET_FORGOT_PASSWORD_STATE:
      state = {
        ...state,
        forgotPassword: undefined,
      }
      break
    case types.VALIDATE_FORGET_PASSWORD_KET_RESPONSE:
      state = {
        ...state,
        email: action.payload,
      }
      break
    case types.NEW_PASSWORD_RESPONSE:
      state = {
        ...state,
        changePasswordResponse: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}
export default forgotPasswordReducer
