import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING, LANDING_PAGE_LOADING } from "../common/actionTypes"

export const getSelectionStateForComponet = actionType => ({
  type: types.SELECTION_STATE_FOR_COMPONENTS,
  payload: actionType,
})

export const getChatItemsRequest = (pageNumber, searchNumber = "") => ({
  type: types.GET_CHAT_ITEMS_REQUEST,
  payload: { pageNumber, searchNumber },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getChatItemsResponse = (
  chatItems,
  totalContacts,
  updatedChatsPaginationData,
  alertMessageData
) => ({
  type: types.GET_CHAT_ITEMS_RESPONSE,
  payload: { chatItems, totalContacts, updatedChatsPaginationData },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const setWhatsappWebStateByKey = (key, value) => ({
  type: types.SET_WHATSAPP_WEB_STATE_BY_KEY,
  payload: { key, value },
})

export const getConversationsRequest = ({ phoneNumber, pageNumber }) => ({
  type: types.GET_CONVERSATIONS_REQUEST,
  payload: { phoneNumber, pageNumber },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getConversationsResponse = (
  conversations,
  conversationPaginationData,
  totalConversations,
  alertMessageData
) => ({
  type: types.GET_CONVERSATIONS_RESPONSE,
  payload: { conversations, conversationPaginationData, totalConversations },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const socketMessageReceived = (payload, masterPhoneNumber) => ({
  type: types.SOCKET_MESSAGE_RECEIVED,
  payload,
  masterPhoneNumber,
})

export const socketMessageStatusReceived = payload => ({
  type: types.SOCKET_MESSAGE_STATUS_RECEIVED,
  payload,
})

export const socketMessageMappingReceived = payload => ({
  type: types.SOCKET_MESSAGE_MAPPING_RECEIVED,
  payload,
})

export const getEnableChatDetailsRequest = ({
  cin,
  phoneNumber,
  masterPhoneNumber,
}) => ({
  type: types.GET_ENABLE_CHAT_DETAILS_REQUEST,
  payload: { cin, phoneNumber, masterPhoneNumber },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getEnableChatDetailsResponse = (
  updatedChatItems,
  alertMessageData
) => ({
  type: types.GET_ENABLE_CHAT_DETAILS_RESPONSE,
  payload: updatedChatItems,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

// To Get the list and Replay buttons messages
export const getAllListAndReplyMessageTemplateRequest = type => ({
  type: types.GET_LIST_AND_REPLAY_MESSAGES_REQUEST,
  payload: type,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getAllListAndReplyMessageTemplateResponse = (
  listAndReplyMessageData,
  alertMessageData
) => ({
  type: types.GET_LIST_AND_REPLAY_MESSAGES_RESPONSE,
  payload: listAndReplyMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const fetchMoreConversationsRequest = phoneNumber => ({
  type: types.FETCH_MORE_CONVERSATIONS_REQUEST,
  payload: phoneNumber,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const fetchMoreConversationsResponse = (
  fetchedConversations,
  conversationsPaginationData,
  alertMessageData
) => ({
  type: types.FETCH_MORE_CONVERSATIONS_RESPONSE,
  payload: { fetchedConversations, conversationsPaginationData },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const initiateNewChat = (toNumber, name = null) => ({
  type: types.INITIATE_NEW_CHAT,
  payload: { toNumber, name },
})

export const updateConversationWithEmittedMessage = payload => ({
  type: types.UPDATE_CONVERSATION_WITH_EMITTED_MESSAGE,
  payload,
})

export const getWhatsappScheduledMessagesRequest = () => ({
  type: types.GET_WHATSAPP_SCHEDULED_MESSAGES_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getWhatsappScheduledMessagesResponse = (
  whatsappScheduledMessages,
  alertMessageData
) => ({
  type: types.GET_WHATSAPP_SCHEDULED_MESSAGES_RESPONSE,
  payload: whatsappScheduledMessages,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const createOrUpdateWhatsappScheduledMessageRequest = requestObj => ({
  type: types.CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_REQUEST,
  payload: requestObj,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const createOrUpdateWhatsappScheduledMessageResponse = (
  updatedScheduledMessages,
  alertMessageData
) => ({
  type: types.CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_RESPONSE,
  payload: updatedScheduledMessages,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const getMarketOptOutMobileNumbersRequest = () => ({
  type: types.GET_MARKET_OPT_OUT_MOBILE_NUMBERS_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})

export const getMarketOptOutMobileNumbersResponse = marketOptOutMobileNumbers => ({
  type: types.GET_MARKET_OPT_OUT_MOBILE_NUMBERS_RESPONSE,
  payload: { marketOptOutMobileNumbers },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
})

export const showChartOptionsForMenu = value => ({
  type: types.SHOW_CHART_OPTIONS_MENU_REQUEST,
  payload: value,
});

export const getAgentsDataRequest = () => ({
  type: types.GET_AGENTS_REQUEST,
  loadPayload: true,
  loadType: ACTION_LOADING
});
export const getAgentsDataResponse = (agentsData, alertMessageData) => ({
  type: types.GET_AGENTS_RESPONSE,
  payload: agentsData,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});
export const getLablesBasedOnTicketIDRequest = (ticketID) => ({
  type: types.GET_LABELS_BASED_ON_TICKET_ID_REQUEST,
  loadPayload: true,
  payload: ticketID,
  loadType: ACTION_LOADING
});
export const getLablesBasedOnTicketIDResponse = (agentsData, alertMessageData) => ({
  type: types.GET_LABELS_BASED_ON_TICKET_ID_RESPONSE,
  payload: agentsData,
  loadPayload: false,
  loadType: ACTION_LOADING,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});
export const isClosedCoverstionStatusRequest = (isClosedStatus) => ({
  type: types.SHOW_MODEL_FOR_CLOSED_STATUS_REQUEST,
  payload: isClosedStatus
});

export const setTicketStatusMappingForReceviedContact = (requestPayload) => ({
  type: types.SET_TICKET_STATUS_FOR_MESSAGE_RECEIVED_CONTACTS_REQUEST,
  payload: requestPayload
});
