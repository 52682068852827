import { LANDING_PAGE_LOADING, LAYOUT_ALERT_ACTION_REQUEST } from "store/common/actionTypes";
import * as types from "./actionTypes"

export const cancelAllPendingRequestsForDashBoard = () => ({
  type: types.CANCEL_ALL_PENDING_REQUESTS_FOR_DASHBOARD
});
export const getDashBoardRequest = (
  requestObj,
) => ({
  type: types.GET_DASHBOARD_DATA_REQUEST,
  payload: { requestObj },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})
export const getDashboardResponse = (conversationLineChartAnalytics, conversationSummaryGraphData, conversarionMessageInsightsLineChart, conversarionMessageInsightsBarChart, conversationWigetData, paidConversationsBarGraphData, paidConversationPieGraphData,
  alertMessageData
) => ({
  type: types.GET_DASHBOARD_DATA_RESPONSE,
  payload: { conversationLineChartAnalytics, conversationSummaryGraphData, conversarionMessageInsightsLineChart, conversarionMessageInsightsBarChart, conversationWigetData, paidConversationsBarGraphData, paidConversationPieGraphData },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});
export const getDashBoardTicketInfoRequest = (requestObj) => ({
  type: types.GET_DASHBOARD_TICKET_INFO_REQUEST,
  payload: requestObj,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
});
export const getDashboardTicketInfoResponse = (ticketSummaryData, ticketsDataPoints, resolutions, barChartTicketSummary, ticketInfoStatusChartdata, messageSummaryPieChart, messageDataPointsChartdata, messageVolumeSummaryPercentage, agentDataPoints, alertMessageData) => ({
  type: types.GET_DASHBOARD_TICKET_INFO_RESPONSE,
  payload: { ticketSummaryData, ticketsDataPoints, resolutions, barChartTicketSummary, ticketInfoStatusChartdata, messageSummaryPieChart, messageDataPointsChartdata, messageVolumeSummaryPercentage, agentDataPoints },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
});

export const setSelectedDataAndPhoneNumberRequest = (selectedValues) => ({
  type: types.SET_DATE_AND_PHONE_NUMBER_SELECTION_DASDBOARD_REQUEST,
  payload: selectedValues
})
// export const setSelectedDataAndPhoneNumberRequest = (selectedValues) => ({
//   type: types.SET_DATE_AND_PHONE_NUMBER_SELECTION_REQUEST,
//   payload: selectedValues
// });