import { call, put, all, fork, takeLeading } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as actions from "./actions"
import * as urls from "../../../helpers/url_helper"
import { Post } from "../../../helpers/api_helper"
const errors = {}

function* sendVerificationLinkToMail(action) {
  let alertMessageData
  try {
    const RreqObj = {
      emailId: action.payload.mail,
    }
    console.log("sendVerificationLinkToMail==>", RreqObj, action)
    const response = yield call(Post, urls.FORGET_PASSWORD, RreqObj)
    console.log("sendVerificationLinkToMail==>_res", response)

    if (response && response.data && response.data.status) {
      alertMessageData = {
        status: response.data.status,
        message:
          response?.data?.message || "Successfully send verification link to mail",
      }
      action.payload.setFieldValue("errorFlag", response.data)
    } else {
      alertMessageData = {
        status: false,
        message:
          response?.data?.message || "Unable to send verification link to mail",
      }
      action.payload.setFieldValue("errorFlag", response.data)
    }
  } catch (error) {
    console.log("error", error)
    alertMessageData = {
      status: false,
      message: "Faild to send verification link to mail",
    }
    action.payload.setFieldValue("errorFlag", error.data)
  }
  yield put(actions.forgotPasswordAlertMessage(alertMessageData))
}
function* validationForgerPasswordKey(action) {
  console.log("validationForgerPasswordKey==>", action.payload)
  let emailId, alertMessageData
  const RreqObj = {
    key: action.payload,
  }
  try {
    const response = yield call(Post, urls.SEND_VERIFICATION_KEY, RreqObj, false)
    if (response && response.data && response.data.status) {
      emailId = response?.data?.emailId
      alertMessageData = {
        status: true,
        message: response?.data?.message || errors.NEW_PASSWORD_CREATED_SUCCESSFULLY,
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.data?.message || "Unable to verification",
      }
    }
  } catch (error) {
    alertMessageData = { status: false, message: "Faild to verification" }
  }
  yield put(actions.validationForgerPasswordKeyResponse(emailId, alertMessageData))
}
function* createNewPassword(action) {
  let alertMessageData
  let responseData = false
  console.log("createNewPassword=>", action)
  try {
    const RreqObj = {
      emailId: action?.payload?.mail || "",
      password: action?.payload?.newPassword || "",
    }
    const response = yield call(Post, urls.NEW_PASSWORD, RreqObj, false)
    console.log("response=>", response)
    if (response && response.data && response.data.status) {
      responseData = response.data.status
      alertMessageData = {
        status: response?.data?.status,
        message: response?.data?.message || errors.NEW_PASSWORD_CREATED_SUCCESSFULLY,
      }
      // action.payload?.history.push('/');
    } else {
      responseData = response.data.status
      alertMessageData = {
        status: response?.data?.status,
        message: response?.data?.message || errors.UNABLE_TO_CREATE_NEW_PASSWORD,
      }
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: errors.FAILED_TO_CREATE_NEW_PASSWORD,
    }
  }
  yield put(actions.newPasswordResponse(responseData, alertMessageData))
}

export function* watchForgotPassword() {
  yield takeLeading(types.SEND_VERIFICATION_LINK_REQUEST, sendVerificationLinkToMail)
  yield takeLeading(
    types.VALIDATE_FORGET_PASSWORD_KET_REQUEST,
    validationForgerPasswordKey
  )
  yield takeLeading(types.NEW_PASSWORD_REQUEST, createNewPassword)
}
function* forgotPasswordSaga() {
  yield all([fork(watchForgotPassword)])
}
export default forgotPasswordSaga
