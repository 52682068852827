import * as types from './actionTypes';
const INIT_STATE = {
  chartsData: [],
  selectedDataAndPhoneNumber: undefined,
  conversationLineChartAnalytics: undefined,
  conversationSummaryGraphData: undefined,
  conversarionMessageInsightsLineChart: undefined,
  conversarionMessageInsightsBarChart: undefined,
  conversationWigetData: undefined,
  paidConversationsBarGraphData: undefined,
  paidConversationPieGraphData: undefined,
  ticketSummaryData: undefined,
  ticketsDataPoints: undefined,
  resolutions: undefined,
  barChartTicketSummary: undefined,
  ticketInfoStatusChartdata: undefined,
  messageSummaryPieChart: undefined,
  messageDataPointsChartdata: undefined,
  messageVolumeSummaryPercentage: undefined,
  agentDataPoints: undefined
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SET_DATE_AND_PHONE_NUMBER_SELECTION_DASDBOARD_REQUEST:
      return {
        ...state,
        selectedDataAndPhoneNumber: action.payload
      }
    case types.GET_DASHBOARD_DATA_RESPONSE:
      return {
        ...state,
        conversationLineChartAnalytics: action.payload.conversationLineChartAnalytics,
        conversationSummaryGraphData: action.payload.conversationSummaryGraphData,
        conversarionMessageInsightsLineChart: action.payload.conversarionMessageInsightsLineChart,
        conversarionMessageInsightsBarChart: action.payload.conversarionMessageInsightsBarChart,
        conversationWigetData: action.payload.conversationWigetData,
        paidConversationsBarGraphData: action.payload.paidConversationsBarGraphData,
        paidConversationPieGraphData: action.payload.paidConversationPieGraphData,
      }
    case types.GET_DASHBOARD_TICKET_INFO_RESPONSE:
      return {
        ...state,
        ticketSummaryData: action.payload.ticketSummaryData,
        ticketsDataPoints: action.payload.ticketsDataPoints,
        resolutions: action.payload.resolutions,
        barChartTicketSummary: action.payload.barChartTicketSummary,
        ticketInfoStatusChartdata: action.payload.ticketInfoStatusChartdata,
        messageSummaryPieChart: action.payload.messageSummaryPieChart,
        messageDataPointsChartdata: action.payload.messageDataPointsChartdata,
        messageVolumeSummaryPercentage: action.payload.messageVolumeSummaryPercentage,
        agentDataPoints: action.payload.agentDataPoints,
      }
    default:
      return state
  }
}

export default Dashboard
