import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING } from "../common/actionTypes"

export const getAllQuickReplyDataRequest = () => ({
    type: types.GET_ALL_QUICKREPLY_DATA_REQUEST,
    loadType: ACTION_LOADING,
    loadPayload: true
})

export const getAllQuickReplyDataResponse = (allReplyContentData, alertMessageData) => ({
    type: types.GET_ALL_QUICKREPLY_DATA_RESPONSE,
    payload: allReplyContentData,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData
})

// export const setSelectedReplyContent = (selectedContentType) => ({
//     type: types.S,
//     payload: selectedContentType
// })

export const openModelBasedOnQuickReplyType = (replyType, actionData) => ({
    type: types.OPEN_MODEL_BASED_ON_QUICK_REPLY_TYPE,
    payload: { replyType, actionData }

})
export const createOrUpdateQuickReplyItemRequest = (replyItem, objectState) => ({
    type: types.CREATE_QUICK_REPLY_REQUEST,
    payload: { replyItem, objectState },
    loadType: ACTION_LOADING,
    loadPayload: true
})
export const createOrUpdateQuickReplyContentResponse = (responseStatus, previousReplyItemsData, alertMessageData) => ({
    type: types.CREATE_QUICK_REPLY_RESPONSE,
    payload: { responseStatus, previousReplyItemsData },
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
})
