import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes";
import { ONETIME_MESSAGE_RULES } from '../../constants/index';


const intialState = {
    actionType: ACTION_TYPES.SELECT,
    actionData: undefined,
    replyContentData: undefined,
    selectedContentType: ONETIME_MESSAGE_RULES.text,
    openReplyModelType: ''

}

const QuickReplyReducer = (state = intialState, action) => {
    switch (action.type) {
        case types.CANCEL_ALL_PENDING_REQUEST_QUICKREPLY:
            state = {
                actionType: ACTION_TYPES.SELECT,
                actionData: undefined,
                replyContentData: undefined,
                selectedContentType: ONETIME_MESSAGE_RULES.text,
                openReplyModelType: ''

            }
            break;
        case types.GET_ALL_QUICKREPLY_DATA_RESPONSE:
            state = {
                replyContentData: action?.payload
            }
            break;
        // case types.SET_SELECTED_REPLY_TYPE_FOR_VIEW:
        //     state = {
        //         ...state,
        //         selectedContentType: action?.payload

        //     }
        //     break;
        case types.OPEN_MODEL_BASED_ON_QUICK_REPLY_TYPE:
            const { replyType, actionData } = action?.payload
            state = {
                ...state,
                openReplyModelType: replyType,
                actionData: actionData
            }
            break;
        case types.CREATE_QUICK_REPLY_RESPONSE:
            if (action.payload.responseStatus) {
                state = {
                    ...state,
                    actionType: ACTION_TYPES.SELECT,
                    replyContentData: action?.payload?.previousReplyItemsData,
                    selectedContentType: state?.selectedContentType,
                    openReplyModelType: '',
                    actionData: ''
                }
            }
            break;
        default:
            state = { ...state }
    }
    return state

}

export default QuickReplyReducer