export const SHOW_MODAL_DATA_REQUEST_FOR_WHATSAPP_TEMPLATE =
  "SHOW_MODAL_DATA_REQUEST_FOR_WHATSAPP_TEMPLATE"
export const CANCEL_ALL_PENDING_REQUEST = "CANCEL_ALL_PENDING_REQUEST"

export const GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_REQUEST =
  "GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_REQUEST"
export const GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_RESPONSE =
  "GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_RESPONSE"

export const DELETE_WHATS_APP_TEMPELATE_REQUEST =
  "DELETE_WHATS_APP_TEMPELATE_REQUEST"
export const DELETE_WHATS_APP_TEMPELATE_RESPONSE =
  "DELETE_WHATS_APP_TEMPELATE_RESPONSE"

export const CREATE_OR_UPDATE_WPA_TEMPLATE_REQUEST =
  "CREATE_OR_UPDATE_WPA_TEMPLATE_REQUEST"
export const CREATE_OR_UPDATE_WPA_TEMPLATE_RESPONSE =
  "CREATE_OR_UPDATE_WPA_TEMPLATE_RESPONSE"

export const UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_REQUEST =
  "UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_REQUEST"
export const UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_RESPONSE =
  "UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_RESPONSE"

export const UPLOADING_FILE_DATA_REQUEST = "UPLOADING_FILE_DATA_REQUEST"
export const UPLOADING_FILE_DATA_RESPONSE = "UPLOADING_FILE_DATA_RESPONSE"

export const GET_DEFAULT_WAP_TEPMLATES_REQUEST = "GET_DEFAULT_WAP_TEPMLATES_REQUEST"
export const GET_DEFAULT_WAP_TEPMLATES_RESPONSE =
  "GET_DEFAULT_WAP_TEPMLATES_RESPONSE"

export const GET_TEMPLATE_NAME_EXSITS_OR_NOT_REQUEST =
  "GET_TEMPLATE_NAME_EXSITS_OR_NOT_REQUEST"
export const GET_TEMPLATE_NAME_EXSITS_OR_NOT_RESPONSE =
  "GET_TEMPLATE_NAME_EXSITS_OR_NOT_RESPONSE"
