export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST"
export const NEW_PASSWORD_RESPONSE = "NEW_PASSWORD_RESPONSE"

export const SEND_VERIFICATION_LINK_REQUEST = "SEND_VERIFICATION_LINK_REQUEST"
export const SEND_VERIFICATION_LINK_RESPONSE = "SEND_VERIFICATION_LINK_RESPONSE"

export const VALIDATE_FORGET_PASSWORD_KET_REQUEST =
  "VALIDATE_FORGET_PASSWORD_KET_REQUEST"
export const VALIDATE_FORGET_PASSWORD_KET_RESPONSE =
  "VALIDATE_FORGET_PASSWORD_KET_RESPONSE"

export const GET_FORGOT_PASSWORD_ALERT_MESSAGE = "GET_FORGOT_PASSWORD_ALERT_MESSAGE"

export const RESET_FORGOT_PASSWORD_STATE = "RESET_FORGOT_PASSWORD_STATE"
export const CANCEL_ALL_PENTDING_ACTIVITIES = "CANCEL_ALL_PENTDING_ACTIVITIES"
