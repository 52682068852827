import * as types from "./actionTypes"
const initialState = {
  actionType: "select",
  codeConfigValues: [],
  actionData: undefined,
  codeValues: [],
}
const codeConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODEL_FOR_CREATE_UPDATE_OR_VIEW_REQUEST:
      state = {
        ...state,
        actionType: action.payload.actionType,
        actionData: action.payload.actionData,
      }
      break
    case types.GET_ALL_CODECONFIG_VALUES_RESPONSE:
      state = {
        ...state,
        codeConfigValues: action.payload,
      }
      break
    case types.GET_ALL_CODE_VALUES_DATA_RESPONSE:
      state = {
        ...state,
        codeValues: action.payload,
      }
      break
    case types.CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_RESPONSE:
      if (action?.payload?.status)
        state = {
          ...state,
          actionType: "viewCodeValues",
          actionData: undefined,
        }
      break
    case types.RESET_ACTION_TYPE:
      state = {
        ...state,
        actionType: "select",
      }
    default:
      state = { ...state }
  }
  return state
}
export default codeConfigurationReducer
