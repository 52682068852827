import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put, select } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post, Delete } from "../../helpers/api_helper"
import { fileUploader } from "../helpers"
import { ACTION_TYPES, templateStatus } from "../../constants"
import { getAllPlaceHoldersFromText } from "../../helpers/internalControl"

function* getAllTemplatatesList() {
  let userCreatedTemplatesList = []
  let preDefinedTemplateList = []
  let alertMessagesData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    let req = {
      cin: cin,
      system_tag: "User",
      templateId: 0,
      template_category: ["Utility", "Marketing", "Authentication"],
      template_language: "",
      template_name: "",
      status: "",
    }
    const response = yield call(Post, `${urls.GET_All_WHATS_APP_TEMPLATES}`, req)
    console.log("responase=>", response)
    if (response && response.status) {
      // userCreatedTemplatesList = response.data.templates.filter(x => x.systemTag === 'User');
      userCreatedTemplatesList = response.data.templates.map(x => ({
        ...x,
        templateMessage:
          typeof x.templateMessage === "string"
            ? JSON.parse(x.templateMessage)
            : x.templateMessage,
      }))
      preDefinedTemplateList = response.data.templates.filter(
        x => x.systemTag === "System"
      )
    } else {
      alertMessagesData = {
        status: response.status ?? false,
        message: response?.data?.message || "Some thing went worng",
      }
    }
  } catch (err) {
    alertMessagesData = {
      status: false,
      message: err?.data?.message || "Failed to fetch email templates",
    }
  }
  return { userCreatedTemplatesList, preDefinedTemplateList, alertMessagesData }
}
function* getAllWAPPredefinedAndUserCreatedTemplatesWatcher() {
  yield takeLatest(
    types.GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_REQUEST,
    getAllWAPPredefinedAndUserCreatedTemplatesRequest
  )
}
function* getAllWAPPredefinedAndUserCreatedTemplatesRequest(action) {
  let userCreatedTemplatesData = []
  let preDefinedTemplateData = []
  let alertMessageData
  try {
    let { userCreatedTemplatesList, preDefinedTemplateList, alertMessagesData } =
      yield call(getAllTemplatatesList)
    userCreatedTemplatesData = userCreatedTemplatesList
    preDefinedTemplateData = preDefinedTemplateList
    alertMessageData = alertMessagesData
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed to fetch whatApp templates",
    }
  }
  console.log(
    "responseData=>",
    userCreatedTemplatesData,
    preDefinedTemplateData,
    alertMessageData
  )
  yield put(
    actions.getAllPreDefinedOrUserCreatedTemplatesResponse(
      userCreatedTemplatesData,
      preDefinedTemplateData,
      alertMessageData
    )
  )
}

function* deleteWAPTemplatesRequest(action) {
  let deletedStatus
  let deleteAlertMessageData
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  console.log("deleteWAPTemplateRequest", action?.templateName)
  try {
    let response = yield call(
      Delete,
      `${urls.DELETE_WHATS_APP_TEMPELATE}?cin=${cin}&name=${action?.templateName}`
    )
    console.log("responsedDDD=>", response)
    if (response && response?.status) {
      deletedStatus = response?.data?.status
    } else {
      deleteAlertMessageData = {
        status: false,
        message: response?.message || "Unable to delete whatApp templates",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    console.log("deleteAlertMessageData", err)
    deleteAlertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to delete whatApp templates",
    }
  }
  console.log("deleteAlertMessageData", deleteAlertMessageData)
  return { deletedStatus, deleteAlertMessageData }
  // yield put(actions.deleteWAPTemplateResponse(userCreatedTemplatesData, preDefinedTemplateData, alertMessageData))
}
function* createOrUpdateWAPTemplateWatcher() {
  yield takeLatest(
    types.CREATE_OR_UPDATE_WPA_TEMPLATE_REQUEST,
    createOrUpdateWAPRequest
  )
}
function getButtonFun(values) {
  console.log("getButtonFun", values)
  if (values.callToActions.length > 0) {
    return values.callToActions.map(x => ({
      type: x.typeOfActions.value == 1 ? "PHONE_NUMBER" : "URL",
      text: x.buttonText,
      [x.typeOfActions.value == 1 ? "phone_number" : "url"]:
        x.typeOfActions.value == 1
          ? `${x?.country?.codeValueCd} ${x.phoneNumber}`
          : x.phoneNumber,
    }))
  } else if (
    values.quickReply.length > 0 &&
    values?.templateType?.label !== "Marketing"
  ) {
    return values.quickReply.map(x => ({
      type: "QUICK_REPLY",
      text: x.quickReplyButton,
    }))
  } else if (values.quickReplyForMarketing.length > 0) {
    return values.quickReplyForMarketing.map(x => ({
      type: x.type.value == 2 ? "Marketing-opt-out" : "QUICK_REPLY",
      text: x.buttonText,
    }))
  } else {
    return []
  }
}
function getCountryCodeOrUrlType(countryCodeOrUrlType, valueType) {
  switch (valueType) {
    case "urlType":
      return (
        countryCodeOrUrlType?.find(x => x.typeOfActions?.value === 2)?.country
          ?.label || ""
      )
    case "countryCode":
      return (
        countryCodeOrUrlType?.find(x => x.typeOfActions?.value === 1)?.country
          ?.codeValueCd || ""
      )
    default:
      return ""
  }
}
function* createOrUpdateWAPRequest(action) {
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  let userCreatedTemplatesData = []
  let preDefinedTemplateData = []
  let alertMessageData
  let responseStatus = false
  let { requestObj, objectState } = action.payload
  console.log("requestObj=>", cin, requestObj)
  let attachmentsData = (yield select())["WhatsAppTemplateReducer"]?.fileDataResponse
  let placeHoldersData = (yield select())["WhatsAppTemplateReducer"]
    ?.placeHoldersData
  let urlData =
    attachmentsData?.[0]?.fileLink ||
    requestObj?.imageUrl ||
    requestObj?.vedioUrl ||
    requestObj?.docUrl ||
    ""
  let req =
    objectState === ACTION_TYPES.DELETE
      ? { ...requestObj, objectState: objectState }
      : {
          attachmentName:
            urlData !== ""
              ? attachmentsData?.[0]?.fileName || requestObj?.fileName || ""
              : "",
          attachmentSize: urlData !== "" ? requestObj?.attachmentSize : "",
          attachmentUrl:
            attachmentsData?.[0]?.fileLink ||
            requestObj?.imageUrl ||
            requestObj?.vedioUrl ||
            requestObj?.docUrl ||
            "",
          cin: cin,
          objectState: objectState,
          buttons:
            (requestObj.buttonType.value !== 0
              ? getButtonFun(requestObj)
                  ?.map(x => x?.text)
                  ?.join(",")
              : "") || "",
          rejected: requestObj?.rejected,
          status: requestObj?.status || templateStatus.NOT_STARTED,
          templateCategory: requestObj.templateType.value,
          templateId: requestObj?.templateId || "",
          templateLanguage: requestObj.language.value,
          templateType: requestObj.headerType.label,
          countryCode:
            requestObj?.callToActions?.length > 0
              ? getCountryCodeOrUrlType(requestObj?.callToActions, "countryCode")
              : "",
          urlType:
            requestObj?.callToActions?.length > 0
              ? getCountryCodeOrUrlType(requestObj?.callToActions, "urlType")
              : "",
          templateMessage: {
            category: requestObj.templateType.value,
            components: [
              requestObj.headerType.value !== 0
                ? getAllPlaceHoldersFromText(requestObj.headerText)?.length > 0 &&
                  placeHoldersData?.headerText
                  ? {
                      type: "HEADER",
                      format: requestObj.headerType.label.toUpperCase(),
                      text: requestObj.headerText,
                      example: {
                        header_text: [placeHoldersData?.headerText] || [],
                      },
                    }
                  : {
                      type: "HEADER",
                      format:
                        requestObj.headerType.label.toLowerCase() === "media"
                          ? (requestObj?.mediaType).toUpperCase()
                          : requestObj.headerType.label.toUpperCase(),
                      text: requestObj.headerText,
                    }
                : "",
              placeHoldersData?.bodyTextArray?.length > 0 &&
              getAllPlaceHoldersFromText(requestObj.bodyText)?.length > 0
                ? {
                    type: "BODY",
                    text: requestObj.bodyText,
                    example: {
                      body_text: placeHoldersData?.bodyTextArray || [],
                    },
                  }
                : {
                    type: "BODY",
                    text: requestObj.bodyText,
                  },
              requestObj.footerText
                ? {
                    type: "FOOTER",
                    text: requestObj.footerText,
                  }
                : "",
              requestObj.buttonType.value !== 0
                ? {
                    type: "BUTTONS",
                    buttons: getButtonFun(requestObj),
                  }
                : "",
            ].filter(x => x !== ""),
          },
          templateName: requestObj.templateName,
          updatedFields: {
            status: "",
            template_attachment: "",
            whatsapp_template_id: "",
          },
          whatsappTemplateId: requestObj?.whatsappTemplateId || "",
        }
  console.log("requestObj=>", attachmentsData, requestObj, req)
  try {
    userCreatedTemplatesData = (yield select())["WhatsAppTemplateReducer"]
      ?.userCreatedTemplatesData
    preDefinedTemplateData = (yield select())["WhatsAppTemplateReducer"]
      ?.preDefinedTemplateData
    let isWAPDelteStatus
    let deletedStatus, deleteAlertMessageData
    if (
      requestObj?.status !== templateStatus.NOT_STARTED &&
      objectState === ACTION_TYPES.DELETE
    ) {
      let tagedTemplateResponse
      if (requestObj?.status === templateStatus.APPROVED) {
        tagedTemplateResponse = yield call(
          isCheckForTemplateTagedForCampaginOrNotRequest,
          requestObj
        )
      }
      if (!tagedTemplateResponse?.tagedTemplateAlertMessageData) {
        if (tagedTemplateResponse?.isCheckForTempTagedForCampaginOrNot?.status) {
          deleteAlertMessageData = {
            status: false,
            message:
              tagedTemplateResponse?.message ||
              "Cannot delete this message template.This Message template has got a Campaign.To delete this message template please delete the campaign first",
            isNewAlertModalType: true,
          }
        } else {
          let responseData = yield call(deleteWAPTemplatesRequest, requestObj)
          deletedStatus = responseData?.deletedStatus
          isWAPDelteStatus = responseData?.deletedStatus
          deleteAlertMessageData = responseData?.deleteAlertMessageData
        }
      } else {
        deleteAlertMessageData = tagedTemplateResponse?.tagedTemplateAlertMessageData
      }
    } else {
      isWAPDelteStatus = true
    }
    if (isWAPDelteStatus && !deleteAlertMessageData) {
      let response = yield call(Post, urls.CREATE_OR_EDIT_WAP_TEMPLATE, req)
      if (response && response.status) {
        responseStatus = response.status
        let { userCreatedTemplatesList, preDefinedTemplateList, alertMessagesData } =
          yield call(getAllTemplatatesList)
        if (!alertMessagesData) {
          userCreatedTemplatesData = userCreatedTemplatesList
          preDefinedTemplateData = preDefinedTemplateList
          alertMessageData = {
            status: true,
            message:
              response?.data?.message || "Sucessfully to delete whatApp templates",
            isNewAlertModalType: true,
          }
        } else {
          alertMessageData = alertMessagesData
        }
      }
    } else {
      alertMessageData = deleteAlertMessageData
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to create whatApp templates",
    }
  }
  console.log(
    "response=>",
    responseStatus,
    userCreatedTemplatesData,
    preDefinedTemplateData,
    alertMessageData
  )
  yield put(actions.uploadingFileResponse(undefined, undefined))
  yield put(
    actions.createOrUpdateWPATemplateResponse(
      responseStatus,
      userCreatedTemplatesData,
      preDefinedTemplateData,
      alertMessageData
    )
  )
}
function* isCheckForTemplateTagedForCampaginOrNotRequest(requestData) {
  let isCheckForTempTagedForCampaginOrNot, tagedTemplateAlertMessageData
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  try {
    const response = yield call(
      Post,
      `${urls.IS_CHECK_FOR_TEMPLATE_IS_TAGED_OR_NOT}?cin=${cin}&templateName=${requestData?.templateName}`
    )
    console.log("isCheckForTemplateTagedForCampaginOrNotRequest=>", response)
    if (response?.status) {
      isCheckForTempTagedForCampaginOrNot = response.data
    } else {
      tagedTemplateAlertMessageData = {
        status: false,
        message: response?.data?.message || "Something Went Wrong",
      }
    }
  } catch (err) {
    tagedTemplateAlertMessageData = {
      status: false,
      message: err?.data?.message || "Failed to fetch templates Data",
    }
  }
  return { isCheckForTempTagedForCampaginOrNot, tagedTemplateAlertMessageData }
}
function* upadatingWAPTemplateStatusToWhatsAppWatcher() {
  yield takeLatest(
    types.UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_REQUEST,
    upadatingWAPTemplateStatusToWhatsApp
  )
}
function* uploadMediaTemplateRequest(action) {
  console.log("uploadMediaTemplateRequest", action)
  let templateResponseData, alertMessageData
  try {
    const response = yield call(
      Post,
      `${urls.UPLOAD_WHATS_APP_MEDIA}?cin=${action.cin}&fileSize=${action.attachmentSize}&url=${action.attachmentUrl}`
    )
    console.log("responseDataa=>", response)
    if (response?.data?.status || response?.status === 200) {
      templateResponseData = response?.data
    } else {
      alertMessageData = {
        status: false,
        message:
          response?.data?.message || "Unable to update whatApp templates status",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to update whatApp templates status",
    }
  }
  return { templateResponseData, alertMessageData }
}
function* upadatingWAPTemplateStatusToWhatsApp(action) {
  let alertMessageData
  let updatedStatus
  let userCreatedTemplatesData
  let preDefinedTemplateData
  console.log("upadatingWAPTemplateStatusToWhatsApp", action)
  try {
    userCreatedTemplatesData = (yield select())["WhatsAppTemplateReducer"]
      ?.userCreatedTemplatesData
    preDefinedTemplateData = (yield select())["WhatsAppTemplateReducer"]
      ?.preDefinedTemplateData
    let responseOfMediaTemplate
    if (action.payload.templateType === "Media") {
      responseOfMediaTemplate = yield call(
        uploadMediaTemplateRequest,
        action.payload
      )
    }
    console.log("responseOfMediaTemplate", responseOfMediaTemplate)
    if (
      (responseOfMediaTemplate?.templateResponseData &&
        responseOfMediaTemplate?.templateResponseData?.status) ||
      true
    ) {
      const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
      let requestData = {
        access_token: "",
        category: action.payload?.templateMessage?.category,
        components: action.payload?.templateMessage?.components.map(x => {
          if (x.type === "BODY" && x.example) {
            return {
              ...x,
              example: { body_text: [x?.example.body_text] },
            }
          } else if (
            x.type === "BUTTONS" &&
            x.buttons.find(x => x.type === "Marketing-opt-out")
          ) {
            return {
              ...x,
              buttons: x.buttons.map(y => ({ type: "QUICK_REPLY", text: y.text })),
            }
          } else if (
            x.type === "HEADER" &&
            (x.format === "IMAGE" || x.format === "VEDIO" || x.format === "DOCUMENT")
          ) {
            return {
              type: x.type,
              format: x.format === "VEDIO" ? "VIDEO" : x.format,
              text: null,
              buttons: null,
              example: {
                header_handle: [
                  responseOfMediaTemplate?.templateResponseData?.imageId,
                ],
              },
            }
          } else {
            return x
          }
        }),
        language: action.payload?.templateLanguage,
      }
      const response = yield call(
        Post,
        `${urls.UPLOAD_WHATS_APP_TEMPLATE_STATUS}?cin=${cin}&templateId=${action.payload.templateId}&templateName=${action.payload.templateName}`,
        requestData
      )
      console.log("responase=>", response)
      if (response && response.status) {
        updatedStatus = response
        let { userCreatedTemplatesList, preDefinedTemplateList, alertMessagesData } =
          yield call(getAllTemplatatesList)
        if (!alertMessagesData) {
          userCreatedTemplatesData = userCreatedTemplatesList
          preDefinedTemplateData = preDefinedTemplateList
          alertMessageData = {
            status: true,
            message:
              "The message template was submitted to Facebook Successfully.The Approval time for this template takes 30 mins to 2 business days  Subjected to Facebook team approval.",
            isWhatsAppTempate: true,
          }
        } else {
          alertMessageData = alertMessagesData
        }
      } else {
        alertMessageData = {
          status: false,
          message: response?.data?.message || "Some thing went worng",
        }
      }
    } else {
      alertMessageData = responseOfMediaTemplate?.alertMessageData
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to update whatApp templates status",
    }
  }

  yield put(
    actions.uploadingWAPTemplateResponse(
      updatedStatus,
      userCreatedTemplatesData,
      preDefinedTemplateData,
      alertMessageData
    )
  )
}
function* uploadFileDataWatcher() {
  yield takeLatest(types.UPLOADING_FILE_DATA_REQUEST, uploadFileDataRequest)
}
function* uploadFileDataRequest(action) {
  let fileDataResponse, alertMessageData
  console.log("filesResponse=>", action)
  let { requestData, setFieldValue, selectedMediaType } = action.payload
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const { filesResponse, fileResponseAlertMessageData } = yield call(
      fileUploader,
      requestData,
      `${urls.EMAIL_TEMPLATE_ATTACHMENTS_UPLOAD}?cin=${cin}`
    )
    console.log("filesResponse=>", filesResponse, fileResponseAlertMessageData)
    fileDataResponse = filesResponse
    if (fileDataResponse?.[0]?.status) {
      setFieldValue(selectedMediaType, URL.createObjectURL(requestData[0]))
      alertMessageData = {
        status: fileDataResponse?.[0]?.status ?? false,
        message: fileDataResponse?.[0]?.message || "Some thing went worng",
      }
    } else alertMessageData = fileResponseAlertMessageData
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Unable to upload the File",
    }
  }
  yield put(actions.uploadingFileResponse(fileDataResponse, alertMessageData))
}

function* getAllPreDefinedTemplateWatcher() {
  yield takeLatest(types.GET_DEFAULT_WAP_TEPMLATES_REQUEST, getAllPreDefinedTemplate)
}
function* getAllPreDefinedTemplate() {
  let preDefinedTemplateList = []
  let alertMessagesData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    let req = {
      cin: cin,
      system_tag: "System",
      templateId: 0,
      template_category: ["Utility", "Marketing", "OTP"],
      template_language: "",
      template_name: "",
      status: "",
    }
    const response = yield call(Post, `${urls.GET_All_WHATS_APP_TEMPLATES}`, req)
    console.log("responase=>", response)
    if (response && response.status) {
      preDefinedTemplateList = response.data.templates
    } else {
      alertMessagesData = {
        status: response.status ?? false,
        message: response?.data?.message || "Some thing went worng",
      }
    }
  } catch (err) {
    alertMessagesData = {
      status: false,
      message: err?.data?.message || "Failed to fetch Predefined templates",
    }
  }
  yield put(
    actions.getPreDefinedWAPTemplatesResponse(
      preDefinedTemplateList,
      alertMessagesData
    )
  )
}
function* getTempalateNameExistOrNotWatcher() {
  yield takeLatest(
    types.GET_TEMPLATE_NAME_EXSITS_OR_NOT_REQUEST,
    getTempalateNameExistOrNot
  )
}
function* getTempalateNameExistOrNot(action) {
  let templateNameExistOrNot = []
  let alertMessageData
  console.log("responase=>", action.payload)
  let { requestData, setFieldValue } = action.payload
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    let req = {
      cin: cin,
      system_tag: "User",
      templateId: 0,
      template_category: ["Utility", "Marketing", "OTP"],
      template_language: requestData?.language?.value,
      template_name: requestData?.templateName,
      status: "",
    }
    const response = yield call(Post, `${urls.GET_All_WHATS_APP_TEMPLATES}`, req)
    console.log("responase=>", response)
    if (response && response?.data?.status) {
      templateNameExistOrNot = response?.data?.templates
      if (response?.data?.templates?.length > 0) {
        setFieldValue("language", "")
      } else {
        setFieldValue("language", requestData?.language)
      }
    } else {
      templateNameExistOrNot = response?.data?.templates
      // alertMessagesData = { status: response.status ?? false, message: response?.data?.message || 'Some thing went worng' };
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message || "Failed to fetch templates Name",
    }
  }
  console.log("errorData=>", templateNameExistOrNot)
  yield put(
    actions.getTemplateNameExistOrNotResponse(
      templateNameExistOrNot,
      alertMessageData
    )
  )
}

function* whatsAppTemplatesSaga() {
  yield all([
    fork(getAllWAPPredefinedAndUserCreatedTemplatesWatcher),
    fork(createOrUpdateWAPTemplateWatcher),
    fork(upadatingWAPTemplateStatusToWhatsAppWatcher),
    fork(uploadFileDataWatcher),
    fork(getAllPreDefinedTemplateWatcher),
    fork(getTempalateNameExistOrNotWatcher),
  ])
}
export default whatsAppTemplatesSaga
