export const GET_ALL_CODECONFIG_VALUES_REQUEST = "GET_ALL_CODECONFIG_VALUES_REQUEST"
export const GET_ALL_CODECONFIG_VALUES_RESPONSE =
  "GET_ALL_CODECONFIG_VALUES_RESPONSE"
export const GET_ALL_CODE_VALUES_DATA_REQUEST = "GET_ALL_CODE_VALUES_DATA_REQUEST"
export const GET_ALL_CODE_VALUES_DATA_RESPONSE = "GET_ALL_CODE_VALUES_DATA_RESPONSE"
export const CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_REQUEST =
  "CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_REQUEST"
export const CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_RESPONSE =
  "CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_RESPONSE"
export const SHOW_MODEL_FOR_CREATE_UPDATE_OR_VIEW_REQUEST =
  "SHOW_MODEL_FOR_CREATE_UPDATE_OR_VIEW_REQUEST"
export const RESET_ACTION_TYPE = "RESET_ACTION_TYPE"
