export const GET_MESSAGE_RULES_REQUEST = "GET_MESSAGE_RULES_REQUEST";
export const GET_MESSAGE_RULES_RESPONSE = "GET_MESSAGE_RULES_RESPONSE";
export const CREATE_MESSAGE_RULE_REQUEST = "CREATE_MESSAGE_RULE_REQUEST";
export const CREATE_MESSAGE_RULE_RESPONSE = "CREATE_MESSAGE_RULE_RESPONSE";
export const DELETE_MESSAGE_RULE_REQUEST = "DELETE_MESSAGE_RULE_REQUEST";
export const DELETE_MESSAGE_RULE_RESPONSE = "DELETE_MESSAGE_RULE_RESPONSE";
export const CANCEL_ALL_PENDING_REQUEST_MESSAGE_RULES = "CANCEL_ALL_PENDING_REQUEST_MESSAGE_RULES";
export const SET_MESSAGERULES_MODEL_OPEN = "SET_MESSAGERULES_MODEL_OPEN";
export const VALIDATE_KEYWORD_REQUEST = "VALIDATE_KEYWORD_REQUEST";
export const VALIDATE_KEYWORD_RESPONSE = "VALIDATE_KEYWORD_RESPONSE";
export const UPDATE_KEYWORDS_IN_RULES_AFTER_DELETE = "UPDATE_KEYWORDS_IN_RULES_AFTER_DELETE";
export const GET_ONE_TIME_MESSAGE_REPLIES_REQUEST = "GET_ONE_TIME_MESSAGE_REPLIES_REQUEST";
export const GET_ONE_TIME_MESSAGE_REPLIES_RESPONSE = "GET_ONE_TIME_MESSAGE_REPLIES_RESPONSE";
export const SELECTED_QUICK_REPLIES = "SELECTED_QUICK_REPLIES";
export const SELECTED_FLOW_REPLIES = "SELECTED_FLOW_REPLIES";
export const GET_FLOW_MESSAGE_REPLIES_REQUEST = "GET_FLOW_MESSAGE_REPLIES_REQUEST";
export const GET_FLOW_MESSAGE_REPLIES_RESPONSE = "GET_FLOW_MESSAGE_REPLIES_RESPONSE";
export const SET_KEY_WORDS_MODEL_OPEN = "SET_KEY_WORDS_MODEL_OPEN"