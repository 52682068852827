import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLatest, all, fork, put, select } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post } from "../../helpers/api_helper"
import { OBJECT_STATE } from "../../constants"
import { fileUploader } from "../helpers"

const getAttachmentsStr = files =>
  files?.map(file => `${file.name || file.fileName}::${file.fileLink}`).join()

// Reusable function to get email templates
function* getExistingEmailTemplates() {
  let emailTemplates = [],
    alertMessageData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const response = yield call(Get, `${urls.GET_EMAIL_TEMPLATES}?cin=${cin}`)
    if (response.status && response.templates) {
      emailTemplates = response.templates
    } else {
      alertMessageData = {
        status: response?.status ?? false,
        message: response?.message || "Unable to fetch email templates",
      }
    }
  } catch (err) {
    alertMessageData = { status: false, message: "Failed to fetch email templates" }
  }
  return { emailTemplates, alertMessageData }
}

// Reusable CRUD object generator for Email Templates
function* getCrudObj(objectState) {
  let url, cin, successMsg, failMsg

  try {
    cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    url = `${urls.EMAIL_TEMPLATE_CRUD}?cin=${cin}&objectState=${objectState}`

    switch (objectState) {
      case OBJECT_STATE.NEW:
        successMsg = "Email Template created successfully"
        failMsg = "Failed to create email template"
        break
      case OBJECT_STATE.UPDATE:
      case OBJECT_STATE.DELETE:
        successMsg = `Email Template ${objectState?.toLowerCase()}d successfully`
        failMsg = `Failed to ${objectState?.toLowerCase()} email template`
        break
    }
  } catch (err) {
    console.error("getCrudObj", err)
  }

  return { url, cin, successMsg, failMsg }
}

// Get Existing Email Templates
function* getEmailTemplatesWatcher() {
  yield takeLatest(types.GET_EMAIL_TEMPLATES_REQUEST, getEmailTemplates)
}
function* getEmailTemplates() {
  let emailTemplates, alertMessageData
  try {
    const templatesResponse = yield call(getExistingEmailTemplates)
    emailTemplates = templatesResponse.emailTemplates
    alertMessageData = templatesResponse.alertMessageData
  } catch (err) {
    alertMessageData = { status: false, message: "Failed to fetch email templates" }
  }
  yield put(actions.getEmailTemplatesResponse(emailTemplates, alertMessageData))
}

// Email Template CRUD
function* emailTemplatesCrudWatcher() {
  yield takeLatest(types.EMAIL_TEMPLATE_CRUD_REQUEST, emailTemplatesCrud)
}
function* emailTemplatesCrud(action) {
  let updatedEmailTemplates, alertMessageData

  const { emailTemplate, objectState } = action.payload ?? {}
  const { url, cin, successMsg, failMsg } = yield call(getCrudObj, objectState)

  try {
    const requestObj = { ...emailTemplate, objectState, cin }

    if (emailTemplate.attachments?.length) {
      const isUpdatePhase = objectState === OBJECT_STATE.UPDATE
      let attachmentsToUpload = isUpdatePhase ? [] : emailTemplate.attachments
      let existingAttachments = []

      // only for update phase
      if (isUpdatePhase) {
        emailTemplate.attachments.forEach(attachment => {
          if (attachment.fileLink) existingAttachments.push(attachment)
          else attachmentsToUpload.push(attachment)
        })
      }

      if ((isUpdatePhase && attachmentsToUpload.length > 0) || !isUpdatePhase) {
        const { filesResponse, fileResponseAlertMessageData } = yield call(
          fileUploader,
          attachmentsToUpload,
          `${urls.EMAIL_TEMPLATE_ATTACHMENTS_UPLOAD}?cin=${cin}`
        )

        if (filesResponse?.length && !fileResponseAlertMessageData) {
          const newlyUploadedFilesStr = filesResponse
            .filter(res => res.status && res.fileLink && res.fileName)
            ?.map(file => `${file.fileName}::${file.fileLink}`)
            .join()
          requestObj.attachments =
            isUpdatePhase && existingAttachments.length > 0
              ? `${getAttachmentsStr(existingAttachments)},${newlyUploadedFilesStr}`
              : newlyUploadedFilesStr
        } else {
          if (fileResponseAlertMessageData)
            alertMessageData = fileResponseAlertMessageData
          else alertMessageData = { status: false, message: failMsg }
        }
      }

      if (isUpdatePhase && attachmentsToUpload.length === 0) {
        requestObj.attachments = getAttachmentsStr(existingAttachments)
      }
    }

    if (!alertMessageData) {
      const response = yield call(Post, url, requestObj)
      if (response.status) {
        const templatesResponse = yield call(getExistingEmailTemplates)

        if (!templatesResponse.alertMessageData) {
          updatedEmailTemplates = templatesResponse.emailTemplates
          alertMessageData = {
            status: response?.status ?? true,
            message: response?.message || successMsg,
            isNewAlertModalType: true,
          }
        } else {
          alertMessageData = {
            status: response?.status ?? false,
            message: response?.message || "Some thing went wrong",
            isNewAlertModalType: true,
          }
        }
      } else {
        alertMessageData = {
          status: false,
          message: response?.message ?? "Some thing went wrong",
          isNewAlertModalType: true,
        }
      }
    }
  } catch (err) {
    console.error("_emailTemplatesCrud_catch", err)
    alertMessageData = {
      status: err?.data?.status ?? false,
      message: err?.data?.message ?? failMsg,
      isNewAlertModalType: true,
    }
  }

  yield put(
    actions.emailTemplateCrudResponse(updatedEmailTemplates, alertMessageData)
  )
}

function* emailTemplatesSaga() {
  yield all([fork(getEmailTemplatesWatcher), fork(emailTemplatesCrudWatcher)])
}
export default emailTemplatesSaga
