export const OPEN_MODEL_FOR_CREATE_OE_UPDATE_OR_DELETE =
  "OPEN_MODEL_FOR_CREATE_OE_UPDATE_OR_DELETE"

export const GET_WAP_BUSINESS_ACCOUNT_PHONE_NUMBERS_REQUEST =
  "GET_WAP_BUSINESS_ACCOUNT_PHONE_NUMBERS_REQUEST"
export const GET_WAP_BUSINESS_ACCOUNT_PHONE_NUMBERS_RESPONSE =
  "GET_WAP_BUSINESS_ACCOUNT_PHONE_NUMBERS_RESPONSE"

export const GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_REQUEST =
  "GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_REQUEST"
export const GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_RESPONSE =
  "GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_RESPONSE"

export const CREATE_OR_UPDATE_QR_CODE_TEMPLATE_REQUEST =
  "CREATE_OR_UPDATE_QR_CODE_TEMPLATE_REQUEST"
export const CREATE_OR_UPDATE_QR_CODE_TEMPLATE_RESPONSE =
  "CREATE_OR_UPDATE_QR_CODE_TEMPLATE_RESPONSE"

export const DELETE_QR_CODE_TEMPLATE_REQUEST = "DELETE_QR_CODE_TEMPLATE_REQUEST"
export const DELETE_QR_CODE_TEMPLATE_RESPONSE = "DELETE_QR_CODE_TEMPLATE_RESPONSE"
