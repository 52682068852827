import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING } from "../common/actionTypes"

export const setSideBarActionRequest = () => ({
  type: types.SET_SIDEBAR_ACTION_REQUEST,
  payload: false,
})

export const setCardActionTypeRequest = x => {
  return {
    type: types.SET_CARD_ACTION_TYPE_REQUEST,
    payload: x,
  }
}

export const openNodeRequest = data => {
  return {
    type: types.OPEN_NODE_REQUEST,
    payload: { data },
  }
}

export const storeNodeRequest = values => {
  return {
    type: types.STORE_NODE_REQUEST,
    payload: { values },
  }
}

export const createKeyWordRequest = keyword => {
  return {
    type: types.CREATE_KEYWORD_REQUEST,
    payload: keyword,
  }
}

// text
export const createQuestionTypeTextNodeRequest = postReqObjText => {
  return {
    type: types.CREATE_QUESTION_TYPE_TEXT_NODE_REQUEST,
    payload: postReqObjText,
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const createQuestionTypeTextNodeResponse = alertMessageData => {
  return {
    type: types.CREATE_QUESTION_TYPE_TEXT_NODE_REPONSE,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}
// list
export const createQuestionTypeListNodeRequest = postReqObjList => {
  return {
    type: types.CREATE_QUESTION_TYPE_LIST_NODE_REQUEST,
    payload: postReqObjList,
  }
}
export const createQuestionTypeListNodeResponse = postReqObjForList => {
  return {
    type: types.CREATE_QUESTION_TYPE_LIST_NODE_RESPONSE,
    payload: postReqObjForList,
  }
}

// email
export const createEmailNodeRequest = postReqObjEmail => {
  return {
    type: types.CREATE_EMAIL_NODE_REQUEST,
    payload: postReqObjEmail,
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}

export const createEmailNodeResponse = alertMessageData => {
  return {
    type: types.CREATE_EMAIL_NODE_RESPONSE,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

// phone number

export const createPhoneNumberNodeRequest = postReqObjPhone => {
  return {
    type: types.CREATE_PHONE_NUMBER_NODE_REQUEST,
    payload: postReqObjPhone,
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}

export const createPhoneNumberNodeResponse = alertMessageData => {
  return {
    type: types.CREATE_PHONE_NUMBER_NODE_RESPONSE,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

// number

export const createNumberNodeRequest = postReqObjNumber => {
  return {
    type: types.CREATE_NUMBER_NODE_REQUEST,
    payload: postReqObjNumber,
  }
}

export const createNumberNodeResponse = numberResponseObject => {
  return {
    type: types.CREATE_NUMBER_NODE_RESPONSE,
    payload: numberResponseObject,
  }
}

// condition

export const createConditionNodeRequest = postReqObjCondition => {
  return {
    type: types.CREATE_CONDITION_NODE_REQUEST,
    payload: postReqObjCondition,
  }
}

export const createConditionNodeResponse = conditionResponseObject => {
  return {
    type: types.CREATE_CONDITION_NODE_RESPONSE,
    payload: conditionResponseObject,
  }
}

// username

export const createUserNameNodeRequest = postReqObjCondition => {
  return {
    type: types.CREATE_USERNAME_NODE_REQUEST,
    payload: postReqObjCondition,
  }
}

export const createUserNameNodeResponse = userNameResponseObject => {
  return {
    type: types.CREATE_USERNAME_NODE_RESPONSE,
    payload: userNameResponseObject,
  }
}

// key pad options

export const createKeyPadOptionsNodeRequest = postReqObjKeyPadOptions => {
  return {
    type: types.CREATE_KEY_PAD_OPTIONS_NODE_REQUEST,
    payload: postReqObjKeyPadOptions,
  }
}

export const createKeyPadOptionsNodeResponse = keyPadResponseObject => {
  return {
    type: types.CREATE_QUESTION_TYPE_TEXT_NODE_REPONSE,
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

// location

export const createLocationNodeRequest = postReqObjLocation => {
  return {
    type: types.CREATE_LOCATION_NODE_REQUEST,
    payload: postReqObjLocation,
  }
}

export const createLocationNodeResponse = locationResponseObject => {
  return {
    type: types.CREATE_LOCATION_NODE_RESPONSE,
    payload: locationResponseObject,
  }
}

// assign to agent

export const getAssignToAgentNodeRequest = postReqObjAssignToAgent => {
  return {
    type: types.GET_ASSIGN_TO_AGENT_REQUEST,
    payload: postReqObjAssignToAgent,
  }
}

export const getAssignToAgentNodeResponse = agentsData => {
  return {
    type: types.GET_ASSIGN_TO_AGENT_RESPONSE,
    payload: agentsData,
  }
}

export const openFlowCardRequest = action => {
  return {
    type: types.OPEN_FLOW_CARD_REQUEST,
    payload: action,
  }
}

export const createTextMessageNodeRequest = postReqObjTextMessage => {
  return {
    type: types.CREATE_EMAIL_NODE_REQUEST,
    payload: postReqObjTextMessage,
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}

export const createTextMessageNodeResponse = () => {
  return {
    type: types.CREATE_USERNAME_NODE_RESPONSE,
    payload: textMessageResponseObject,
  }
}

export const createReplybuttonRequest=(postReqReplyButton)=>{
  return{
    type:types.CREATE_REPLY_BUTTON_REQUEST,
    payload:postReqReplyButton,
    loadType: ACTION_LOADING,
    loadPayload: true,
  }
}
export const createReplybuttonResponse=(nodeData)=>{
  return{
    type:types.CREATE_REPLY_BUTTON_RESPONSE,
    payload:nodeData,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  
  }
}


export const createAssignToAgentNodeRequest = reqObj => {
  return {
    type: types.CREATE_ASSIGN_TO_AGENT_NODE_REQUEST,
    payload: reqObj,
  }
}

export const createAssignToAgentNodeResponse = (responseOfAgentData) => {
  return {
    type: types.CREATE_ASSIGN_TO_AGENT_NODE_RESPONSE,
    payload: responseOfAgentData,
  }
}


export const addVaribleRequest=reqVariable=>{
  return{
    type:types.ADD_VARIABLE_REQUEST,
    payload:reqVariable
  }
}

export const addVaribleResponse=(storeResponseData)=>{
  return{
    type:types.ADD_VARIABLE_RESPONSE,
    payload:storeResponseData
  }
}
