import * as types from "./actionTypes"
import {
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTION_LOADING,
  LANDING_PAGE_LOADING,
} from "../common/actionTypes"

export const getAllContactsRequest = (
  requestData,
  downloadRequest = false,
  isLandingPage = false,
  isPagination = false
) => ({
  type: types.GET_ALL_CONTACTS_REQUEST,
  payload: { requestData, downloadRequest, isPagination },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: isLandingPage,
})
export const getAllContactsResponse = (responseData, totalPages) => ({
  type: types.GET_ALL_CONTACTS_RESPONSE,
  payload: { responseData, totalPages },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
})
export const createOrUpdateOrDeleteRequest = (
  requestData,
  isDefaultFlow = false
) => ({
  type: types.CREATE_OR_UPDATE_DELETE_CONTACT_REQUEST,
  payload: { requestData, isDefaultFlow },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createOrUpdateOrDeleteResponse = (responseData, alertMessageData) => ({
  type: types.CREATE_OR_UPDATE_DELETE_CONTACT_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const showModelForCreateOrUpdateOrDelete = (actionType, actionData) => ({
  type: types.SHOW_MODEL_FOR_CREATE_UPDATE_DELETE,
  payload: { actionType, actionData },
})

export const resetShowModelForCreateOrUpdateOrDelete = () => ({
  type: types.RESET_SHOW_MODEL_FOR_CREATE_UPDATE_DELETE,
})

export const getSearchedValueRequest = searchData => ({
  type: types.GET_SEARCH_VALUE_REQUEST,
  payload: searchData,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const getSearchedValueResponse = (responseData, alertMessageData) => ({
  type: types.GET_SEARCH_VALUE_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const getCodeValuesByEnquiryRequest = (
  requestData,
  downloadRequest = false,
  isLandingPage = false
) => ({
  type: types.GET_CODE_VALUE_FOR_ENQUIRES_REQUEST,
  payload: { requestData, downloadRequest },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: isLandingPage,
})
export const getCodeValuesByEnquiryResponse = responaseData => ({
  type: types.GET_CODE_VALUE_FOR_ENQUIRES_RESPONSE,
  payload: responaseData,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
})

export const deleteContactRequest = (
  requestData,
  setCheckedValue,
  setShowCheckBox
) => ({
  type: types.DELETE_CONTACT_LIST_DATE_REQUEST,
  payload: { requestData, setCheckedValue, setShowCheckBox },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const deleteContactResponse = (responseData, alertMessageData) => ({
  type: types.DELETE_CONTACT_LIST_DATE_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const selectUploadOrDownload = selectedType => ({
  type: types.SELECT_VALUES_FOR_UPLOAD_OR_DOWNLOAD,
  payload: selectedType,
})

export const createBulkUploadContactRequest = requestData => ({
  type: types.CREATE_BULK_UPLOAD_CONTACT_REQUEST,
  payload: requestData,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createBulkUploadContactResponse = (responseData, alertMessageData) => ({
  type: types.CREATE_BULK_UPLOAD_CONTACT_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const getPostCodeBasedDataRequest = (requestData, setFieldValue) => ({
  type: types.GET_POSTAL_CODED_BASED_DATA_REQUEST,
  payload: { requestData, setFieldValue },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const getPostCodeBasedDataResponse = (responseData, alertMessageData) => ({
  type: types.GET_POSTAL_CODED_BASED_DATA_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const openModelForShowingUploadingStatus = (isOpenOrClose, message) => ({
  type: types.OPEN_MODEL_FOR_SHOWING_UPLOAGING_STATUS,
  payload: { isOpenOrClose, message },
})
