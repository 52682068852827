import {
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  RESET_UPLOAD_RESPONSE,
} from "store/common/actionTypes"
import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes"
const intialState = {
  uploadProfile: undefined,
  profileDetails: undefined,
  actionData: undefined,
  actionType: undefined,
  whatsAppAcoountDetails: undefined,
  isEditOrCreate: ACTION_TYPES.SELECT,
  isEditData: undefined,
  phoneNumbersDetails: undefined,
  testWABAAccountResponse: undefined,
  uploadResponse: {},
  uploadError: {},
}
const profileReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.CANCEL_ALL_PENDING_REQUEST_FOR_PROFILE:
      state = {
        uploadProfile: undefined,
        profileDetails: undefined,
        actionData: undefined,
        actionType: undefined,
        whatsAppAcoountDetails: undefined,
        isEditOrCreate: ACTION_TYPES.SELECT,
        isEditData: undefined,
        phoneNumbersDetails: undefined,
        testWABAAccountResponse: undefined,
      }
      break
    case types.UPLOADING_PROFILE_FILE_DATA_RESPONSE:
      state = {
        ...state,
        uploadProfile: action.payload,
      }
      break
    case types.GET_PROFILE_DETAILS_RESPONSE:
      state = {
        ...state,
        profileDetails: action.payload,
      }
      break
    case types.UPDATE_PROFILE_DETAILS_RESPONSE:
      state = {
        ...state,
        profileDetails: action.payload.responseData,
        actionType: action.payload.actionType,
      }
      break
    case types.SHOW_MODEL_FOR_UPDATE:
      state = {
        ...state,
        actionData: action.payload.actionData,
        actionType: action.payload.actionType,
      }
      break
    case types.GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_RESPONSE:
      state = {
        ...state,
        whatsAppAcoountDetails: action.payload,
      }
      break
    case types.OPEN_MODEL_FOR_CREATE_OR_UPDATE_PHONE_NUMBER:
      state = {
        ...state,
        isEditOrCreate: action.payload.isEditOrCreate,
        isEditData: action.payload.isEditData,
      }
      break
    case types.GET_ALL_CREATED_PHONE_NUMBERS_RESPONSE:
      state = {
        ...state,
        phoneNumbersDetails: action.payload,
      }
      break
    case types.TEST_WABA_ACCOUNT_DETIALS_RESPONSE:
      state = {
        ...state,
        testWABAAccountResponse: action.payload,
      }
      break

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadResponse: action.payload,
      }
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadError: action.payload,
      }
    case RESET_UPLOAD_RESPONSE:
      return {
        ...state,
        uploadResponse: {},
      }
    default:
      state = { ...state }
  }
  return state
}
export default profileReducer
