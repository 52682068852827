import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING } from "../common/actionTypes"
import { ACTION_TYPES } from "../../constants"

// Set Action Data
export const setEmailTemplatesActionData = (
  actionType = ACTION_TYPES.SELECT,
  actionId = null
) => ({
  type: types.SET_EMAIL_TEMPLATES_ACTION_DATA,
  payload: { actionType, actionId },
})

// Get Email Templates
export const getEmailTemplatesRequest = () => ({
  type: types.GET_EMAIL_TEMPLATES_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const getEmailTemplatesResponse = (emailTemplates, alertMessageData) => ({
  type: types.GET_EMAIL_TEMPLATES_RESPONSE,
  payload: emailTemplates,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

// Email Templates CRUD
export const emailTemplateCrudRequest = (emailTemplate, objectState) => ({
  type: types.EMAIL_TEMPLATE_CRUD_REQUEST,
  payload: { emailTemplate, objectState },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const emailTemplateCrudResponse = (
  updatedEmailTemplates,
  alertMessageData
) => ({
  type: types.EMAIL_TEMPLATE_CRUD_RESPONSE,
  payload: updatedEmailTemplates,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const resetEmailTemplates = () => ({
  type: types.RESET_EMAIL_TEMPLATES,
})
