import * as types from "./actionTypes"

const INIT_STATE = {
  userData: undefined,
  userDto: undefined,
  token: undefined,
  info: undefined,
  sessionStateData: undefined,
  authToken: undefined,
  // wbaDetails: undefined,
  loginAlertMessage: false,
  isSubscriptionModalOpen: false,
  isLoginLoading: false,
}

const loginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case types.ACTION_LOGOUT:
    //     state = {
    //         ...state,
    //         userDto: undefined,
    //         token: undefined,
    //         userData: undefined,
    //         info: undefined
    //     };
    //     break;
    case types.GET_LOGIN_REQUEST:
      state = {
        ...state,
        info: action.payload,
      }
      break
    case types.GET_LOGIN_RESPONSE:
      state = {
        ...state,
        userDto: action.payload.userDto,
        token: action.payload.tokenInfo,
      }
      break
    case types.GET_VERIFICATON_RESPONSE:
      state = {
        ...state,
        userData: action.payload,
      }
      break
    case types.SET_SESSION_STATE_DATA:
      state = {
        ...state,
        sessionStateData: action.payload.sessionState,
        authToken: action.payload.token,
        wbaDetails: action.payload.wbaDetails,
      }
      break
    case types.IS_SESSION_MODEL_OPEN_OR_CLOSE:
      state = {
        ...state,
        isSubscriptionModalOpen: action.payload,
      }
      break
    case types.IS_LOGIN_LOADING:
      state = {
        ...state,
        isLoginLoading: action.payload,
      }
      break
    // case types.GET_LOGIN_ALERT_MESSAGE:
    //     state = {
    //         ...state,
    //         loginAlertMessage: action.payload
    //     }
    //     break;
    default:
      state
      break
  }
  return state
}
export default loginReducer
