
import * as types from "./actionTypes"

const initialState = {
  sideBarState: true,
  cardState: "",
  flowNodes: [],
  storeNodeData: {},
  newKeyWord: "",
  textResponseData: {},
  listResponseData: {},
  emailResponseData: {},
  phoneNumberResponseData: {},
  numberResponseData: {},
  conditionResponseData: {},
  userNameResponseData: {},
  keypadResponseData: {},
  locationResponseData: {},
  assignAgentResponseData: {},
  openFlowCardState: true,
  storeKeywordsData:{}
}

const rulesConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SIDEBAR_ACTION_RESPONSE:
      state = {
        ...state,
        sideBarState: action.payload,
      }
      break
    case types.SET_CARD_ACTION_TYPE_REQUEST:
      state = {
        ...state,
        cardState: action.payload,
      }
      break
    case types.OPEN_NODE_REQUEST:
      state = {
        ...state,
        flowNodes: action.payload?.data,
      }
      break
    case types.STORE_NODE_REQUEST:
      state = {
        ...state,
        storeNodeData: action.payload,
      }
      break
    case types.STORE_NODE_REQUEST:
      state = {
        ...state,
        storeNodeData: action.payload,
      }
      break

    case types.CREATE_QUESTION_TYPE_TEXT_NODE_REPONSE:
      state = {
        ...state,
        textResponseData: action.payload,
      }
      break

    case types.CREATE_QUESTION_TYPE_LIST_NODE_RESPONSE:
      state = {
        ...state,
        listResponseData: action.payload,
      }
      break

    case types.CREATE_EMAIL_NODE_RESPONSE:
      state = {
        ...state,
        emailResponseData: action.payload,
      }
      break

    case types.CREATE_PHONE_NUMBER_NODE_RESPONSE:
      state = {
        ...state,
        phoneNumberResponseData: action.payload,
      }
      break

    case types.CREATE_NUMBER_NODE_RESPONSE:
      state = {
        ...state,
        numberResponseData: action.payload,
      }
      break

    case types.CREATE_CONDITION_NODE_RESPONSE:
      state = {
        ...state,
        conditionResponseData: action.payload,
      }
      break

    case types.CREATE_USERNAME_NODE_RESPONSE:
      state = {
        ...state,
        userNameResponseData: action.payload,
      }
      break

    case types.CREATE_KEY_PAD_OPTIONS_NODE_RESPONSE:
      state = {
        ...state,
        keypadResponseData: action.payload,
      }
      break

    case types.CREATE_LOCATION_NODE_RESPONSE:
      state = {
        ...state,
        locationResponseData: action.payload,
      }
      break

    case types.GET_ASSIGN_TO_AGENT_RESPONSE:
      state = {
        ...state,
        assignAgentResponseData: action.payload,
      }
      break
    case types.OPEN_FLOW_CARD_REQUEST:
      state = {
        ...state,
        openFlowCardState: true,
      }
      case types.ADD_VARIABLE_RESPONSE:
        state={
          ...state,
          storeKeywordsData:action?.payload

        }

    default:
      state = { ...state }
      break
  }
  return state
}
export default rulesConfigurationReducer
