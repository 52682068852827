import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING, LANDING_PAGE_LOADING } from "../common/actionTypes"

export const showModelForOpenOrCreateOrUpadateRequest = (
  actionType,
  actionData
) => ({
  type: types.OPEN_MODEL_FOR_CREATE_OE_UPDATE_OR_DELETE,
  payload: { actionType, actionData },
})

export const getWAPBusinessAccountPhoneNumbersRequest = () => ({
  type: types.GET_WAP_BUSINESS_ACCOUNT_PHONE_NUMBERS_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getWAPBusinessAccountPhoneNumbersResponse = (
  phoneNumbersListData,
  alertMessageData
) => ({
  type: types.GET_WAP_BUSINESS_ACCOUNT_PHONE_NUMBERS_RESPONSE,
  payload: phoneNumbersListData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const getQrCodeTemplateRequest = () => ({
  type: types.GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})

export const getQrCodeTemplateResponse = (qrCodeList, alertMessageData) => ({
  type: types.GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_RESPONSE,
  payload: qrCodeList,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const createOrUpdateQRCodeTemplateRequest = (requestObj, objectState) => ({
  type: types.CREATE_OR_UPDATE_QR_CODE_TEMPLATE_REQUEST,
  payload: { requestObj, objectState },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createOrUpdateQRCodeTemplateResponse = (
  responseStatus,
  qrCodeList,
  alertMessageData
) => ({
  type: types.CREATE_OR_UPDATE_QR_CODE_TEMPLATE_RESPONSE,
  payload: { responseStatus, qrCodeList },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const deleteQRCodeTemplateRequest = requestObj => ({
  type: types.DELETE_QR_CODE_TEMPLATE_REQUEST,
  payload: requestObj,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const deleteQRCodeTemplateResponse = (qrCodeList, alertMessageData) => ({
  type: types.DELETE_QR_CODE_TEMPLATE_RESPONSE,
  payload: qrCodeList,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
