import {
  all,
  fork,
  put,
  call,
  select,
  takeLeading,
  take,
  cancel,
} from "redux-saga/effects"
import * as types from "./actionTypes"
import * as actions from "./actions"
import { Post } from "helpers/api_helper"
import * as urls from "../../helpers/url_helper"

function* getAdminAgent(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("getAdminAgent_user_info", user_info)
  let agentsDetails
  let alertMessageData

  try {
    const reqData = {
      agentStatus: "",
      cin: user_info?.ciNo,
      role: "",
    }
    const response = yield call(Post, urls.GET_ADMIN_AGENTS, reqData)
    console.log("getAdminAgent_response", response)
    if (response && response?.data?.status) {
      agentsDetails = response?.data
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Get Agent Detials",
      }
    }
  } catch (error) {
    console.log("getAdminAgent_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To get Agent Details",
    }
  }
  return { agentsDetails, alertMessageData }
}
function* getAdminApi() {
  let alertMessageData
  let agentsDetails
  try {
    const response = yield call(getAdminAgent)
    console.log("getAdminApi_response", response)
    if (!alertMessageData) {
      agentsDetails = response?.agentsDetails || []
    } else {
      response?.alertMessageData
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To get Agent Details",
    }
  }
  yield put(actions.getAdminAgentsResponse(agentsDetails, alertMessageData))
}
function* addAgent(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("addAgent_user_info", user_info)
  const formData = action?.payload?.values
  const actionType = action?.payload?.actionType
  const setIsAddAgentOpen = action?.payload?.setIsAddAgentOpen

  let agentsDetails
  let alertMessageData

  // {
  //   "agentId": 123,
  //   "agentStatus": "Active",
  //   "cin": "TS221023I",
  //   "countryCode": "+91",
  //   "createdBy": "cmcp",
  //   "department": "Thryakshari",
  //   "emailId": "abc@gmail.com",
  //   "firstName": "Thrya",
  //   "hashedPassword": "Abc@2023",
  //   "jobTitle": "Thryakshari",
  //   "lastName": "kshari",
  //   "mobileNo": 9948012345,
  //   "objectState": "NEW",
  //   "role": "Admin"
  // }

  try {
    const reqData = {
      agentId: actionType === "add" ? 0 : formData?.agentId,
      agentStatus: formData?.agentStatus,
      createdBy: user_info?.regName,
      cin: user_info?.ciNo,
      countryCode: formData?.countryCode || "+91",
      department: formData?.department,
      emailId: formData?.email,
      firstName: formData?.firstName,
      hashedPassword: formData?.password,
      jobTitle: formData?.jobTitle,
      lastName: formData?.lastName,
      mobileNo: formData?.phNumber,
      objectState: actionType === "add" ? "NEW" : "UPDATE",
      role: formData?.roles,
    }
    console.log("addAgent_reqData", reqData)
    const response = yield call(Post, urls.ADD_AGENTS, reqData)
    console.log("addAgent_response", response, reqData)
    if (response && response?.data?.status) {
      agentsDetails = response?.data
      setIsAddAgentOpen(false)
      yield call(getAdminApi)
      alertMessageData = {
        status: true,
        message: response?.message || "Agent added successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to add agent",
      }
    }
  } catch (error) {
    console.log("addAgent_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to add agent",
    }
  }
  yield put(actions.addAgentResponse(alertMessageData))
}

function* suspendAgent(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("suspendAgent_user_info", user_info)
  const reqObj = action?.payload?.reqObj
  const setIsSuspendAgentOpen = action?.payload?.setIsSuspendAgentOpen
  const setAgentRecord = action?.payload?.setAgentRecord
  let alertMessageData

  try {
    const reqData = {
      agentId: reqObj?.agentId,
      agentStatus: reqObj?.agentStatus,
      cin: reqObj?.cin,
      countryCode: reqObj?.countryCode,
      createdBy: reqObj?.createdBy,
      department: reqObj?.department,
      emailId: reqObj?.emailId,
      firstName: reqObj?.firstName,
      hashedPassword: reqObj?.hashedPassword,
      jobTitle: reqObj?.jobTitle,
      lastName: reqObj?.lastName,
      mobileNo: reqObj?.mobileNo,
      objectState: reqObj?.objectState,
      role: reqObj?.role,
    }
    const response = yield call(Post, urls.ADD_AGENTS, reqData)
    console.log("suspendAgent_response", response)
    if (response && response?.data?.status) {
      setIsSuspendAgentOpen(false)
      setAgentRecord(undefined)
      alertMessageData = {
        status: true,
        message: response?.message || "Agent Suspended Succesfully",
      }
      yield call(getAdminApi)
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Suspend Agent",
      }
    }
  } catch (error) {
    console.log("suspendAgent_error", error)
    alertMessageData = {
      status: false,
      message: response?.message || "Unable To Suspend Agent",
    }
  }
  yield put(actions.suspendAgentResponse(alertMessageData))
}
function* deleteAgent(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("deleteAgent_user_info", user_info)
  const reqObj = action?.payload?.reqObj
  const setIsDeleteAgentOpen = action?.payload?.setIsDeleteAgentOpen
  const setAgentRecord = action?.payload?.setAgentRecord
  let alertMessageData

  try {
    const reqData = {
      agentId: reqObj?.agentId,
      agentStatus: reqObj?.agentStatus,
      cin: reqObj?.cin,
      countryCode: reqObj?.countryCode,
      createdBy: reqObj?.createdBy,
      department: reqObj?.department,
      emailId: reqObj?.emailId,
      firstName: reqObj?.firstName,
      hashedPassword: reqObj?.hashedPassword,
      jobTitle: reqObj?.jobTitle,
      lastName: reqObj?.lastName,
      mobileNo: reqObj?.mobileNo,
      objectState: reqObj?.objectState,
      role: reqObj?.role,
    }
    const response = yield call(Post, urls.ADD_AGENTS, reqData)
    console.log("deleteAgent_response", response)
    if (response && response?.data?.status) {
      setIsDeleteAgentOpen(false)
      setAgentRecord(undefined)
      alertMessageData = {
        status: true,
        message: response?.message || "Agent Deleted Succesfully",
      }
      yield call(getAdminApi)
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Delete Agent",
      }
    }
  } catch (error) {
    console.log("deleteAgent_error", error)
    alertMessageData = {
      status: false,
      message: response?.message || "Unable To Delete Agent",
    }
  }
  yield put(actions.deleteAgentResponse(alertMessageData))
}
function* getUserRolesPermissions(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("getUserRolesPermissions_user_info", user_info)
  const role = action?.payload?.role
  console.log("afsfsafsfassafasf", action)
  let rolesAndPermissions
  let alertMessageData

  try {
    const reqData = {
      // defaultPermission: false,
      cin: user_info?.ciNo,
      role: role,
    }
    const response = yield call(Post, urls.GET_ROLES_PERMISSIONS, reqData)
    console.log("getUserRolesPermissions_response", response)
    if (response && response?.data?.status) {
      rolesAndPermissions = response?.data?.data
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to Get Roles and Permissions",
      }
    }
  } catch (error) {
    console.log("getUserRolesPermissions_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to Get Roles and Permissions",
    }
  }
  return { rolesAndPermissions, alertMessageData }
}

function* getRolesPermissionsApi(action) {
  let alertMessageData
  let rolesAndPermissions
  try {
    const response = yield call(getUserRolesPermissions, action)
    console.log("getRolesPermissionsApi_response", response)
    if (!alertMessageData) {
      rolesAndPermissions = response?.rolesAndPermissions || []
    } else {
      response?.alertMessageData
    }
  } catch (error) {
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to Get Roles and Permissions",
    }
  }
  yield put(
    actions.getUserRolesPermissionsResponse(rolesAndPermissions, alertMessageData)
  )
}

function* updateRolesPremissions(action) {
  const userData = localStorage.getItem("authUserinfo")
  const user_info = userData ? JSON.parse(userData) : undefined
  console.log("updateRolesPremissions_user_info", user_info)
  const premissionsData = action?.payload?.premissionsData || []

  const role = action?.payload?.role
  const restoreDefault = action?.payload?.restoreDefault
  const permissionsCreatedBy = action?.payload?.permissionsCreatedBy
  const setIsUpdateOpenClicked = action?.payload?.setIsUpdateOpenClicked
  const setRestoreDefaults = action?.payload?.setRestoreDefaults
  let alertMessageData

  try {
    const reqData = {
      cin: user_info?.ciNo,
      createdBy: permissionsCreatedBy,
      permissions: premissionsData || [],
      restore: restoreDefault,
      role: role,
    }
    console.log("updateRolesPremissions_reqData", reqData)
    const response = yield call(Post, urls.UPDATE_ROLES_PERMISSIONS, reqData)
    console.log("updateRolesPremissions_response", response)
    if (response && response?.data?.status) {
      // agentsDetails = response?.data;
      setIsUpdateOpenClicked(false)
      setRestoreDefaults(false)
      yield call(getRolesPermissionsApi)
      alertMessageData = {
        status: true,
        message: response?.data?.message || "Updated successfully",
      }
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable to update Roles and Premissions",
      }
    }
  } catch (error) {
    console.log("updateRolesPremissions_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed to update Roles and Premissions",
    }
  }
  yield put(actions.updateRolesPremissionsResponse(alertMessageData))
}

function* userManagementWatcher() {
  yield takeLeading(types.GET_ADMIN_AGENTS_REQUEST, getAdminApi)
  yield takeLeading(types.ADD_AGENT_REQUEST, addAgent)
  yield takeLeading(types.SUSPEND_AGENT_REQUEST, suspendAgent)
  yield takeLeading(types.DELETE_AGENT_REQUEST, deleteAgent)

  yield takeLeading(types.GET_USER_ROLES_PERMISSIONS_REQUEST, getRolesPermissionsApi)
  yield takeLeading(types.UPDATE_ROLES_PREMISSIONS_REQUEST, updateRolesPremissions)
}
function* userManagementSaga() {
  yield all([fork(userManagementWatcher)])
}
export default userManagementSaga
