import { call, put, takeEvery, all, fork, takeLeading } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as actions from "./actions"

function* modelConfirmation(action) {
  if (action.payload?.data) yield put(action.payload?.data)
}
export function* watchCommonLayout() {
  yield takeLeading(types.SET_CONFIRMATION_CLOSE, modelConfirmation)
}
function* commonLayoutSaga() {
  yield all([fork(watchCommonLayout)])
}
export default commonLayoutSaga
