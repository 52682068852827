import { ACTION_TYPES } from "../../constants/index";
import * as types from "./actionTypes"

const initialState = {
  plansData: undefined,
  actionData: undefined,
  actionType: ACTION_TYPES.ADD,
  createOrUpdateSubscriptions: undefined,
  allCreatedSubscriptionResponse: undefined,
  cancelSubScriptionData: undefined,
  showModelForFeedBack: false,
  invoicesListData: undefined
}
const subscripitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CANCLE_ALL_PENDING_REQUEST_OF_SUBSCRIPTIONS:
      state = {
        ...state,
        plansData: undefined,
        actionData: undefined,
        actionType: ACTION_TYPES.ADD,
        createOrUpdateSubscriptions: undefined,
        cancelSubScriptionData: undefined,
        showModelForFeedBack: false,
        invoicesListData: undefined
      }
      break
    case types.SET_MODEL_FOR_SUBSCRIPTIONS_REQUEST:
      state = {
        ...state,
        actionData: action.payload.actionData,
        actionType: action.payload.actionType,
      }
      break
    case types.GET_ALL_SUBSCRIPTION_PLANS_RESPONSE:
      state = {
        ...state,
        plansData: action.payload,
      }
      break
    case types.CREATE_OR_UPDATE_SUBSCRIPTIONS_RESPONSE:
      state = {
        ...state,
        createOrUpdateSubscriptions: action.payload,
      }
      break;
    case types.GET_ALL_CREATED_SUBSCRIPTION_RESPONSE:
      state = {
        ...state,
        allCreatedSubscriptionResponse: action.payload
      }
      break;
    case types.CANCEL_SUBSCRIPTION_RESPONSE:
      state = {
        ...state,
        cancelSubScriptionData: action.payload
      };
      break
    case types.SHOW_MODE_FOR_FEED_BACK_REQUEST:
      state = {
        ...state,
        showModelForFeedBack: action.payload
      }
      break;
    case types.SUBMIT_FEED_BACK_RESPONSE:
      if (action.payload) {
        state = {
          ...state,
          showModelForFeedBack: false
        }
      }
      break;
    case types.GET_ALL_SUBSCRIPTION_INVOICES_RESPONSE:
      state = {
        ...state,
        invoicesListData: action.payload
      }
      break;
    default:
      state = { ...state }
  }
  return { ...state }
}
export default subscripitionsReducer
