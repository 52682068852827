import * as types from "./actionTypes"
import {
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTION_LOADING,
  LANDING_PAGE_LOADING,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  RESET_UPLOAD_RESPONSE,
} from "../common/actionTypes"

export const showModelForUpadate = (actionType, actionData) => ({
  type: types.SHOW_MODEL_FOR_UPDATE,
  payload: { actionType, actionData },
})
export const uploadingProfileFileRequest = requestData => ({
  type: types.UPLOADING_PROFILE_FILE_DATA_REQUEST,
  payload: { requestData },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const uploadingProfileFileResponse = (responseData, alertMessageData) => ({
  type: types.UPLOADING_PROFILE_FILE_DATA_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const getProfileDetilsRequest = () => ({
  type: types.GET_PROFILE_DETAILS_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const getProfileDetilsResponse = (responseData, alertMessageData) => ({
  type: types.GET_PROFILE_DETAILS_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const changepasswordRequest = (
  requestData,
  setFieldValue,
  setFieldTouched
) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload: { requestData, setFieldValue, setFieldTouched },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const changepasswordResponse = (responseData, alertMessageData) => ({
  type: types.CHANGE_PASSWORD_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const updateProfileDetailsRequest = (requestData, profilePic = false) => ({
  type: types.UPDATE_PROFILE_DETAILS_REQUEST,
  payload: { requestData, profilePic },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const updateProfileDetailsResponse = (
  responseData,
  actionType,
  alertMessageData
) => ({
  type: types.UPDATE_PROFILE_DETAILS_RESPONSE,
  payload: { responseData, actionType },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const getWAPProfileAccountDetilsReq = (
  requestData,
  isLandingPage = false
) => ({
  type: types.GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_REQUEST,
  payload: requestData,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: isLandingPage,
})
export const getWAPProfileAccountDetilsResponse = (
  responseData,
  alertMessageData
) => ({
  type: types.GET_WHATSAPP_BUSINESS_ACCOUNT_DETAILS_RESPONSE,
  payload: responseData,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const openModelForAddingOrUpdatingPhoneNumbers = (
  isEditOrCreate,
  isEditData
) => ({
  type: types.OPEN_MODEL_FOR_CREATE_OR_UPDATE_PHONE_NUMBER,
  payload: { isEditOrCreate, isEditData },
})

export const createOrEditWABAPhoneNumbersRequest = (requestData, requestType) => ({
  type: types.CREATE_OR_EDIT_WABA_PHONE_NUMBERS_REQUEST,
  payload: { requestData, requestType },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})
export const createOrEditWABAPhoneNumbersResponse = (
  response,
  alertMessageData
) => ({
  type: types.CREATE_OR_EDIT_WABA_PHONE_NUMBERS_RESPONSE,
  payload: response,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const testWABAPhoneNumbersRequest = requestData => ({
  type: types.TEST_WABA_ACCOUNT_DETIALS_REQUEST,
  payload: requestData,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})
export const testWABAPhoneNumbersResponse = (response, alertMessageData) => ({
  type: types.TEST_WABA_ACCOUNT_DETIALS_RESPONSE,
  payload: response,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const getAllPhoneNumbersRequest = () => ({
  type: types.GET_ALL_CREATED_PHONE_NUMBERS_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})
export const getAllPhoneNumbersResponse = (response, alertMessageData) => ({
  type: types.GET_ALL_CREATED_PHONE_NUMBERS_RESPONSE,
  payload: response,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const updateUserAvailabilityStatusRequest = (status, setAnchorElStatus) => ({
  type: types.UPDATE_USER_AVAILABILITY_STATUS_REQUEST,
  payload: { status, setAnchorElStatus },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const updateUserAvailabilityStatusResponse = alertMessageData => ({
  type: types.UPDATE_USER_AVAILABILITY_STATUS_RESPONSE,
  payload: {},
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const updateAgentProfileRequest = (
  values,
  resetForm,
  setIsUpdateProfile
) => ({
  type: types.UPDATE_AGENT_PROFILE_REQUEST,
  payload: { values, resetForm, setIsUpdateProfile },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const updateAgentProfileRespone = alertMessageData => {
  return {
    type: types.UPDATE_AGENT_PROFILE_RESPONSE,
    payload: {},
    loadType: ACTION_LOADING,
    loadPayload: false,
    alertMType: LAYOUT_ALERT_ACTION_REQUEST,
    alertMPayload: alertMessageData,
  }
}

//file upload
export const uploadFileRequest = (cin, file) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: { cin, file },
})

export const uploadFileSuccess = response => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: response,
})

export const uploadFileFailure = error => ({
  type: UPLOAD_FILE_FAILURE,
  payload: error,
})

export const resetUploadResponse = () => ({
  type: RESET_UPLOAD_RESPONSE,
})
