import { Post } from "../helpers/api_helper"

export function* fileUploader(files, requestUrl) {
  console.log("filesResponse=>", files, requestUrl)
  let filesResponse, fileResponseAlertMessageData

  try {
    const filesArr = Array.isArray(files) ? files : Array.from(files)
    const promises = filesArr.map(file => {
      const formData = new FormData()
      formData.append("file", file)

      return Post(requestUrl, formData, { isUpload: true })
    })
    filesResponse = yield Promise.allSettled(promises)
    filesResponse = filesResponse.map(response => {
      if (response.status === "fulfilled") {
        return response.value.data
      }
    })

    console.log("FileUploader_response => ", filesArr, filesResponse)
  } catch (err) {
    console.error("FileUploader_error => ", err)
    fileResponseAlertMessageData = {
      status: false,
      message: "Unable to upload selected files",
    }
  }

  return { filesResponse, fileResponseAlertMessageData }
}
