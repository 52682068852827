export const GET_ADMIN_AGENTS_REQUEST = "GET_ADMIN_AGENTS_REQUEST";
export const GET_ADMIN_AGENTS_RESPONSE = "GET_ADMIN_AGENTS_RESPONSE";

export const ADD_AGENT_REQUEST = "ADD_AGENT_REQUEST";
export const ADD_AGENT_RESPONSE = "ADD_AGENT_RESPONSE";

export const GET_USER_ROLES_PERMISSIONS_REQUEST = "GET_USER_ROLES_PERMISSIONS_REQUEST";
export const GET_USER_ROLES_PERMISSIONS_RESPONSE = "GET_USER_ROLES_PERMISSIONS_RESPONSE";

export const SUSPEND_AGENT_REQUEST = "SUSPEND_AGENT_REQUEST";
export const SUSPEND_AGENT_RESPONSE = "SUSPEND_AGENT_RESPONSE";

export const DELETE_AGENT_REQUEST = "DELETE_AGENT_REQUEST";
export const DELETE_AGENT_RESPONSE = "DELETE_AGENT_RESPONSE";

export const UPDATE_ROLES_PREMISSIONS_REQUEST = "UPDATE_ROLES_PREMISSIONS_REQUEST";
export const UPDATE_ROLES_PREMISSIONS_RESPONSE = "UPDATE_ROLES_PREMISSIONS_RESPONSE";