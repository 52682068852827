import {
  SET_CONFIRMATION_OPEN,
  SET_CONFIRMATION_CLOSE,
  ACTIVATE_AUTH_LAYOUT,
  LAYOUT_ALERT_ACTION_SUSPEND,
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTIVATE_NON_AUTH_LAYOUT,
  IS_LARGE,
  ACTION_LOADING,
  LANDING_PAGE_LOADING
} from "./actionTypes"
import * as _ from "lodash"
const initialState = {
  loading: false,
  alertMessage: null,
  confirmationData: null,
  landingPageLoading: false
}

const commonlayout = (state = initialState, action) => {
  switch (action.loadType) {
    case ACTION_LOADING:
      if (state.loading !== action.loadPayload)
        state = {
          ...state,
          loading: action.loadPayload,
        }
      break
    case LANDING_PAGE_LOADING:
      if (state.landingPageLoading !== action.landingPageLoading)
        state = {
          ...state,
          landingPageLoading: action.loadPayload,
        }
      break
    default:
      break
  }
  switch (action.alertMType) {
    case LAYOUT_ALERT_ACTION_REQUEST:
      state = {
        ...state,
        alertMessage: action.alertMPayload,
      }
      break
    default:
      break
  }
  switch (action.type) {
    case LAYOUT_ALERT_ACTION_SUSPEND:
      if (state.alertMessage)
        state = {
          ...state,
          alertMessage: null,
        }
      break
    case LAYOUT_ALERT_ACTION_REQUEST:
      if (action.alertMPayload)
        state = {
          ...state,
          alertMessage: action.alertMPayload,
        }
      break
    case ACTION_LOADING:
      if (state.loading !== action.payload)
        state = {
          ...state,
          loading: action.payload,
        }
      break
    case SET_CONFIRMATION_OPEN:
      state = {
        ...state,
        confirmationData: action.payload,
      }
      if (action.payload) {
        if (action.payload !== state.loading)
          state = {
            ...state,
            loading: false,
          }
      }
      break
    case SET_CONFIRMATION_CLOSE:
      if (state.confirmationData !== null)
        state = {
          ...state,
          confirmationData: null,
        }
      if (
        state.loading !== (action.payload.loading === 1 ? true : false) &&
        action.payload.data
      )
        state = {
          ...state,
          loading: action.payload.loading === 1 ? true : false,
        }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default commonlayout
