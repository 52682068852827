import * as types from "./actionTypes"
import { LAYOUT_ALERT_ACTION_REQUEST, ACTION_LOADING, LANDING_PAGE_LOADING } from "../common/actionTypes"

export const cancleAllPendingRequest = () => ({
  type: types.CANCEL_ALL_PENDING_REQUEST,
})
export const showModelDataForWhatAppTemplate = (
  actionType,
  actionData,
  placeHoldersData = undefined,
  selectedType = undefined
) => ({
  type: types.SHOW_MODAL_DATA_REQUEST_FOR_WHATSAPP_TEMPLATE,
  payload: { actionType, actionData, placeHoldersData, selectedType },
})
export const getAllPreDefinedOrUserCreatedTemplatesRequest = (isLandingPage = false) => ({
  type: types.GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_REQUEST,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: isLandingPage,
})

export const getAllPreDefinedOrUserCreatedTemplatesResponse = (
  userCreatedTemplatesData,
  preDefinedTemplateData,
  alertMessageData
) => ({
  type: types.GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_RESPONSE,
  payload: { userCreatedTemplatesData, preDefinedTemplateData },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const deleteWAPTemplateRequest = templateName => ({
  type: types.DELETE_WHATS_APP_TEMPELATE_REQUEST,
  payload: templateName,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const deleteWAPTemplateResponse = (
  userCreatedTemplatesData,
  preDefinedTemplateData,
  alertMessageData
) => ({
  type: types.DELETE_WHATS_APP_TEMPELATE_RESPONSE,
  payload: { userCreatedTemplatesData, preDefinedTemplateData },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const createOrUpdateWPATemplateRequest = (requestObj, objectState) => ({
  type: types.CREATE_OR_UPDATE_WPA_TEMPLATE_REQUEST,
  payload: { requestObj, objectState },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createOrUpdateWPATemplateResponse = (
  responseStatus,
  userCreatedTemplatesData,
  preDefinedTemplateData,
  alertMessageData
) => ({
  type: types.CREATE_OR_UPDATE_WPA_TEMPLATE_RESPONSE,
  payload: { responseStatus, userCreatedTemplatesData, preDefinedTemplateData },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const uploadingWAPTemplateRequest = requestData => ({
  type: types.UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_REQUEST,
  payload: requestData,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const uploadingWAPTemplateResponse = (
  responseData,
  userCreatedTemplatesData,
  preDefinedTemplateData,
  alertMessageData
) => ({
  type: types.UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_RESPONSE,
  payload: { responseData, userCreatedTemplatesData, preDefinedTemplateData },
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const uploadingFileRequest = (
  requestData,
  setFieldValue,
  selectedMediaType
) => ({
  type: types.UPLOADING_FILE_DATA_REQUEST,
  payload: { requestData, setFieldValue, selectedMediaType },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const uploadingFileResponse = (responseData, alertMessageData) => ({
  type: types.UPLOADING_FILE_DATA_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const getPreDefinedWAPTemplatesRequest = () => ({
  type: types.GET_DEFAULT_WAP_TEPMLATES_REQUEST,
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getPreDefinedWAPTemplatesResponse = (
  preDefinedTemplates,
  alertMessageData
) => ({
  type: types.GET_DEFAULT_WAP_TEPMLATES_RESPONSE,
  payload: preDefinedTemplates,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
export const getTemplateNameExistOrNotRequest = (requestData, setFieldValue) => ({
  type: types.GET_TEMPLATE_NAME_EXSITS_OR_NOT_REQUEST,
  payload: { requestData, setFieldValue },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const getTemplateNameExistOrNotResponse = (response, alertMessageData) => ({
  type: types.GET_TEMPLATE_NAME_EXSITS_OR_NOT_RESPONSE,
  payload: response,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
