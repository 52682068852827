export const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST"
export const GET_LOGIN_RESPONSE = "GET_LOGIN_RESPONSE"
export const GET_LOGIN_ALERT_MESSAGE = "GET_LOGIN_ALERT_MESSAGE"

export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE"
export const ACTION_LOGOUT = "ACTION_LOGOUT"

export const GET_VERIFICATON_RESPONSE = "GET_VERIFICATON_RESPONSE"
export const GET_VERIFICATION_REQUEST = "GET_VERIFICATION_REQUEST"
export const GET_VERIFICATION_ALERT_MESSAGE = "GET_VERIFICATION_ALERT_MESSAGE"

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST"
export const RESEND_OTP_RESPONSE = "RESEND_OTP_RESPONSE"
export const RESEND_OTP_ALERT_MESSAGE = "RESEND_OTP_ALERT_MESSAGE"

export const IS_LOGIN_MODEL_OPEN_OR_CLOSE = "IS_LOG IN_MODEL_OPEN_OR_CLOSE"
export const IS_VERIFICATION_MODEL_OPEN_OR_CLOSE =
  "IS_LOG IS_VERIFICATION_MODEL_OPEN_OR_CLOSE"

export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST"
export const SET_SESSION_STATE_DATA = "SET_SESSION_STATE_DATA"
export const IS_SESSION_MODEL_OPEN_OR_CLOSE = "IS_SESSION_MODEL_OPEN_OR_CLOSE"
export const IS_LOGIN_LOADING = "IS_LOGIN_LOADING"
export const FETCH_USERS = "FETCH_USERS"
export const GET_USERS = "GET_USERS"
export const SET_USERS = "SET_USERS"
