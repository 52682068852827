export const ACTION_LOADING = "ACTION_LOADING"

export const LAYOUT_ALERT_ACTION_SUSPEND = "LAYOUT_ALERT_ACTION_SUSPEND"
export const LAYOUT_ALERT_ACTION_REQUEST = "LAYOUT_ALERT_ACTION_REQUEST"

export const SET_CONFIRMATION_OPEN = "SET_CONFIRMATION_OPEN"
export const SET_CONFIRMATION_CLOSE = "SET_CONFIRMATION_CLOSE"

export const LANDING_PAGE_LOADING = "LANDING_PAGE_LOADING"

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE"
export const RESET_UPLOAD_RESPONSE = "RESET_UPLOAD_RESPONSE"
