export const SHOW_MODEL_FOR_CREATE_OR_UPDATE_DELETE =
  "SHOW_MODEL_FOR_CREATE_OR_UPDATE_DELETE"

export const GET_ALL_LIST_MESSAGE_TEMPLATE_REQUEST =
  "GET_ALL_LIST_MESSAGE_TEMPLATE_REQUEST"
export const GET_ALL_LIST_MESSAGE_TEMPLATE_RESPONSE =
  "GET_ALL_LIST_MESSAGE_TEMPLATE_RESPONSE"

export const CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_REQUEST =
  "CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_REQUEST"
export const CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_RESPONSE =
  "CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_RESPONSE"
