import * as types from "./actionTypes";
import * as actions from "./actions";
import { call, takeLatest, all, fork, put, select, takeLeading } from "redux-saga/effects";
import * as urls from "../../helpers/url_helper";
import { Post } from "../../helpers/api_helper";
import * as _ from 'lodash';





function* getReplyContentData(action) {
    console.log('checkkkkkkkkkk', action)
    let alertMessageData;
    let oneTimeMessageReplies = []
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo || '';
    let request =
    {
        cin: cin,
        contextType: "quickreplies",
        messageType: ""
    }
    console.log('getQuickReplyResponse', request)
    try {
        const response = yield call(Post, `${urls.QUICK_REPLY_ENQUIRY_MESSSAGES}`, request)
        console.log('response', response)
        if (response && response?.data?.status) {
            let responseData = response?.data?.data || []
            // const groupByMessageReplies = _.groupBy(responseData, 'messageType');
            // oneTimeMessageReplies = groupByMessageReplies
            oneTimeMessageReplies = responseData


            console.log('groupByDqata', oneTimeMessageReplies)
        }
        else {
            alertMessageData = {
                status: false,
                message: response?.message || "Unable to get Reply Content ",
                isNewAlertModalType: true,
            }
        }
    }
    catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to get Reply Content Replies",
        }
    }
    // yield put(actions?.getOneTimessageRepliesResponse(oneTimeMessageReplies, alertMessageData))
    console.log('RelyContentDataEnd', { oneTimeMessageReplies, alertMessageData })
    return { oneTimeMessageReplies, alertMessageData }

}

function* getAllQuickReplyDataRequest() {
    let allReplyContentData
    let alertMessageData
    try {
        let { oneTimeMessageReplies, alertMessageData } = yield call(getReplyContentData)
        console.log('checlldhsdgshgdh', oneTimeMessageReplies)
        allReplyContentData = oneTimeMessageReplies
        alertMessageData = alertMessageData
    }
    catch (err) {
        alertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to fetch Reply Content data",
        }
    }
    console.log(
        "responseData=>",
        allReplyContentData,
        alertMessageData
    )
    yield put(
        actions.getAllQuickReplyDataResponse(
            allReplyContentData,
            alertMessageData
        )
    )

}



function* getAllQuickReplyDataWatcher() {
    yield takeLatest(
        types.GET_ALL_QUICKREPLY_DATA_REQUEST, getAllQuickReplyDataRequest

    )
}

function* createQuickReplyContentWatcher() {
    yield takeLatest(types.CREATE_QUICK_REPLY_REQUEST, createQuickReplyResponse)

}
function* createQuickReplyResponse(action) {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo;
    let { replyItem, objectState } = action?.payload;
    let replyItemsDataAfterAdd
    let allAlertMessageData
    let responseStatus = false


    try {
        const response = yield call(Post, `${urls.ADD_UPDATE_DELETE_QUICK_REPLY}`, replyItem)
        if (response && response?.data.status) {
            responseStatus = response?.data.status

            let { oneTimeMessageReplies, alertMessageData } = yield call(getReplyContentData)
            if (!alertMessageData) {
                replyItemsDataAfterAdd = oneTimeMessageReplies
            }

            allAlertMessageData = {
                status: true,
                message: response?.data?.message,
                isNewAlertModalType: true,
            }

        }
    }
    catch (err) {
        allAlertMessageData = {
            status: false,
            message: err?.data?.message || "Failed to create Reply Content",
        }

    }
    console.log('End_create_update_delete_replyCntent', { responseStatus, replyItemsDataAfterAdd, allAlertMessageData })

    yield put(actions?.createOrUpdateQuickReplyContentResponse(responseStatus, replyItemsDataAfterAdd, allAlertMessageData))

}





function* QuickreplySaga() {
    yield all([
        fork(getAllQuickReplyDataWatcher),
        fork(createQuickReplyContentWatcher)
    ])
}

export default QuickreplySaga


