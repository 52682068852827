import * as types from "./actionTypes"
import {
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTION_LOADING,
} from "../../common/actionTypes"

export const cancelAllPendingActivites = () => ({
  type: types.CANCEL_ALL_PENTDING_ACTIVITIES,
})
export const newPasswordRequest = values => ({
  type: types.NEW_PASSWORD_REQUEST,
  payload: values,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const newPasswordResponse = (responseData, alertMessageData) => ({
  type: types.NEW_PASSWORD_RESPONSE,
  payload: responseData,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})

export const sendVerificationLinkToMailRequest = (mail, setFieldValue) => ({
  type: types.SEND_VERIFICATION_LINK_REQUEST,
  payload: { mail, setFieldValue },
  loadType: ACTION_LOADING,
  loadPayload: true,
})

export const validationForgerPasswordKeyRequest = key => ({
  type: types.VALIDATE_FORGET_PASSWORD_KET_REQUEST,
  payload: key,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const validationForgerPasswordKeyResponse = response => ({
  type: types.VALIDATE_FORGET_PASSWORD_KET_RESPONSE,
  payload: response,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const forgotPasswordAlertMessage = alertMessageData => ({
  type: types.GET_FORGOT_PASSWORD_ALERT_MESSAGE,
  // alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  // alertMPayload: alertMessageData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const resetForgotPasswordSate = () => ({
  type: types.RESET_FORGOT_PASSWORD_STATE,
})
