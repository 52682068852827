export const GET_ALL_SUBSCRIPTION_PLANS_REQUEST =
  "GET_ALL_SUBSCRIPTION_PLANS_REQUEST"
export const GET_ALL_SUBSCRIPTION_PLANS_RESPONSE =
  "GET_ALL_SUBSCRIPTION_PLANS_RESPONSE"
export const CANCLE_ALL_PENDING_REQUEST_OF_SUBSCRIPTIONS =
  "CANCLE_ALL_PENDING_REQUEST_OF_SUBSCRIPTIONS"
export const SET_MODEL_FOR_SUBSCRIPTIONS_REQUEST =
  "SET_MODEL_FOR_SUBSCRIPTIONS_REQUEST"

export const CREATE_OR_UPDATE_SUBSCRIPTIONS_REQUEST =
  "CREATE_OR_UPDATE_SUBSCRIPTIONS_REQUEST"
export const CREATE_OR_UPDATE_SUBSCRIPTIONS_RESPONSE =
  "CREATE_OR_UPDATE_SUBSCRIPTIONS_RESPONSE"

export const GET_ALL_CREATED_SUBSCRIPTION_REQUEST = "GET_ALL_CREATED_SUBSCRIPTION_REQUEST";
export const GET_ALL_CREATED_SUBSCRIPTION_RESPONSE = "GET_ALL_CREATED_SUBSCRIPTION_RESPONSE";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_RESPONSE = "CANCEL_SUBSCRIPTION_RESPONSE";

export const SHOW_MODE_FOR_FEED_BACK_REQUEST = "SHOW_MODE_FOR_FEED_BACK_REQUEST";

export const SUBMIT_FEED_BACK_REQUEST = "SUBMIT_FEED_BACK_REQUEST";
export const SUBMIT_FEED_BACK_RESPONSE = "SUBMIT_FEED_BACK_RESPONSE";

export const GET_ALL_SUBSCRIPTION_INVOICES_REQUEST = "GET_ALL_SUBSCRIPTION_INVOICES_REQUEST";
export const GET_ALL_SUBSCRIPTION_INVOICES_RESPONSE = "GET_ALL_SUBSCRIPTION_INVOICES_RESPONSE";

export const ADD_GST_NUMBER_TO_SUBSCRIPTION_REQUEST = "ADD_GST_NUMBER_TO_SUBSCRIPTION_REQUEST";
export const ADD_GST_NUMBER_TO_SUBSCRIPTION_RESPONSE = "ADD_GST_NUMBER_TO_SUBSCRIPTION_RESPONSE";