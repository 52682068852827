import axios from "axios"
import store from "../../store"
import { GET_ALL_JOBS } from "../../helpers/url_helper"
import { getUTCstring } from "../../helpers/helperUtils"
import { CRON_JOB_OBJECT_STATE } from "../../constants"
import { setCampaignStateByKey } from "./actions"

//change url

// const JOB_SCHEDULER_BASE_URL = "https://www.backend-api.notifyy.io/v3/api/"
const JOB_SCHEDULER_BASE_URL = "https://www.scheduler.notifyy.io/v3/api/"
const TSS_CHNL = "TSSP"

const getAxiosConfig = () => {
  const token = store?.getState?.()?.["loginReducer"]?.authToken

  return {
    headers: {
      Authorization: token,
    },
  }
}

const getCinNo = () => store?.getState?.()?.["loginReducer"]?.sessionStateData?.ciNo

const getCronMap = () =>
  store?.getState?.()?.["campaignManagementReducer"]?.cronJobsMap

export const getCampaignJobId = campaignId => {
  const cronJobsMap = getCronMap()
  return cronJobsMap[Math.abs(campaignId)]?.jobId || 0
}

const updateCronMap = ({ requestType, campaignId, jobScheduleResponse }) => {
  const existingCronMap = getCronMap()
  const updatedCronMap = { ...(existingCronMap || {}) }

  if (requestType === CRON_JOB_OBJECT_STATE.CREATE) {
    updatedCronMap[campaignId] = {
      jobId: jobScheduleResponse.jobId,
      jobStatus: "",
    }
  } else if (requestType === CRON_JOB_OBJECT_STATE.DELETE) {
    delete updatedCronMap[campaignId]
  }

  store?.dispatch(setCampaignStateByKey("cronJobsMap", updatedCronMap))
}

// Cron Job Scheduling Http Helpers
async function Get(url) {
  const response = await axios.get(
    `${JOB_SCHEDULER_BASE_URL}${url}`,
    getAxiosConfig()
  )
  return response
}

async function Post(url, body) {
  const response = await axios.post(
    `${JOB_SCHEDULER_BASE_URL}${url}`,
    body,
    getAxiosConfig()
  )
  return response
}

async function Put(url, body) {
  const response = await axios.put(
    `${JOB_SCHEDULER_BASE_URL}${url}`,
    body,
    getAxiosConfig()
  )
  return response
}

async function Delete(url, body) {
  const response = await axios.delete(
    `${JOB_SCHEDULER_BASE_URL}${url}/${body.jobId}`,
    getAxiosConfig()
  )
  return response
}

export const cronJobCrud = async requestObj => {
  const { requestType, requestUrl, campaignId, scheduledTime } = requestObj
  let status = false,
    cronJobResponse = {}

  try {
    const requestObj = {
      campaignId: Math.abs(campaignId),
      cin: getCinNo(),
      jobId: getCampaignJobId(campaignId),
      scheduledTime: scheduledTime ? getUTCstring(scheduledTime) : "",
      createdBy: TSS_CHNL,
      tssChl: TSS_CHNL,
      updateBy: TSS_CHNL,
    }

    const isDeleteCronJob = requestType === CRON_JOB_OBJECT_STATE.DELETE
    let fetcher

    if (isDeleteCronJob) {
      if (requestObj.jobId > 0) {
        fetcher = Delete
      }
    } else {
      fetcher = requestType === CRON_JOB_OBJECT_STATE.UPDATE ? Put : Post
    }

    if (fetcher) {
      const { data: jobScheduleResponse } = await fetcher(requestUrl, requestObj)
      console.log("__cronJobCrud", { requestObj, jobScheduleResponse })

      if (jobScheduleResponse && jobScheduleResponse.status) {
        status = true
        cronJobResponse = jobScheduleResponse

        updateCronMap({ requestType, campaignId, jobScheduleResponse })
      }
    } else {
      status = true
    }
  } catch (err) {
    console.error(`createCronJob_catch campaignId: ${campaignId}`, err)
  }

  return { status, cronJobResponse }
}

export const getAllJobs = async () => {
  let status = false,
    jobs

  try {
    const { data } = await Post(GET_ALL_JOBS, {
      cin: getCinNo(),
      jobStatus: "",
      jobType: "",
    })

    if (data) {
      status = true
      jobs = data
    }
  } catch (err) {
    console.error("getAllJobs_catch", err)
  }

  return { status, jobs }
}
