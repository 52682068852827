import * as XLSX from "xlsx"
import {
  matchTemplateVariableCountRegEx,
  matchEntireTemplateVariableCountRegEx,
} from "../constants"

export const getPlaceholderMatchCount = str =>
  str?.match(matchTemplateVariableCountRegEx)?.length || 0

export const getMatches = str => {
  const matches = str?.match(matchTemplateVariableCountRegEx)

  if (matches && matches.length)
    return matches.map(matchStr =>
      Number(matchStr?.length === 3 ? matchStr.substr(-1) : matchStr.substr(-2))
    )
  return []
}

export const getIsValidSequence = str => {
  const matchedNumbers = getMatches(str)

  if (matchedNumbers && matchedNumbers.length)
    return matchedNumbers.every(
      (num, index, array) => num + 1 === (array[index + 1] ?? num + 1)
    )
  return true
}

export const getTemplateVariableNextCount = str => {
  const initialDynamicVariable = 1
  const matchedNumbers = getMatches(str)

  if (matchedNumbers && matchedNumbers.length) {
    return Math.max(...matchedNumbers) + initialDynamicVariable
  }
  return initialDynamicVariable
}

export const getLocalFileURL = file => {
  try {
    return URL.createObjectURL(file)
  } catch (err) {
    console.error("_getLocalFileURL", err)
  }
}

export const getFileBlob = ({ method, url, body }) => {
  return new Promise((resolve, reject) => {
    try {
      // const token = store?.getState()?.loginReducer?.authToken ?? '';

      const xhr = new XMLHttpRequest()
      xhr.open(method, url, true)
      // xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      // xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = "blob"

      xhr.onload = () => {
        const fileBlob = URL.createObjectURL(xhr.response)
        resolve(fileBlob)
      }

      xhr.send(body ? JSON.stringify(body) : null)
    } catch (err) {
      console.error("_getFileBlob_catch", err)
      reject("")
    }
  })
}

export const filterDataByKey = (data, key, value) => {
  if (data?.length && key && value) {
    return data.filter(item => item[key] === value)
  }
  return data
}

export const getCharsEnteredStr = (currentValue, maxLength) =>
  `${currentValue?.length || 0} / ${maxLength || 100}`

export const safeParseJSON = str => {
  let parsedJSON

  try {
    console.log("_safeParseJSON_before_parse", str)
    parsedJSON = typeof str === "string" && str !== "" ? JSON.parse(str) : []
    console.log("_safeParseJSON_after_parse", parsedJSON)
  } catch (err) {
    parsedJSON = []
    console.error("_parseJSON_catch", err)
  }
  return parsedJSON
}

export const validatePlaceholderSyntax = str => {
  try {
    if (str && str.length) {
      const variableMatches = (
        (str || "").match(matchEntireTemplateVariableCountRegEx) || []
      ).length
      const isPlaceholderSyntaxAvailable =
        str.split("{").length > 1 || str.split("}").length > 1
      const nextPlaceholder = getTemplateVariableNextCount(str)

      if (variableMatches > 0 || isPlaceholderSyntaxAvailable) {
        if (nextPlaceholder - 1 === 0) {
          return true
        }
        return nextPlaceholder - 1 === variableMatches
      } else if (!variableMatches || !isPlaceholderSyntaxAvailable) {
        return true
      }
      return false
    }
    return true
  } catch (err) {
    console.error("_validatePlaceholderSyntax_catch", err)
  }
}

// Export / Download into csv (or) xlsx files.
export const exportDataIntoCsvOrExcel = ({
  fileName,
  fileData,
  fileType = "xlsx",
}) => {
  // doc - https://docs.sheetjs.com/docs/getting-started/example/#export-a-file
  try {
    const ws = XLSX.utils.json_to_sheet(fileData ?? [])
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1")

    XLSX.writeFile(wb, fileName, { bookType: fileType })
  } catch (err) {
    console.error("_createExcel_catch", err)
  }
}

// Accepts date string and returns date ISO string.
export const getUTCstring = dateStr => {
  let isoString = dateStr

  try {
    if (dateStr) isoString = new Date(dateStr).toISOString()
    else isoString = new Date().toISOString()
  } catch (err) {
    console.error("_getUTCstring_catch", err)
  }

  return isoString
}

export const asBoolean = value => {
  const valueType = typeof value

  switch (valueType) {
    case "boolean":
      return value
    case "string":
      return value === "true"
    case "number":
      return value === 1
    default:
      return false
  }
}
