import { combineReducers } from "redux"
import Dashboard from "./dashboard/reducer"
import commonlayout from "./common/reducer"
import loginReducer from "./auth/login/reducer"
import signUpReducer from "./auth/signUp/reducer"
import { ACTION_LOGOUT } from "./auth/login/actionTypes"
import forgotPasswordReducer from "./auth/forgotPassword/reducer"
import createContactReducer from "./contactManagement/reducer"
import codeConfigurationReducer from "./codeConfiguration/reducer"
import WhatsAppTemplateReducer from "./whatsappTemplates/reducer"
import emailTemplatesReducer from "./emailTemplates/reducer"
import QrcodeListReducer from "./qrCodeTemplates/reducer"
import WhatsAppListMessageReducer from "./whatsAppListMessages/reducer"
import campaignManagementReducer from "./campaignManagement/reducer"
import InsightsReducer from "./insights/reducer"
import profileReducer from "./profile/reducer"
import WhatsAppWebReducer from "./whatsappweb/reducer"
import subscripitionsReducer from "./subscriptions/reducer"
import MessageRulesReducer from "./messageRules/reducer";
import userManagementReducer from "./userManagement/reducer"
import rulesConfigurationReducer from "./rulesConfiguration/reducer"
import ReplyContentReducer from "./replyContent/reducer"
import QuickReplyReducer from "./qucikReply/reducer"

const appReducer = combineReducers({
  Dashboard,
  commonlayout,
  loginReducer,
  signUpReducer,
  forgotPasswordReducer,
  createContactReducer,
  codeConfigurationReducer,
  WhatsAppTemplateReducer,
  emailTemplatesReducer,
  QrcodeListReducer,
  WhatsAppListMessageReducer,
  campaignManagementReducer,
  InsightsReducer,
  profileReducer,
  WhatsAppWebReducer,
  subscripitionsReducer,
  MessageRulesReducer,
  userManagementReducer,
  rulesConfigurationReducer,
  ReplyContentReducer, QuickReplyReducer
})

const rootReducer = (state, action) => {
  if (action.type === ACTION_LOGOUT) {
    console.log(ACTION_LOGOUT)
    state = undefined
  }
  return appReducer(state, action)
}
export default rootReducer
