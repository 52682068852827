import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes"

const initialState = {
  actionData: undefined,
  actionType: ACTION_TYPES.SELECT,
  phoneNumbersListData: [],
  qrCodeList: [],
}
const QrcodeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_MODEL_FOR_CREATE_OE_UPDATE_OR_DELETE:
      state = {
        ...state,
        actionData: action.payload.actionData,
        actionType: action.payload.actionType,
      }
      break
    case types.GET_ALL_QR_CODE_MESSAGE_TEMPLTAE_RESPONSE:
      state = {
        ...state,
        qrCodeList: action.payload,
      }
      break
    case types.CREATE_OR_UPDATE_QR_CODE_TEMPLATE_RESPONSE:
      if (action.payload.responseStatus) {
        state = {
          ...state,
          actionType: ACTION_TYPES.SELECT,
          qrCodeList: action.payload.qrCodeList,
        }
      }
      break
    case types.DELETE_QR_CODE_TEMPLATE_RESPONSE:
      state = {
        ...state,
        qrCodeList: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}
export default QrcodeListReducer
