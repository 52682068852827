export const SET_SIDEBAR_ACTION_REQUEST = "SET_SIDEBAR_ACTION_REQUEST"
export const SET_SIDEBAR_ACTION_RESPONSE = "SET_SIDEBAR_ACTION_RESPONSE"

export const OPEN_NODE_REQUEST = "OPEN_NODE_REQUEST"
export const OPEN_NODE_RESPONSE = "OPEN_NODE_RESPONSE"

export const STORE_NODE_REQUEST = "STORE_NODE_DATA_REQUEST"
export const STORE_NODE_RESPONSE = "STORE_NODE_DATA_RESPONSE"

export const CREATE_KEYWORD_REQUEST = "CREATE_KEYWORD_REQUEST"
export const CREATE_KEYWORD_RESPONSE = "CREATE_KEYWORD_RESPONSE"

export const SET_CARD_ACTION_TYPE_REQUEST = "SET_CARD_ACTION_TYPE_REQUEST"
export const SET_CARD_ACTION_TYPE_RESPONSE = "SET_CARD_ACTION_TYPE_RESPONSE"

// text
export const CREATE_QUESTION_TYPE_TEXT_NODE_REQUEST =
  "CREATE_QUESTION_TYPE_TEXT_NODE_REQUEST"
export const CREATE_QUESTION_TYPE_TEXT_NODE_REPONSE =
  "CREATE_QUESTION_TYPE_TEXT_NODE_REPONSE"

// list
export const CREATE_QUESTION_TYPE_LIST_NODE_REQUEST =
  "CREATE_QUESTION_TYPE_LIST_NODE_REQUEST"
export const CREATE_QUESTION_TYPE_LIST_NODE_RESPONSE =
  "CREATE_QUESTION_TYPE_LIST_NODE_RESPONSE"

// email
export const CREATE_EMAIL_NODE_REQUEST = "CREATE_EMAIL_NODE_REQUEST"
export const CREATE_EMAIL_NODE_RESPONSE = "CREATE_EMAIL_NODE_RESPONSE"

// phone number
export const CREATE_NUMBER_NODE_REQUEST = "CREATE_NUMBER_NODE_REQUEST"
export const CREATE_NUMBER_NODE_RESPONSE = "CREATE_NUMBER_NODE_RESPONSE"

// ask number
export const CREATE_PHONE_NUMBER_NODE_REQUEST = "CREATE_PHONE_NUMBER_NODE_REQUEST"
export const CREATE_PHONE_NUMBER_NODE_RESPONSE = "CREATE_PHONE_NUMBER_NODE_RESPONSE"

// set Condition
export const CREATE_CONDITION_NODE_REQUEST = "CREATE_CONDITION_NODE_REQUEST"
export const CREATE_CONDITION_NODE_RESPONSE = "CREATE_CONDITION_NODE_RESPONSE"

// ASk username
export const CREATE_USERNAME_NODE_REQUEST = "CREATE_USERNAME_NODE_REQUEST"
export const CREATE_USERNAME_NODE_RESPONSE = "CREATE_USERNAME_NODE_RESPONSE"

// keypad options
export const CREATE_KEY_PAD_OPTIONS_NODE_REQUEST =
  "CREATE_KEY_PAD_OPTIONS_NODE_REQUEST"
export const CREATE_KEY_PAD_OPTIONS_NODE_RESPONSE =
  "CREATE_KEY_PAD_OPTIONS_NODE_RESPONSE"

// location
export const CREATE_LOCATION_NODE_REQUEST = "CREATE_LOCATION_NODE_REQUEST"
export const CREATE_LOCATION_NODE_RESPONSE = "CREATE_LOCATION_NODE_RESPONSE"

// assign to agent
export const CREATE_ASSIGN_TO_AGENT_NODE_REQUEST =
  "CREATE_ASSIGN_TO_AGENT_NODE_REQUEST"
export const CREATE_ASSIGN_TO_AGENT_NODE_RESPONSE =
  "CREATE_ASSIGN_TO_AGENT_NODE_RESPONSE"

export const CREATE_REPLY_BUTTON_REQUEST = "CREATE_REPLY_BUTTON_REQUEST"
export const CREATE_REPLY_BUTTON_RESPONSE = "CREATE_REPLY_BUTTON_RESPONSE"

export const OPEN_FLOW_CARD_REQUEST = "OPEN_FLOW_CARD_REQUEST"
export const OPEN_FLOW_CARD_RESPONSE = "OPEN_FLOW_CARD_RESPONSE"

export const CREATE_TEXT_MESSAGE_NODE_REQUEST = "CREATE_TEXT_MESSAGE_NODE_REQUEST"
export const CREATE_TEXT_MESSAGE_NODE_RESPONSE = "CREATE_TEXT_MESSAGE_NODE_RESPONSE"

export const GET_ASSIGN_TO_AGENT_REQUEST = "GET_ASSIGN_TO_AGENT_REQUEST"
export const GET_ASSIGN_TO_AGENT_RESPONSE = "GET_ASSIGN_TO_AGENT_RESPONSE"

export const ADD_VARIABLE_REQUEST = "ADD_VARIABLE_REQUEST"
export const ADD_VARIABLE_RESPONSE = "ADD_VARIABLE_RESPONSE"
