import axios from "axios"
// export const BASE_URL = "https://backend-api.notifyy.io/v3/api/"

import store from "../store/index"

export const BASE_URL = "https://backend-api.notifyy.io/v3/api/"
export const UPLOAD_URL = "https://www.object.notifyy.io/v3/api/"
export const CAMPAIGN_BASE_URL = "https://www.scheduler.notifyy.io/v3/api/"

// export const BASE_URL = "https://object.notifyy.io/v3/api/"
// export const BASE_URL = "https://notifyy-api.thryakshari.com/v3/api/"
// export const BASE_URL = "https://notifyy-api.sit.apps.cs.thryakshari.com/v3/api/"

// export async function Get(url, config = {}, isBaseUrl = true, isAuth = true) {
//   const storeData = store.getState()["loginReducer"]
//   console.log("storeData", storeData)
//   const tokenInfo = storeData?.authToken
//   const axiosOptions = {
//     headers: {
//       Authorization: tokenInfo,
//     },
//     ...config,
//   }
//   const baseUrl = isBaseUrl ? `${BASE_URL}${url}` : url
//   const axiosReq = isAuth ? axios.get(baseUrl, axiosOptions) : axios.get(baseUrl)
//   console.log("GET_API_Helper_Start_==>", baseUrl)

//   try {
//     const res = await axiosReq
//     console.log("Get_API_Helper_Response_==>", res)
//     return res?.data
//   } catch (err) {
//     console.log("GET_API_Helper_Error==>", err)
//     return err
//   }
// }

export async function Get(endpoint, config = {}, options = {}) {
  const storeData = store.getState()["loginReducer"]
  console.log("storeData", storeData)
  const tokenInfo = storeData?.authToken
  const axiosOptions = {
    headers: {
      Authorization: tokenInfo,
    },
    ...config,
  }

  const { auth = true, isUpload = false, isCampaign = false } = options
  const baseUrl = isCampaign ? CAMPAIGN_BASE_URL : isUpload ? UPLOAD_URL : BASE_URL
  // Concatenate baseUrl and endpoint to form the full URL
  const finalUrl = `${baseUrl}${endpoint}`

  const axiosReq = auth ? axios.get(finalUrl, axiosOptions) : axios.get(finalUrl)

  try {
    const res = await axiosReq
    console.log("Get_API_Helper_Response_==>", res)
    return res?.data
  } catch (err) {
    console.log("GET_API_Helper_Error==>", err)
    return err
  }
}

export async function Post(endpoint, data, options = {}) {
  const { auth = true, isUpload = false, isCampaign = false } = options
  const baseUrl = isCampaign ? CAMPAIGN_BASE_URL : isUpload ? UPLOAD_URL : BASE_URL
  // Concatenate baseUrl and endpoint to form the full URL
  const finalUrl = `${baseUrl}${endpoint}`

  const storeData = store.getState()["loginReducer"]
  console.log("storeData", storeData)
  const tokenInfo = storeData?.authToken
  const axiosOptions = {
    headers: {
      Authorization: tokenInfo,
    },
  }
  const axiosReq = auth
    ? axios.post(finalUrl, data, axiosOptions)
    : axios.post(finalUrl, data)

  try {
    const res = await axiosReq
    console.log("POST_API_Helper_Response==>111", res)
    if (res.status === 400 || res.status === 500) throw res
    return res
  } catch (err) {
    handleAxiosError(err)
  }
}

export async function Put(url, data, auth = true) {
  const baseUrl = `${BASE_URL}${url}`
  const storeData = store.getState()["loginReducer"]
  console.log("storeData", storeData)
  const tokenInfo = storeData?.authToken
  const axiosOptions = {
    headers: {
      Authorization: tokenInfo,
    },
  }
  const axiosReq = auth
    ? axios.put(baseUrl, data, axiosOptions)
    : axios.put(baseUrl, data)

  try {
    const res = await axiosReq
    console.log("PUT_API_Helper_Response==>111", res)
    if (res.status === 400 || res.status === 500) throw res
    return res
  } catch (err) {
    handleAxiosError(err)
  }
}

export async function Delete(url, auth = true) {
  const baseUrl = `${BASE_URL}${url}`
  const storeData = store.getState()["loginReducer"]
  console.log("storeData", storeData)
  const tokenInfo = storeData?.authToken
  const axiosOptions = {
    headers: {
      Authorization: tokenInfo,
    },
  }
  console.log("DELETE_API_Helper_Start==>", baseUrl, auth, axiosOptions)
  const axiosReq = auth ? axios.delete(baseUrl, axiosOptions) : axios.delete(baseUrl)

  try {
    const res = await axiosReq
    console.log("DELETE_API_Helper_Response==>111", res)
    if (res.status === 400 || res.status === 500) throw res
    return res
  } catch (err) {
    handleAxiosError(err)
  }
}

function handleAxiosError(err) {
  if (err.response) {
    console.log("AxiosCatchSCHresponse=>", err.response)
    throw err.response
  } else if (err.request) {
    console.log("AxiosCatchSCHrequest=>", err.request)
    throw err
  } else {
    console.log("AxiosCatchSCHelse", err.message)
    throw err
  }
}

export async function MultiCallsRequest(multiCallReqArr, extraPromises) {
  try {
    const promises = multiCallReqArr.map(requestObj => {
      const { method, url, body } = requestObj
      const requestMethod = method?.toLowerCase() ?? ""

      if (requestMethod === "post") return Post(url, body)
      if (requestMethod === "get") return Get(url)
    })

    if (extraPromises) {
      if (Array.isArray(extraPromises) && extraPromises.length > 0)
        promises.push(...extraPromises)
      else promises.push(extraPromises)
    }

    const responses = await Promise.allSettled(promises)
    return responses.map(response =>
      response.status === "fulfilled" ? response.value : []
    )
  } catch (err) {
    console.error("MultiCall_catch", err)
  }
}
