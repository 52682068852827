import { call, put, all, fork, takeLeading } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as actions from "./actions"
import * as urls from "../../../helpers/url_helper"
import { Get, Post } from "../../../helpers/api_helper"

const createAccountData = data => Post(urls.CREATE_ACCOUNT, data, { auth: false })

function* createAccount({ payload: { requestData, history, resetForm } }) {
  let alertMessageData
  let createAccountRequest = {
    brandName: requestData.brandName ? requestData.brandName : "",
    businessType: requestData?.businessPartner?.label || "",
    countryCode: requestData.countryCode ? requestData.countryCode.label : "",
    emailId: requestData.email ? requestData.email : "",
    firstName: requestData.firstName ? requestData.firstName : "",
    lastName: requestData.lastName ? requestData.lastName : "",
    mobileNo: requestData.whatsappNumber ? +requestData.whatsappNumber : "",
    hashedPassword: requestData.password ? requestData.password : "",
  }
  try {
    console.log("createAccount_Api_request==>", createAccountRequest)
    const response = yield call(createAccountData, createAccountRequest)
    console.log("createAccount_Api_responce==>", response, response.data)
    if (response && response.data && response.data.status) {
      alertMessageData = {
        status: true,
        message:
          response && response.data && response.data.message
            ? response.data.message
            : "User Created successfully",
      }
      resetForm()
    } else {
      alertMessageData = {
        status: false,
        message:
          response && response.data && response.data.message
            ? response.data.message
            : "UNABLE_TO_ADD_PROFESSIONAL_DATA",
      }
    }
  } catch (error) {
    console.log("error=>", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "FAILED_TO_ADD_PROFESSIONAL_DATA",
    }
  }
  yield put(actions.signUpAlertMessage(alertMessageData))
}
export function* watchSignUp() {
  yield takeLeading(types.CREATE_ACCOUNT_REQUEST, createAccount)
}
function* signUpSaga() {
  yield all([fork(watchSignUp)])
}
export default signUpSaga
