import * as types from "./actionTypes"
import * as actions from "./actions"
import { call, takeLeading, all, fork, put, select } from "redux-saga/effects"
import * as urls from "../../helpers/url_helper"
import { Get, Post } from "../../helpers/api_helper"
import * as _ from "lodash"
import axios from "axios"
import { setWhatsappWebStateByKey, initiateNewChat } from "../actions"
import { WHATSAPP_WEB_ACTION_TYPES } from "../../constants/index"
/*
For Getting the Contact List Api Method
 */

function* getContactManagementWatcher() {
  yield takeLeading(types.GET_ALL_CONTACTS_REQUEST, getAllContacts)
}
function* getAllContacts(action) {
  let managecontactdata = []
  let totalPages = {}
  let {
    requestData,
    downloadRequest = false,
    isLandingPage,
    isPagination,
  } = action.payload || action
  let previousContactsData = (yield select())["createContactReducer"]
    ?.contactListData
  try {
    const response = yield call(Post, urls.GET_ALL_CONTACTS, requestData || action)
    if (response.data) {
      if (downloadRequest) {
        response.data.data.map(x => {
          managecontactdata.push({
            firstName: x.firstName,
            lastName: x.lastName,
            contactName: x.contactName,
            dob: x.dob,
            salutation: x?.salutation?.codeValueCd,
            gender: x?.gender?.refCodeValueCd,
            emailId: x.emailId,
            mobileNo: x.mobileNo,
            occupation: x?.occupation?.refCodeValueCd,
            jobTitle: x.jobTitle,
            employeeStatus: x?.employeeStatus?.refCodeValueCd,
            customerType: null,
            staffIndicator: x?.staffIndicator?.refCodeValueCd,
            category: x?.category?.refCodeValueCd,
            department: x?.department,
            relOfficer: x.relOfficer,
            customField: x.customField,
            addressType: x?.addressDetails?.addressType?.refCodeValueCd,
            country: x?.addressDetails?.country?.codeValueDispVal,
            streetLine1: x?.addressDetails?.streetLine1,
            streetLine2: x?.addressDetails?.streetLine2,
            city: x?.addressDetails?.city,
            district: x?.addressDetails?.district,
            postalCode: x?.addressDetails?.postalCode,
            state: x?.addressDetails?.state,
            ciNo: x?.ciNo,
          })
        })
      } else {
        managecontactdata = isPagination
          ? [...response?.data?.data, ...previousContactsData]
          : response.data.data
        totalPages = response?.data?.totalPages
      }
    }
  } catch (err) {
    console.error("__getAllContacts", err)
  }
  yield put(actions.getAllContactsResponse(managecontactdata, totalPages))
}

/*
API Method For Searched values
 */

function* getSearchedConttactListWatcher() {
  yield takeLeading(types.GET_SEARCH_VALUE_REQUEST, getSearchedConttactList)
}
function* getSearchedConttactList(action) {
  let managecontactdata = []
  let alertMessageData
  try {
    const response = yield call(
      Post,
      urls.GET_SEARCHED_CONTACTS,
      action?.payload || action
    )
    managecontactdata = response.data.data
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(actions.getSearchedValueResponse(managecontactdata, alertMessageData))
}
/*
To CreateOrUpdateOrDelete Api Method
 */
function* CreateOrUpdateOrDeleteWatcher() {
  yield takeLeading(
    types.CREATE_OR_UPDATE_DELETE_CONTACT_REQUEST,
    createOrUpdateOrDelete
  )
}
function* createOrUpdateOrDelete(action) {
  let alertMessageData = {}
  let managecontactdata
  let userinfoobj = (yield select())["loginReducer"]?.sessionStateData
  const ciNumberval = userinfoobj?.ciNo
  let { requestData, isDefaultFlow } = action.payload
  try {
    const response = yield call(Post, urls.CONTACT_CREATE, requestData || action)
    if (response.data.status) {
      managecontactdata = response.data
      let requestData1 = {
        category: "",
        cin: ciNumberval,
        contactName: "",
        customerType: "",
        department: "",
        dob: "",
        emailId: "",
        employeeStatus: "",
        globalSearch: "",
        jobTitle: "",
        occupation: "",
        pageNo: 0,
        pageSize: 100,
        phonoNo: "",
        staffIndicator: "",
        filterSearch: false,
      }
      if (!isDefaultFlow) yield call(getAllContacts, requestData1)
      alertMessageData = {
        status: true,
        message: response?.data?.message
          ? response?.data?.message
          : "Contact Created Succesfull",
      }
      if (isDefaultFlow) {
        yield put(
          initiateNewChat(
            requestData?.contactNo || action?.contactNo,
            `${requestData?.firstName} ${requestData?.lastName}` ||
              `${action?.firstName} ${action?.lastName}`
          )
        )
        yield put(
          setWhatsappWebStateByKey(
            "actionType",
            WHATSAPP_WEB_ACTION_TYPES.VIEW_MESSAGES
          )
        )
      }
    } else {
      managecontactdata = response.data
      alertMessageData = {
        status: false,
        message: response?.data?.message
          ? response?.data?.message
          : "Contact Created UnSuccesfull",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(
    actions.createOrUpdateOrDeleteResponse(managecontactdata, alertMessageData)
  )
}

/*
To get the Codevalues API method
 */
function* getCodeValuesByEnquiryWatcher() {
  yield takeLeading(
    types.GET_CODE_VALUE_FOR_ENQUIRES_REQUEST,
    getCodeValuesByEnquiry
  )
}
const getCodValueName = key => {
  let codename = ""
  switch (+key) {
    case 10:
      codename = "salutation"
      break
    case 11:
      codename = "maritialStatus"
      break
    case 12:
      codename = "gender"
      break
    case 13:
      codename = "occupationalGroups"
      break
    case 14:
      codename = "countryCode"
      break
    case 15:
      codename = "businessType"
      break
    case 16:
      codename = "customerStatus"
      break
    case 17:
      codename = "customerType"
      break
    case 18:
      codename = "nationality"
      break
    case 19:
      codename = "addressType"
      break
    case 20:
      codename = "staffIndicator"
      break
    case 21:
      codename = "employeeStatus"
      break
    case 22:
      codename = "category"
      break
    default:
      codename = ""
      break
  }
  return codename
}
function* getCodeValuesByEnquiry(action) {
  let codeValues = []
  let alertMessageData
  let { requestData, downloadRequest } = action.payload
  let userinfoobj = (yield select())["loginReducer"]?.sessionStateData
  const ciNumberval = userinfoobj?.ciNo
  try {
    const response = yield call(Post, urls.CODE_ENQUIRY, requestData)
    if (response.data.data && response.data.status) {
      let reqData = {
        cin: ciNumberval,
        codeTypeIds: response.data.data?.map(x => x.codeTypeId),
        status: "",
        codeValueId: 0,
      }
      try {
        const responseData = yield call(Post, urls.CODE_CODEVALUES, reqData)
        if (downloadRequest) {
          codeValues = responseData?.data?.data?.codeValuesWithCodeTypes.map(x => {
            return { [getCodValueName(x.codeTypeId)]: x.codeValueCd }
          })
        } else {
          codeValues = _(responseData.data.data?.codeValuesWithCodeTypes)
            .groupBy("codeTypeId")
            .map((objs, key) => {
              return {
                [getCodValueName(key)]: objs,
              }
            })
            .value()
        }
      } catch (err) {
        alertMessageData = {
          status: false,
          message: err?.data?.message ? err?.data?.message : "Some thing went worng",
        }
      }
    } else {
      codeValues = []
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(actions.getCodeValuesByEnquiryResponse(codeValues))
}
function* deleteContactWatcher() {
  yield takeLeading(types.DELETE_CONTACT_LIST_DATE_REQUEST, deleteContactRequest)
}
function* deleteContactRequest(action) {
  let { requestData, setCheckedValue, setShowCheckBox } = action.payload
  let deleteSuccesData
  let alertMessageData = {}
  let userinfoobj = (yield select())["loginReducer"]?.sessionStateData
  const ciNumberval = userinfoobj?.ciNo
  try {
    for (let i = 0; i < requestData.length; i++) {
      const response = yield call(Post, urls.CONTACT_CREATE, requestData[i])
      if (response.data.status) {
        if (i + 1 == requestData.length) {
          deleteSuccesData = response.data
          let requestData = {
            category: "",
            cin: ciNumberval,
            contactName: "",
            customerType: "",
            department: "",
            dob: "",
            emailId: "",
            employeeStatus: "",
            globalSearch: "",
            jobTitle: "",
            occupation: "",
            pageNo: 0,
            pageSize: 100,
            phonoNo: "",
            staffIndicator: "",
            filterSearch: false,
          }
          yield call(getAllContacts, requestData)
          setCheckedValue([])
          // setShowCheckBox(false)
          alertMessageData = {
            status: true,
            message: response?.data?.message
              ? response?.data?.message
              : "Contact deleted Succesfull",
          }
        }
      } else {
        deleteSuccesData = response.data
        alertMessageData = {
          status: false,
          message: response?.data?.message
            ? response?.data?.message
            : "Contact delete unsuccesfull",
        }
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(actions.deleteContactResponse(deleteSuccesData, alertMessageData))
}

function* bulkUploadContactsWatcher() {
  yield takeLeading(
    types.CREATE_BULK_UPLOAD_CONTACT_REQUEST,
    createBulkUploadContact
  )
}
function* createBulkUploadContact(action) {
  let { requestObj, fileData, setFileName, setAcceptCondition, documentData } =
    action.payload
  let alertMessageData = {}
  let responseData
  console.log("createBulkUploadContact", { requestObj, fileData })
  yield put(
    actions.openModelForShowingUploadingStatus(
      true,
      "Please Wait Contacts are uploading"
    )
  )
  try {
    const formData = new FormData()
    formData.append("file", requestObj?.contacts)
    const response = yield call(
      Post,
      `${urls.CONTACT_BULK_UPLOAD}?cin=${requestObj?.cin}`,
      formData
    )
    yield put(
      actions.openModelForShowingUploadingStatus(
        true,
        "Please untill wait contacts are uploading"
      )
    )
    if (response.data.status) {
      responseData = response.data
      let duplicateData = response?.data?.duplicateContacts?.map((x, index) => {
        return {
          "Sl/No": index,
          "First Name": x.firstName,
          "Last Name": x.lastName,
          Salutation: x.salutation,
          Gender: x.gender,
          "Date Of Birth": x.dob,
          "Email Id": x.emailId,
          "Mobile No": x.mobileNo,
          Occupation: x.occupation,
          "Job Title": x.jobTitle,
          "Employment Status": x.employeeStatus,
          "Customer Type": x.customerType,
          "Staff Flag": x.staffIndicator,
          Department: x.department,
          "Relation Officer": x.relOfficer,
          "Custom Field": x.customField,
          "Address Type": x.addressDetails.addressType,
          "Street 1": x.addressDetails.streetLine_1,
          "Street 2": x.addressDetails.streetLine_2,
          City: x.city,
          "Postal Code": x.postalCode,
          District: x.district,
          "State/Provenance": x.state,
          Country: x.country,
          reason: x.contactStatus,
        }
      })
      try {
        let data1 = JSON.parse(localStorage.getItem("values")) || []
        console.log("localStorage=>", data1, {
          date: `${new Date().getTime()}`,
          array: [...duplicateData, ...fileData],
        })
        data1 = [
          { date: `${new Date()}`, array: [...duplicateData, ...fileData] },
          ...data1,
        ]
        duplicateData?.length > 0 &&
          localStorage.setItem("values", JSON.stringify(data1))
      } catch (err) {
        console.log("insidecarch=>", err)
      }
      setFileName("")
      setAcceptCondition(false)
      documentData.value = ""
      alertMessageData = {
        status: true,
        message: response?.data?.message
          ? response?.data?.message
          : "Contacts created succesfull",
      }
    } else {
      responseData = response.data
      alertMessageData = {
        status: false,
        message: response?.data?.message
          ? response?.data?.message
          : "Contacts created unsuccesfull",
      }
    }
  } catch (err) {
    console.log("erroe=>", err)
    alertMessageData = {
      status: false,
      message: err?.data?.message ? err?.data?.message : "Some thing went worng",
    }
  }
  yield put(
    actions.openModelForShowingUploadingStatus(
      false,
      "Successfully contacts updated sucessfully"
    )
  )
  yield put(actions.createBulkUploadContactResponse(responseData, alertMessageData))
}

function* postaCodeBasedDataWatcher() {
  yield takeLeading(types.GET_POSTAL_CODED_BASED_DATA_REQUEST, postCodeBaedRequest)
}
async function getPostalInfoAip(action) {
  let postalCode = []
  let alertMessageData = undefined
  await axios
    .get(`https://api.postalpincode.in/pincode/${action}`)
    .then(res => {
      postalCode = res?.data
    })
    .catch(err => {
      alertMessageData = {
        status: false,
        message: err?.data?.message ? err?.data?.message : "Some thing went worng",
      }
    })
  return { postalCode, alertMessageData }
}
function* postCodeBaedRequest(action) {
  let posatalDetails = []
  let alertMessageData = undefined
  let { requestData, setFieldValue } = action.payload
  let { postalCode, alertRMessageData } = yield call(getPostalInfoAip, requestData)
  if (!alertRMessageData) {
    posatalDetails = postalCode
    console.log("setFieldValue", postalCode)
    if (postalCode?.[0]?.PostOffice?.length > 0) {
      console.log("setFieldValue", postalCode?.[0]?.PostOffice[0].Name)
      setFieldValue("city", postalCode?.[0]?.PostOffice[0].Name)
      setFieldValue("district", postalCode?.[0]?.PostOffice[0].District)
      setFieldValue("state", postalCode?.[0]?.PostOffice[0].State)
    } else {
      setFieldValue("city", "")
      setFieldValue("district", "")
      setFieldValue("state", "")
    }
  } else {
    alertMessageData = alertRMessageData
  }
  console.log("postalCode=>", postalCode)
  yield put(actions.getPostCodeBasedDataResponse(posatalDetails, alertMessageData))
}
function* createContactSaga() {
  yield all([
    fork(getContactManagementWatcher),
    fork(getSearchedConttactListWatcher),
    fork(CreateOrUpdateOrDeleteWatcher),
    fork(getCodeValuesByEnquiryWatcher),
    fork(deleteContactWatcher),
    fork(bulkUploadContactsWatcher),
    fork(postaCodeBasedDataWatcher),
  ])
}
export default createContactSaga
