import * as types from "./actionTypes"
import {
  LAYOUT_ALERT_ACTION_REQUEST,
  ACTION_LOADING,
  LANDING_PAGE_LOADING,
} from "../common/actionTypes"

export const showModelForCreateOrUpdateoeView = (actionType, actionData) => ({
  type: types.SHOW_MODEL_FOR_CREATE_UPDATE_OR_VIEW_REQUEST,
  payload: { actionType, actionData },
})
export const getAllCodeConfigValuesRequest = request => ({
  type: types.GET_ALL_CODECONFIG_VALUES_REQUEST,
  payload: request,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: true,
})
export const getAllCodeConfigValuesResponse = responseData => ({
  type: types.GET_ALL_CODECONFIG_VALUES_RESPONSE,
  payload: responseData,
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
})
export const getAllCodeValuesRequest = request => ({
  type: types.GET_ALL_CODE_VALUES_DATA_REQUEST,
  payload: request,
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const getAllCodeValuesResponse = responseData => ({
  type: types.GET_ALL_CODE_VALUES_DATA_RESPONSE,
  payload: responseData,
  loadType: ACTION_LOADING,
  loadPayload: false,
})
export const createOrUpdateOrDeleteCodeConfigRequest = (
  requestData,
  setIsDeleteModel = undefined
) => ({
  type: types.CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_REQUEST,
  payload: { requestData, setIsDeleteModel },
  loadType: ACTION_LOADING,
  loadPayload: true,
})
export const createOrUpdateOrDeleteCodeConfigResponse = (
  response,
  alertMessageData
) => ({
  type: types.CREATE_OR_UPDATE_OR_DELETE_CODE_CONFIG_RESPONSE,
  payload: response,
  loadType: ACTION_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})

export const resetCodeConfigValue = () => ({ type: types.RESET_ACTION_TYPE })
