import {
  SET_CONFIRMATION_OPEN,
  SET_CONFIRMATION_CLOSE,
  LAYOUT_ALERT_ACTION_SUSPEND,
  ACTION_LOADING,
  LAYOUT_ALERT_ACTION_REQUEST,
  LANDING_PAGE_LOADING
} from "./actionTypes"

export const loading = flag => ({
  type: ACTION_LOADING,
  payload: flag,
})
export const landingPageLoading = flag => ({
  type: LANDING_PAGE_LOADING,
  payload: flag,
})
export const alertActionSuspend = alertMessagedata => ({
  type: LAYOUT_ALERT_ACTION_SUSPEND,
  payload: alertMessagedata,
})
export const alertActionRequest = alertMessagedata => ({
  type: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessagedata,
})
export const setConfirmationOpen = data => ({
  type: SET_CONFIRMATION_OPEN,
  payload: data,
})
export const setConfirmationClose = (data, loading) => ({
  type: SET_CONFIRMATION_CLOSE,
  payload: { data, loading },
})
