import { all, fork } from "redux-saga/effects"
import dashboardSaga from "./dashboard/saga"
import commonLayoutSaga from "./common/saga"
import loginSaga from "./auth/login/saga"
import signUpSaga from "./auth/signUp/saga"
import forgotPasswordSaga from "./auth/forgotPassword/saga"
import createContactSaga from "./contactManagement/saga"
import codeConfigSaga from "./codeConfiguration/saga"
import emailTemplatesSaga from "./emailTemplates/saga"
import whatsAppTemplatesSaga from "./whatsappTemplates/saga"
import whatsAppListMessageTemplatesSaga from "./whatsAppListMessages/saga"
import whatsAppQrCodeSaga from "./qrCodeTemplates/saga"
import campaignManagementSaga from "./campaignManagement/saga"
import insightsSaga from "./insights/saga"
import profilesSaga from "./profile/saga"
import whatsappWebSaga from "./whatsappweb/saga"
import messageRulesSaga from './messageRules/saga'
import subscriptionsSaga from "./subscriptions/saga"
import userManagementSaga from "./userManagement/saga"
import flowConfigSaga from "./rulesConfiguration/saga"
import replyContentSaga from './replyContent/saga'
import QuickreplySaga from "./qucikReply/saga"
export default function* rootSaga() {
  yield all([
    fork(dashboardSaga),
    fork(commonLayoutSaga),
    fork(loginSaga),
    fork(signUpSaga),
    fork(forgotPasswordSaga),
    fork(createContactSaga),
    fork(codeConfigSaga),
    fork(emailTemplatesSaga),
    fork(whatsAppTemplatesSaga),
    fork(whatsAppListMessageTemplatesSaga),
    fork(whatsAppQrCodeSaga),
    fork(campaignManagementSaga),
    fork(insightsSaga),
    fork(profilesSaga),
    fork(whatsappWebSaga),
    fork(subscriptionsSaga),
    fork(messageRulesSaga),
    fork(userManagementSaga),
    fork(flowConfigSaga),
    fork(replyContentSaga),
    fork(QuickreplySaga)
  ])
}
