import {
  call,
  takeLatest,
  all,
  fork,
  put,
  select,
  takeLeading,
} from "redux-saga/effects"
import * as types from "./actionTypes"
import * as actions from "./actions"
import { Get, Post } from "../../helpers/api_helper"
import * as urls from "../../helpers/url_helper"
import { ACTION_TYPES, SUBSCRIPTION_PLAN_TYPE } from "../../constants/index"

// To Get All the Subscription Plans Details
function* getAllSubscriptonPlansWatcher() {
  yield takeLatest(
    types.GET_ALL_SUBSCRIPTION_PLANS_REQUEST,
    getAllSubScrptionsRequest
  )
}
function* getAllSubScrptionsRequest() {
  let plansData, alertMessageData
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  try {
    const response = yield call(Get, `${urls.GET_ALL_PLANS}?cin=${cin}`)
    if (response) {
      plansData = response
    } else {
      alertMessageData = {
        status: false,
        message: response?.data?.message || "Unable to Get the Subscription Plans",
      }
    }
  } catch (err) {
    console.log("getAllSubScrptionsRequest_Error=>", err)
    alertMessageData = {
      status: false,
      message: err?.message || "Unable to Get the Subscription Plans",
    }
  }
  yield put(actions.getAllSubscriptionsResponse(plansData, alertMessageData))
}

// To Create Or Update the Subscriptions
function* createOrUpdateSubscriptionPlanWatcher() {
  yield takeLatest(
    types.CREATE_OR_UPDATE_SUBSCRIPTIONS_REQUEST,
    createOrUpdateSubscriptionPlanRequest
  )
}

// For Year new Date(new Date().setFullYear(new Date().getFullYear() + 1)) / 1000
// For Month new Date(new Date().setMonth(new Date().getMonth() + 1))
// For Day new Date(new Date().setDate(new Date().getDate() + 1))
// For Week new Date(new Date().setDate(new Date().getDay()+6))
const getSubscriptionEndDate = planType => {
  switch (planType?.toLowerCase()) {
    case SUBSCRIPTION_PLAN_TYPE.DAILY?.toLowerCase().toLowerCase():
      return Math.round(
        new Date(new Date().setDate(new Date().getDate() + 1)) / 1000
      )
    case SUBSCRIPTION_PLAN_TYPE.WEEKLY?.toLowerCase().toLowerCase():
      return Math.round(
        new Date(new Date().setDate(new Date().getDate() + 6)) / 1000
      )
    case SUBSCRIPTION_PLAN_TYPE.MONTHLY?.toLowerCase().toLowerCase():
      return Math.round(
        new Date(new Date().setMonth(new Date().getMonth() + 1)) / 1000
      )
    case SUBSCRIPTION_PLAN_TYPE.YEARLY?.toLowerCase().toLowerCase():
      return Math.round(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)) / 1000
      )
    default:
      return Math.round(
        new Date(new Date().setDate(new Date().getDate() + 1)) / 1000
      )
  }
}

function* createOrUpdateSubscriptionUpdateToOurSystem(sessionStateData, action) {
  const plansData = (yield select())["subscripitionsReducer"]?.plansData?.find(
    x => x.modelJson?.map?.id === action?.plan_id
  )

  let subscriptionResponse, alertMessageData
  let requestObj = {
    amount: plansData?.modelJson?.map?.item?.map?.amount,
    chargeAt: "", //action?.charge_at + "",
    cin: sessionStateData?.ciNo,
    currentEndDt: "", //action?.end_at + "",
    currentStartDt: "", //action?.created_at + "",
    customerId: "", //sessionStateData?.subscription?.[0]?.customerId,
    emailId: sessionStateData?.emailId,
    endDate: "", //action?.end_at + "",
    expireBy: "", //action?.expire_by + "",
    id: sessionStateData?.subscription?.find(x => x?.subscriptionId === action?.id)
      ? sessionStateData?.subscription?.find(x => x?.subscriptionId === action?.id)
          ?.id
      : "", //(action?.id),
    objectState: sessionStateData?.subscription?.find(
      x => x?.subscriptionId === action?.id
    )
      ? "UPDATE"
      : "NEW",
    planDesc: plansData?.modelJson?.map?.item?.map?.description,
    planId: action?.plan_id,
    planName: plansData?.modelJson?.map?.item?.map?.name,
    quantity: action?.quantity,
    shortUrl: action?.short_url,
    status: action?.status,
    subscriptionId: action?.id, //sessionStateData?.subscription[0]?.subscriptionId,
    trailFlag: sessionStateData?.subscription?.[0]?.trailFlag,
    trialDays: sessionStateData?.subscription?.[0]?.trialDays,
    trialEndDt: sessionStateData?.subscription?.[0]?.trialEndDt,
    trialStartDt: sessionStateData?.subscription?.[0]?.trialStartDt,
  }
  try {
    let response = yield call(
      Post,
      urls.CREATE_SUBSCRIPTION_ON_NOTIFY_SYSTEM,
      requestObj
    )
    if (response?.status) {
      yield call(getAllSubscriptionsCreated)
      yield put(actions.setModelForSubscriptionModel(undefined, undefined))
      subscriptionResponse = response
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Create The SubScription",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.message || "Unable To Create The SubScription",
    }
  }
}
function* createOrUpdateSubscriptionPlanRequest(action) {
  let alertMessageData, createOrUpdateSubscrtionData
  const sessionStateData = (yield select())["loginReducer"]?.sessionStateData
  let { actionType, requestObj } = action.payload
  try {
    if (
      actionType === ACTION_TYPES.ADD &&
      sessionStateData?.[0]?.status !== "active"
    ) {
      let requestObject = {
        addOnId: "",
        cin: sessionStateData?.ciNo,
        emailId: sessionStateData?.emailId,
        mobileNO: sessionStateData?.contactNo,
        notes: "",
        notifyCustomer: 1,
        offerId: null,
        planId: requestObj?.modelJson?.map?.id,
        planName: requestObj?.modelJson?.map?.item?.map?.name,
        quantity: 1,
        totalCount: 100,
      }
      console.log(
        "createOrUpdateSubscriptionPlanRequest=>",
        requestObj,
        requestObject
      )
      const response = yield call(Post, urls.CREATE_SUBSCRIPTION_LINK, requestObject)
      console.log("response=>", response)
      if (response?.status) {
        window.open(response?.data?.modelJson?.map?.short_url)
        createOrUpdateSubscrtionData = response?.data
        yield call(
          createOrUpdateSubscriptionUpdateToOurSystem,
          sessionStateData,
          response?.data?.modelJson?.map
        )
      } else {
        alertMessageData = {
          status: false,
          message: response?.message || "Unable To Create The SubScription",
        }
      }
    } else {
      window.open(sessionStateData?.[0]?.shortUrl)
    }
  } catch (err) {
    console.log("errrrr=>", err)
    alertMessageData = {
      status: false,
      message: err?.message || "Unable To Create The SubScription",
    }
  }

  yield put(
    actions.createOrUpdateSubscriptionResponse(
      createOrUpdateSubscrtionData,
      alertMessageData
    )
  )
}
function* getAllCreatedSubscriptionsWatcher() {
  console.log("---->watch first", urls.GET_ALL_SUBSCRIPTIONS)

  yield takeLatest(
    types.GET_ALL_CREATED_SUBSCRIPTION_REQUEST,
    getAllSubscriptionsCreated
  )
}
function* getAllSubscriptionsCreated() {
  let subscription, alertMessageData
  const sessionStateData = (yield select())["loginReducer"]?.sessionStateData
  let requestObj = {
    cin: sessionStateData?.ciNo,
    id: 0,
    subscriptionId: "",
  }
  try {
    let response = yield call(Post, urls.GET_ALL_SUBSCRIPTIONS, requestObj)
    if (response) {
      subscription = response?.data?.data
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Create The SubScription",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.message || "Unable To Create The SubScription",
    }
  }
  yield put(
    actions.getAllCreateSubscriptionsResponse(subscription, alertMessageData)
  )
}

function* cancelSubscriptionRequestWatcher() {
  yield takeLatest(types.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionPlan)
}
function* cancelSubscriptionPlan(action) {
  console.log("cancelSubscriptionPlan_Start=>", action)
  let subscriptionData, alertMessageData
  const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
  try {
    let response = yield call(
      Post,
      `${urls.CANCEL_SUBSCRIPTIONS}?cin=${cin}&subscriptionId=${action.payload}`
    )
    console.log(
      "response=>",
      `${urls.CANCEL_SUBSCRIPTIONS}?cin=${cin}&subscriptionId=${action.payload}`
    )
    if (response) {
      subscriptionData = response
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Cancel The SubScription",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.message || "Failed To Cancel Subscription",
    }
  }
  yield put(actions.cancelSubscriptionResponse(subscriptionData, alertMessageData))
}
function* createFeedback(action) {
  let requestObj, alertMessageData

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    requestObj = {
      ...action.payload,
      cin,
    }
    const response = yield Post(urls.CREATE_FEEDBACK, requestObj)
    console.log("_createFeedback_response", response)

    // if (true) {
    if (response?.data?.status) {
      alertMessageData = {
        status: true,
        message: response.data?.message || "Feedback successfully submitted",
        isNewAlertModalType: true,
      }
      yield call(getAllSubscriptionsCreated)
    } else {
      alertMessageData = {
        status: false,
        message: "Unable to submit the feedback",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    console.error("_createFeedback_catch", err)
    alertMessageData = {
      status: false,
      message: "Failed to submit the feedback",
      isNewAlertModalType: true,
    }
  }

  yield put(actions.submitFeedBackResponse(true, alertMessageData))
}
function* createFeedbackWatcher() {
  yield takeLatest(types.SUBMIT_FEED_BACK_REQUEST, createFeedback)
}
function* getAllInvoiceRequest(action) {
  let invoicesListData, alertMessageData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const response = yield call(
      Post,
      `${urls.GET_ALL_INVOICE}?cin=${cin}&subscriptionId=${action.payload}`
    )
    console.log("_createFeedback_response", response)
    if (response?.data?.status) {
      invoicesListData = response.data?.data
    } else {
      alertMessageData = {
        status: false,
        message: "Unable to Get the InVoices",
        isNewAlertModalType: true,
      }
    }
  } catch (err) {
    console.error("_createFeedback_catch", err)
    alertMessageData = {
      status: false,
      message: "Failed to Get the Invoices",
      isNewAlertModalType: true,
    }
  }
  yield put(actions.getAllInvoiceResponse(invoicesListData, alertMessageData))
}
function* getAllInvoicesWatcher() {
  yield takeLatest(types.GET_ALL_SUBSCRIPTION_INVOICES_REQUEST, getAllInvoiceRequest)
}
function* addGstNumberToSubscriptionWatcher() {
  yield takeLatest(
    types.ADD_GST_NUMBER_TO_SUBSCRIPTION_REQUEST,
    addGstNumberToSubscription
  )
}
function* addGstNumberToSubscription(action) {
  let subscriptionResponse, alertMessageData
  let { activeSubscriptionData, gstNumber, setIsAddGST } = action.payload
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    let requestObj = {
      amount: activeSubscriptionData?.amount,
      chargeAt: activeSubscriptionData?.chargeAt, //action?.charge_at + "",
      cin: cin,
      currentEndDt: activeSubscriptionData?.currentEndDt, //action?.end_at + "",
      currentStartDt: activeSubscriptionData?.currentStartDt, //action?.created_at + "",
      customerId: activeSubscriptionData?.customerId, //sessionStateData?.subscription?.[0]?.customerId,
      emailId: activeSubscriptionData?.emailId,
      endDate: activeSubscriptionData?.endDate, //action?.end_at + "",
      expireBy: activeSubscriptionData?.expireBy, //action?.expire_by + "",
      id: activeSubscriptionData?.id, //(action?.id),
      objectState: "UPDATE",
      planDesc: activeSubscriptionData?.planDesc,
      planId: activeSubscriptionData?.planId,
      planName: activeSubscriptionData?.planName,
      quantity: activeSubscriptionData?.quantity,
      shortUrl: activeSubscriptionData?.shortUrl,
      status: activeSubscriptionData?.status,
      subscriptionId: activeSubscriptionData?.subscriptionId, //sessionStateData?.subscription[0]?.subscriptionId,
      trailFlag: activeSubscriptionData?.trailFlag,
      trialDays: activeSubscriptionData?.trialDays,
      trialEndDt: activeSubscriptionData?.trialEndDt,
      trialStartDt: activeSubscriptionData?.trialStartDt,
      gstIN: gstNumber,
    }
    let response = yield call(
      Post,
      urls.CREATE_SUBSCRIPTION_ON_NOTIFY_SYSTEM,
      requestObj
    )
    if (response?.status) {
      yield call(getAllSubscriptionsCreated)
      subscriptionResponse = response
      setIsAddGST(false)
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Create The SubScription",
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.message || "Unable To Create The SubScription",
    }
  }
  yield put(
    actions.createGstNumberForSubscriptionResponse(
      subscriptionResponse,
      alertMessageData
    )
  )
}
function* subscriptionsSaga() {
  yield all([
    fork(getAllSubscriptonPlansWatcher),
    fork(createOrUpdateSubscriptionPlanWatcher),
    fork(getAllCreatedSubscriptionsWatcher),
    fork(cancelSubscriptionRequestWatcher),
    fork(createFeedbackWatcher),
    fork(getAllInvoicesWatcher),
    fork(addGstNumberToSubscriptionWatcher),
  ])
}
export default subscriptionsSaga
