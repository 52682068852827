import { cloneDeep, isEmpty } from "lodash"
import moment from "moment"
import {
  WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP,
  MESSAGE_READ_RECEIPTS_MAP,
  WHATSAPP_MEDIA_TYPE_MAP,
  DAY_NAME_BY_NUMBER_MAP,
} from "../../constants"
import * as urls from "../../helpers/url_helper"
import { fileUploader } from "../../store/helpers"
import {
  alertActionRequest,
  socketMessageReceived,
  uploadFileRequest,
} from "../../store/actions"
import { styled, alpha } from "@mui/material/styles"
import React from "react"
import Menu from "@mui/material/Menu"
import { useDispatch as dispatch } from "react-redux"
export const getTextAvatar = text => {
  if (text) {
    const avatar = text?.trim()?.split(" ")
    if (avatar.length > 1) {
      return avatar[0][0] + avatar[1][0]
    } else if (avatar.length === 1) {
      return avatar[0][0] + avatar[0][1]
    } else {
      return "WB"
    }
  }
  return "WB"
}

export const getUpdatedMessageStyles = (styles, isUserMessage) => {
  const updatedStyles = { ...styles }

  if (!isUserMessage) {
    updatedStyles["right"] = "20px"

    delete updatedStyles.left
  }
  return updatedStyles
}

export const getUpdatedChatsAndConversationsWithMapping = (
  state,
  receivedPayload
) => {
  let updatedConversationsWithMapping, updatedChatItemsWithMapping

  try {
    const { chatItems, conversations, selectedChatItem } = state

    if (receivedPayload.key) {
      const conversationIndex = conversations.findIndex(
        msg => msg.uniqueId == receivedPayload.key
      )
      console.log("__getUpdatedChatsAndConversationsWithMapping", {
        conversationIndex,
        conversations,
        receivedPayload,
      })

      if (conversationIndex !== -1) {
        updatedChatItemsWithMapping = cloneDeep(chatItems)
        updatedConversationsWithMapping = cloneDeep(conversations)

        const updatedConversationObj = {
          ...conversations[conversationIndex],
          whatsAppMessageId: receivedPayload.whatsAppMessageId,
        }
        updatedConversationsWithMapping.splice(
          conversationIndex,
          1,
          updatedConversationObj
        )

        const updatedChatItemObj = {
          ...chatItems[selectedChatItem],
          status: receivedPayload.status,
        }
        updatedChatItemsWithMapping.splice(selectedChatItem, 1, updatedChatItemObj)
      }
    }
  } catch (err) {
    console.log("_getUpdatedChatsAndConversationsWithMapping_err", err)
    console.error("_getUpdatedChatsAndConversationsWithMapping_err", err)
  }

  return { updatedConversationsWithMapping, updatedChatItemsWithMapping }
}

export const getUpdatedChatsAndConversationsWithStatus = (
  state,
  receivedPayload
) => {
  let updatedConversationsWithStatus, updatedChatItemsWithStatus
  try {
    const { chatItems, conversations, selectedChatItem } = state
    console.log(
      "getUpdatedChatsAndConversations=>",
      chatItems,
      selectedChatItem,
      conversations
    )
    if (
      typeof selectedChatItem === "number" &&
      selectedChatItem >= 0 &&
      !!receivedPayload.status
    ) {
      const conversationIndex = conversations.findIndex(
        message => message.whatsAppMessageId === receivedPayload.whatsAppMessageId
      )
      const chatItemIndex = chatItems?.findIndex(
        item => item?.phoneNumber === receivedPayload?.to
      )

      if (conversationIndex !== -1 || chatItemIndex !== -1) {
        updatedConversationsWithStatus = cloneDeep(conversations)
        updatedChatItemsWithStatus = cloneDeep(chatItems)

        // update status for conversations (right side)
        if (conversationIndex !== -1) {
          const updatedConversationObj = {
            ...conversations[conversationIndex],
            status: receivedPayload?.status || "",
          }
          updatedConversationsWithStatus.splice(
            conversationIndex,
            1,
            updatedConversationObj
          )
        }

        // update status for chatItem (left side)
        if (chatItemIndex !== -1) {
          const updatedChatItem = {
            ...chatItems[chatItemIndex],
            status: receivedPayload.status,
          }
          updatedChatItemsWithStatus.splice(chatItemIndex, 1, updatedChatItem)
        }
      }
    }
  } catch (err) {
    console.log("_getUpdatedChatsAndConversationsWithStatus_err", err)
    console.error("_getUpdatedChatsAndConversationsWithStatus_err", err)
  }

  console.log("_getUpdatedChatsAndConversationsWithStatus", {
    updatedChatItemsWithStatus,
    updatedConversationsWithStatus,
  })
  return { updatedChatItemsWithStatus, updatedConversationsWithStatus }
}

export const getUpdatedChatsAndConversations = (
  state,
  receivedPayload,
  masterPhoneNumber
) => {
  const { chatItems, selectedChatItem, conversations } = state
  let updatedConversations = cloneDeep(conversations)
  let updatedChatItems = cloneDeep(chatItems)
  let updatedSelectedChatItem
  try {
    const chatItemIndex = chatItems?.findIndex(
      item => item?.phoneNumber === receivedPayload?.from
    )
    let parsedActionsData = undefined
    try {
      parsedActionsData = JSON.parse(receivedPayload?.actions?.response)
    } catch (err) {
      parsedActionsData = undefined
    }
    console.log(
      "parsedActionsData",
      chatItemIndex === selectedChatItem,
      receivedPayload,
      parsedActionsData
    )
    // if the received message is of selected contact.
    if (chatItemIndex === selectedChatItem) {
      updatedConversations.push({
        fromMobileNo: receivedPayload?.from,
        toMobileNo: receivedPayload?.to,
        messageType: receivedPayload?.type,
        message: receivedPayload?.message || "",
        updatedTs: receivedPayload?.updatedTs,
        channel:
          receivedPayload?.messageType || WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP.WEBHOOK,
        // channel: (["rules", 'interactive', 'image', 'video', 'document', 'template', 'location'].includes(receivedPayload?.type?.toLowerCase())) ? "API" : receivedPayload?.type?.toLowerCase() === 'text' ? 'API' : WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP.WEBHOOK,
        url: receivedPayload?.url || "",
        type: receivedPayload?.type || WHATSAPP_MEDIA_TYPE_MAP.TEXT,
        whatsAppMessageId: receivedPayload?.whatsAppMessageId,
        context: receivedPayload?.context,
        actions: receivedPayload?.actions,
      })

      const updatedChatItem = {
        ...updatedChatItems[chatItemIndex],
        message: receivedPayload?.message || chatItems[chatItemIndex]?.message,
        actions: receivedPayload?.actions || chatItems[chatItemIndex]?.actions,
        messageType: receivedPayload?.type,
        ticketInfo:
          parsedActionsData && parsedActionsData?.item === "status"
            ? {
                ...chatItems[chatItemIndex]?.ticketInfo,
                reporterId: parsedActionsData?.reporter?.id,
                status: parsedActionsData?.newValue?.status,
              }
            : parsedActionsData?.item === "replyowner"
            ? {
                ...chatItems[chatItemIndex]?.ticketInfo,
                assigneeId: parsedActionsData?.newValue?.assigneeId,
                replyOwner: parsedActionsData?.newValue?.replyOwner,
                reporterId: parsedActionsData?.reporter?.id,
              }
            : chatItems[chatItemIndex]?.ticketInfo,
      }

      // update empty status when the receiver is admin.
      if (receivedPayload?.to === masterPhoneNumber) {
        updatedChatItem.status = ""
      }

      updatedChatItems.splice(chatItemIndex, 1)
      updatedChatItems.unshift(updatedChatItem)

      console.log("_getUpdatedChatsAndConversations_start", {
        updatedChatItems,
        updatedSelectedChatItem,
        chatItemIndex,
        selectedChatItem,
        chatItem: updatedChatItems[chatItemIndex],
        chatItem2: updatedChatItems[selectedChatItem],
      })

      // update chatItemIndex (update previously selected chatIndex)
      if (typeof selectedChatItem === "number" && selectedChatItem >= 0) {
        updatedSelectedChatItem = 0
      }
      // scroll to the latest message.
      setTimeout(() => {
        const entry = Array.from(document.querySelectorAll(".conversation")).at(-1)
        entry?.scrollIntoView?.({ behavior: "auto" })
      })
    } else {
      const chatItem = chatItemIndex !== -1 ? updatedChatItems[chatItemIndex] : null
      const previouslySelectedChatItem =
        selectedChatItem !== -1 ? updatedChatItems[selectedChatItem] : {}
      if (chatItem) {
        const updatedChatItem = {
          ...(chatItem || {}),
          message: receivedPayload?.message || chatItem?.message,
          actions: receivedPayload?.actions || chatItem?.actions,
          status: MESSAGE_READ_RECEIPTS_MAP.UNREAD,
          updatedTs: receivedPayload?.updatedTs,
          messageType: receivedPayload?.type,
          channel:
            receivedPayload?.messageType ||
            WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP.WEBHOOK,
          // channel: ["rules", 'interactive', 'image', 'video', 'document', 'template', 'location'].includes(receivedPayload?.type?.toLowerCase()) ? "API" : WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP.WEBHOOK,
          url: receivedPayload?.url || "",
          type: receivedPayload?.type || WHATSAPP_MEDIA_TYPE_MAP.TEXT,
          whatsAppMessageId:
            receivedPayload?.whatsAppMessageId || chatItem?.whatsAppMessageId,
          context: receivedPayload?.context || chatItem?.context,
          actions: receivedPayload?.actions || chatItem?.actions,
          ticketInfo:
            parsedActionsData && parsedActionsData?.item === "status"
              ? {
                  ...chatItem?.ticketInfo,
                  reporterId: parsedActionsData?.reporter?.id,
                  status: parsedActionsData?.newValue?.status,
                }
              : parsedActionsData?.item === "replyowner"
              ? {
                  ...chatItem?.ticketInfo,
                  assigneeId: parsedActionsData?.newValue?.assigneeId,
                  replyOwner: parsedActionsData?.newValue?.replyOwner,
                  reporterId: parsedActionsData?.reporter?.id,
                }
              : chatItem?.ticketInfo,
        }
        console.log("updatedChatItem=>", updatedChatItem)
        updatedChatItems.splice(chatItemIndex, 1)
        updatedChatItems.unshift(updatedChatItem)
        console.log("updatedChatItem=>90909090", updatedChatItems)
      } else {
        updatedChatItems.unshift({
          message: receivedPayload?.message,
          actions: receivedPayload?.actions,
          status: MESSAGE_READ_RECEIPTS_MAP.UNREAD,
          messageType: receivedPayload?.type,
          phoneNumber: receivedPayload?.from,
          updatedTs: receivedPayload?.updatedTs,
          channel: WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP.WEBHOOK,
          url: receivedPayload?.url || "",
          type: receivedPayload?.type || WHATSAPP_MEDIA_TYPE_MAP.TEXT,
          whatsAppMessageId: receivedPayload?.whatsAppMessageId,
          context: receivedPayload?.context,
        })
      }

      const latestChatItemIndex = updatedChatItems?.findIndex(
        chat =>
          chat?.contactName === previouslySelectedChatItem?.contactName &&
          chat?.phoneNumber === previouslySelectedChatItem?.phoneNumber
      )
      // update chatItemIndex (update previously selected chatIndex)
      if (typeof selectedChatItem === "number" && latestChatItemIndex >= 0) {
        console.log("latestChatItemIndex==>", latestChatItemIndex)
        updatedSelectedChatItem = latestChatItemIndex
      }
    }
  } catch (err) {
    console.error("_getUpdatedConversations_catch", err)
  }

  console.log("__getUpdatedChatsAndConversations", {
    updatedConversations,
    updatedChatItems,
    updatedSelectedChatItem,
  })
  return { updatedConversations, updatedChatItems, updatedSelectedChatItem }
}

export const getFileExtension = file =>
  file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length)

export const getRemoteUrlsForValidSelectedFiles = async (
  selectedFiles,
  cin,
  dispatch
) => {
  const fileRes = fileUploader(selectedFiles, `${urls.MEDIA_UPLOAD}?cin=${cin}`)

  const response = await fileRes.next().value
  const fileUrls = response
    ?.filter(res => res?.value?.data?.status)
    ?.map(res => res?.value?.data?.fileLink)

  console.log("_getRemoteUrlsForValidSelectedFiles", {
    response,
    fileUrls,
  })

  if (response?.length !== selectedFiles?.length) {
    dispatch(
      alertActionRequest({
        status: false,
        message: "Something went wrong while uploading few media files.",
        isNewAlertModalType: false,
      })
    )
  }

  return fileUrls
}

export const getMessageToEmit = ({
  toNumber,
  messageType,
  messageBody,
  mediaUrl,
  metaRequestObj,
  captions,
}) => {
  try {
    const requestObj = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: toNumber,
      type: messageType,
    }

    switch (messageType) {
      case WHATSAPP_MEDIA_TYPE_MAP.TEXT:
        requestObj.text = {
          preview_url: false,
          body: messageBody,
        }
        break
      case WHATSAPP_MEDIA_TYPE_MAP.IMAGE:
      case WHATSAPP_MEDIA_TYPE_MAP.VIDEO:
      case WHATSAPP_MEDIA_TYPE_MAP.DOCUMENT:
        if (mediaUrl) {
          requestObj[messageType] = {
            link: mediaUrl,
            caption: captions,
          }
        }
        break
      case WHATSAPP_MEDIA_TYPE_MAP.LIST_MESSAGE:
        if (!isEmpty(metaRequestObj)) {
          requestObj.interactive = metaRequestObj
        }
        break
      case WHATSAPP_MEDIA_TYPE_MAP.TEMPLATE:
        if (!isEmpty(metaRequestObj)) {
          requestObj.template = metaRequestObj
        }
        break
      case WHATSAPP_MEDIA_TYPE_MAP.LOCATION:
        requestObj[messageType] = messageBody
        break
    }
    console.log("_getMessageToEmit", {
      messageType,
      mediaUrl,
      requestObj,
    })

    return JSON.stringify(requestObj)
  } catch (err) {
    console.error("_getMessageToEmit_catch", err)
  }
}

export const scrollIntoElementView = (eleId, isAuto) => {
  setTimeout(() => {
    document
      .getElementById(eleId)
      ?.scrollIntoView?.({ behavior: isAuto ? "auto" : "smooth" })
  }, 1000)
}

const getMonthFromDate = date => {
  const month = date.getMonth()

  if (month <= 9) {
    return `0${month + 1}`
  }
  return month + 1
}

export const getWhatsappTimeStamp = updatedTs => {
  try {
    if (updatedTs) {
      const date = new Date()
      const dateStr = `${date.getFullYear()}-${getMonthFromDate(
        date
      )}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      const startDate = moment(dateStr)
      const endDate = moment(updatedTs)
      const dayDiff = Number(endDate.diff(startDate, "hours", false) / 12) || 0
      const difference = Math.floor(Math.abs(dayDiff))

      if (difference === 0) {
        // returns only time (hh:mm) for current day conversations.
        const [hh, mm] = updatedTs.split(" ")[1]?.split(":")
        return `${hh}:${mm}`
      } else if (difference === 1) {
        // returns 'Yesterday' when the difference 1.
        return DAY_NAME_BY_NUMBER_MAP["YESTERDAY"]
      } else if (difference > 1 && difference < 7) {
        // returns day of the week if the difference range is 1-7.
        return DAY_NAME_BY_NUMBER_MAP[endDate._d.getDay()]
      } else if (difference >= 7) {
        // returns date str (yyyy-mm-dd) when the diff is greater than 7.
        return updatedTs.split(" ")[0]
      }
      return updatedTs
    }
    return updatedTs
  } catch (err) {
    console.error("Error occured in getWhatsappTimeStamp fn()", err)
    return updatedTs
  }
}

export const pullContextFromSocketEvent = context => {
  try {
    if (context) {
      const parsedContext = context ? JSON.parse(context) : ""

      if (parsedContext) {
        const eventObj =
          parsedContext?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]
        const context = eventObj?.context

        return {
          parentWhatsappMessageId: context?.id,
          parentWhatsappMessageId2: eventObj?.id,
          // from: eventObj?.from,
          // to: context?.from,
          // messageType: eventObj?.type,
          // messageBody: eventObj[eventObj?.type],
        }
      }
      return parsedContext
    }
    return context
  } catch (err) {
    console.log("_pullContextFromSocketEvent", err)
    console.error("_pullContextFromSocketEvent", err)

    return context
  }
}

export const ActionsForMessgaes = {
  CREATED: "created",
  UPDATED: "updated",
  DELETED: "deleted",
}
export const MessageTypes = {
  STATUS: "status",
  COMMENT: "comment",
  REPLY_OWNER: "replyowner",
  LABEL: "label",
}

export const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    // height: 200,
    color: "#1E2A4A",
    fontFamily: "Roboto-Medium",
    fontSize: 21,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      boxShadow: "7px 4px 21px rgb(0 0 0 / 20%)",
      borderRadius: "5px",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        // marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export const getSocketMessageRecivedConnection = (updatedChatItem, fromMobileNo) =>
  socketMessageReceived(updatedChatItem, fromMobileNo)
