export const GET_ALL_CONTACTS_REQUEST = "GET_ALL_CONTACTS_REQUEST"
export const GET_ALL_CONTACTS_RESPONSE = "GET_ALL_CONTACTS_RESPONSE"
export const CREATE_OR_UPDATE_DELETE_CONTACT_REQUEST =
  "CREATE_OR_UPDATE_DELETE_CONTACT_REQUEST"
export const CREATE_OR_UPDATE_DELETE_CONTACT_RESPONSE =
  "CREATE_OR_UPDATE_DELETE_CONTACT_RESPONSE"

export const GET_SEARCH_VALUE_REQUEST = "GET_SEARCH_VALUE_REQUEST"
export const GET_SEARCH_VALUE_RESPONSE = "GET_SEARCH_VALUE_RESPONSE"
export const GET_CODE_VALUE_FOR_ENQUIRES_REQUEST =
  "GET_CODE_VALUE_FOR_ENQUIRES_REQUEST"
export const GET_CODE_VALUE_FOR_ENQUIRES_RESPONSE =
  "GET_CODE_VALUE_FOR_ENQUIRES_RESPONSE"

export const SHOW_MODEL_FOR_CREATE_UPDATE_DELETE =
  "SHOW_MODEL_FOR_CREATE_UPDATE_DELETE"
export const DELETE_CONTACT_LIST_DATE_REQUEST = "DELETE_CONTACT_LIST_DATE_REQUEST"
export const DELETE_CONTACT_LIST_DATE_RESPONSE = "DELETE_CONTACT_LIST_DATE_RESPONSE"

export const SELECT_VALUES_FOR_UPLOAD_OR_DOWNLOAD =
  "SELECT_VALUES_FOR_UPLOAD_OR_DOWNLOAD"

export const CREATE_BULK_UPLOAD_CONTACT_REQUEST =
  "CREATE_BULK_UPLOAD_CONTACT_REQUEST"
export const CREATE_BULK_UPLOAD_CONTACT_RESPONSE =
  "CREATE_BULK_UPLOAD_CONTACT_RESPONSE"
export const GET_POSTAL_CODED_BASED_DATA_REQUEST =
  "GET_POSTAL_CODED_BASED_DATA_REQUEST"
export const GET_POSTAL_CODED_BASED_DATA_RESPONSE =
  "GET_POSTAL_CODED_BASED_DATA_RESPONSE"

export const OPEN_MODEL_FOR_SHOWING_UPLOAGING_STATUS =
  "OPEN_MODEL_FOR_SHOWING_UPLOAGING_STATUS"
export const RESET_SHOW_MODEL_FOR_CREATE_UPDATE_DELETE =
  "RESET_SHOW_MODEL_FOR_CREATE_UPDATE_DELETE"
