import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes"

const initialState = {
  actionData: undefined,
  actionType: ACTION_TYPES.SELECT,
  listMessageTemplateList: undefined,
}

const WhatsAppListMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODEL_FOR_CREATE_OR_UPDATE_DELETE:
      state = {
        ...state,
        actionData: action.payload.actionData,
        actionType: action.payload.actionType,
      }
      break
    case types.GET_ALL_LIST_MESSAGE_TEMPLATE_RESPONSE:
      state = {
        ...state,
        listMessageTemplateList: action.payload,
      }
      break
    case types.CREATE_OR_UPDATE_OR_DELETE_LIST_MESSAGE_RESPONSE:
      if (action.payload.responseStatus) {
        state = {
          ...state,
          actionType: ACTION_TYPES.SELECT,
          listMessageTemplateList: action.payload.listMessageData,
        }
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}
export default WhatsAppListMessageReducer
