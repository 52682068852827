import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes"

const intialState = {
  actionType: ACTION_TYPES.SELECT,
  actionData: undefined,
  userCreatedTemplatesData: undefined,
  preDefinedTemplateData: undefined,
  fileDataResponse: undefined,
  placeHoldersData: undefined,
  selectedType: undefined,
  templateNameExitsOrNot: [],
}
const WhatsAppTemplateReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.CANCEL_ALL_PENDING_REQUEST:
      state = {
        actionType: ACTION_TYPES.SELECT,
        actionData: undefined,
        userCreatedTemplatesData: undefined,
        preDefinedTemplateData: [],
        fileDataResponse: undefined,
        placeHoldersData: undefined,
        selectedType: undefined,
        templateNameExitsOrNot: [],
      }
      break
    case types.SHOW_MODAL_DATA_REQUEST_FOR_WHATSAPP_TEMPLATE:
      state = {
        ...state,
        actionType: action.payload.actionType,
        actionData: action.payload.actionData,
        placeHoldersData: action.payload.placeHoldersData,
        selectedType: action.payload.selectedType,
      }
      break
    case types.GET_ALL_USER_CREATED_OR_PRE_DEFINED_WHATSAPP_TEMPLATES_RESPONSE:
      state = {
        ...state,
        userCreatedTemplatesData: action.payload.userCreatedTemplatesData,
        preDefinedTemplateData: action.payload.preDefinedTemplateData,
      }
      break
    case types.DELETE_WHATS_APP_TEMPELATE_RESPONSE:
      state = {
        ...state,
        userCreatedTemplatesData: action.payload.userCreatedTemplatesData,
        preDefinedTemplateData: action.payload.preDefinedTemplateData,
      }
      break
    case types.CREATE_OR_UPDATE_WPA_TEMPLATE_RESPONSE:
      if (action.payload.responseStatus) {
        state = {
          ...state,
          actionType: ACTION_TYPES.SELECT,
          userCreatedTemplatesData: action.payload.userCreatedTemplatesData,
          preDefinedTemplateData: action.payload.preDefinedTemplateData,
        }
      }
      break
    case types.UPLOADING_FILE_DATA_RESPONSE:
      state = {
        ...state,
        fileDataResponse: action.payload,
      }
      break
    case types.UPLOADING_WAP_TEMPLATE_TO_WHATSAPP_RESPONSE:
      state = {
        ...state,
        userCreatedTemplatesData: action.payload.userCreatedTemplatesData,
        preDefinedTemplateData: action.payload.preDefinedTemplateData,
      }
    case types.GET_DEFAULT_WAP_TEPMLATES_RESPONSE:
      state = {
        ...state,
        preDefinedTemplateData: action.payload,
      }
      break
    case types.GET_TEMPLATE_NAME_EXSITS_OR_NOT_RESPONSE:
      state = {
        ...state,
        templateNameExitsOrNot: action.payload,
      }
      break
    default:
      state = { ...state }
  }
  return state
}
export default WhatsAppTemplateReducer
