export const GET_CAMPAIGNS_DATA_REQUEST = "GET_CAMPAIGNS_DATA_REQUEST"
export const GET_CAMPAIGNS_DATA_RESPONSE = "GET_CAMPAIGNS_DATA_RESPONSE"

export const GET_CAMPAIGN_MULTI_DATA_REQUEST = "GET_CAMPAIGN_MULTI_DATA_REQUEST"
export const GET_CAMPAIGN_MULTI_DATA_RESPONSE = "GET_CAMPAIGN_MULTI_DATA_RESPONSE"

export const GET_ALL_CONTACTS_FOR_CAMPAIGN_REQUEST =
  "GET_ALL_CONTACTS_FOR_CAMPAIGN_REQUEST"
export const GET_ALL_CONTACTS_FOR_CAMPAIGN_RESPONSE =
  "GET_ALL_CONTACTS_FOR_CAMPAIGN_RESPONSE"

export const SET_CAMPAIGNS_ACTION_DATA = "SET_CAMPAIGNS_ACTION_DATA"
export const SET_CAMPAIGN_STATE_BY_KEY = "SET_CAMPAIGN_STATE_BY_KEY"
export const SET_CAMPAIGNS_FILTER = "SET_CAMPAIGNS_FILTER"

export const CAMPAIGN_CRUD_REQUEST = "CAMPAIGN_CRUD_REQUEST"
export const CAMPAIGN_CRUD_RESPONSE = "CAMPAIGN_CRUD_RESPONSE"

export const SET_CAMPAIGN_SELECTED_CONTACTS = "SET_CAMPAIGN_SELECTED_CONTACTS"

export const CLEAR_CAMPAIGN_STATE = "CLEAR_CAMPAIGN_STATE"

export const FETCH_CAMPAIGN_CONTACTS_REQUEST = "FETCH_CAMPAIGN_CONTACTS_REQUEST"
export const FETCH_CAMPAIGN_CONTACTS_RESPONSE = "FETCH_CAMPAIGN_CONTACTS_RESPONSE"

export const VIEW_CAMPAIGN_INSIGHTS_REQUEST = "VIEW_CAMPAIGN_INSIGHTS_REQUEST"
export const VIEW_CAMPAIGN_INSIGHTS_RESPONSE = "VIEW_CAMPAIGN_INSIGHTS_RESPONSE"

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST"
export const GET_FEEDBACK_RESPONSE = "GET_FEEDBACK_RESPONSE"

export const CREATE_FEEDBACK_REQUEST = "CREATE_FEEDBACK_REQUEST"
export const CREATE_FEEDBACK_RESPONSE = "CREATE_FEEDBACK_RESPONSE"

export const GET_LATEST_CAMPAIGN_DATA_REQUEST = "GET_LATEST_CAMPAIGN_DATA_REQUEST"
export const GET_LATEST_CAMPAIGN_DATA_RESPONSE = "GET_LATEST_CAMPAIGN_DATA_RESPONSE"

export const RESET_CONTACT_ATTRIBUTES = "RESET_CONTACT_ATTRIBUTES"

export const FETCH_LATEST_PDPA_DATA_REQUEST = "FETCH_LATEST_PDPA_DATA_REQUEST"
export const FETCH_LATEST_PDPA_DATA_RESPONSE = "FETCH_LATEST_PDPA_DATA_RESPONSE"
