import { ACTION_TYPES } from "../../constants/index"
import * as types from "./actionTypes";

const intialState = {
    actionType: ACTION_TYPES.SELECT,
    actionData: undefined,
    messageRulesData: undefined,
    oneTimeMessageQuickReplies: undefined,
    selectedQuickReplies: undefined,
    flowsData: undefined,
    selectedFlowsData: undefined,
    isSelectedQuickReplies: false,
    isEditRule: false,
    failedMessageForKeyWord: '',
    isKeyWordsModelOpen: false,
    keyWordDataFromAtioncomp: undefined

}

const MessageRulesReducer = (state = intialState, action) => {
    switch (action.type) {
        case types.CANCEL_ALL_PENDING_REQUEST_MESSAGE_RULES:
            state = {
                actionType: ACTION_TYPES.SELECT,
                actionData: undefined,
                messageRulesData: undefined,
                oneTimeMessageQuickReplies: undefined,
                selectedQuickReplies: undefined,
                selectedFlowsData: undefined,
                isSelectedQuickReplies: undefined,
                isEditRule: false

            }
            break;
        case types.SET_MESSAGERULES_MODEL_OPEN:
            state = {
                ...state,
                actionType: action.payload.actionType,
                actionData: action.payload.actionData,
                isEditRule: action?.payload?.isEdit,
                isKeyWordsModelOpen: false

            }
            if (action.payload.actionType === ACTION_TYPES.EDIT) {

                if (action.payload?.actionData?.ruleType === "Flow")
                    state = {
                        ...state,
                        selectedFlowsData: action?.payload?.actionData?.replyItems || []
                    }
                if (action?.payload?.actionData?.ruleType === "Onetime")
                    state = {
                        ...state,
                        selectedQuickReplies: action?.payload?.actionData?.replyItems || []
                    }
            }
            if (action?.payload?.actionType === ACTION_TYPES.FLOW_MESSAGE_RULE) {
                state = {
                    ...state,
                    selectedQuickReplies: undefined

                }
            }
            else if (action?.payload?.actionType === ACTION_TYPES.ONE_TIME_MESSAGE_RULE) {
                state = {
                    ...state,
                    selectedFlowsData: undefined
                }
            }

            break;
        case types.VALIDATE_KEYWORD_RESPONSE:
            state = {
                ...state,

                failedMessageForKeyWord: action.payload.failedMessage,
                isKeyWordsModelOpen: action?.payload?.keyWOrdModelCheck
            }
            break;
        case types.GET_ONE_TIME_MESSAGE_REPLIES_RESPONSE:
            state = {
                ...state,
                oneTimeMessageQuickReplies: action?.payload
            }
            break;
        case types.SELECTED_QUICK_REPLIES:
            state = {
                ...state,
                selectedQuickReplies: action?.payload,
                isSelectedQuickReplies: true
            }
            break;
        case types.SELECTED_FLOW_REPLIES:
            state = {
                ...state,
                selectedFlowsData: action?.payload
            }
            break;
        case types.CREATE_MESSAGE_RULE_RESPONSE:
            if (action.payload.responseStatus) {
                state = {
                    ...state,
                    actionType: ACTION_TYPES.SELECT,
                    messageRulesData: action?.payload?.previousMessagesData
                }
            }
            break;
        case types?.GET_FLOW_MESSAGE_REPLIES_RESPONSE:
            state = {
                ...state,
                flowsData: action?.payload
            }
            break;
        case types.GET_MESSAGE_RULES_RESPONSE:
            state = {
                ...state,
                messageRulesData: action?.payload,

                selectedFlowsData: undefined,
                selectedQuickReplies: undefined,
                isEditRule: false,

            }
            break;
        case types.SET_KEY_WORDS_MODEL_OPEN:
            state = {
                ...state,
                isKeyWordsModelOpen: true,
                keyWordDataFromAtioncomp: action?.payload?.data
            }
            break;
        default:
            state = { ...state }
    }
    return state

}

export default MessageRulesReducer