import * as types from "./actionTypes"
import {
  ACTION_LOADING,
  LAYOUT_ALERT_ACTION_REQUEST,
  LANDING_PAGE_LOADING
} from "../../store/common/actionTypes"

export const cancelAllPendingRequestsForInSights = () => ({
  type: types.CANCEL_ALL_PENDING_REQUESTS_FOR_INSIGHTS,
})
export const getInsightsRequest = (
  requestObj,
  selectedChartType,
  apiRequest = true,
  isLandingPage = false
) => ({
  type: types.GET_INSIGHTS_DATA_REQUEST,
  payload: { requestObj, selectedChartType, apiRequest },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: isLandingPage,
})
export const getInsightsResponse = (
  requestObj,
  selectedChartType,
  pricingAnalytics,
  conversationAnalytics,
  barGraphDataOptions,
  pieChartOptions,
  alertMessageData
) => ({
  type: types.GET_INSIGHTS_DATA_RESPONSE,
  payload: {
    requestObj,
    selectedChartType,
    pricingAnalytics,
    conversationAnalytics,
    barGraphDataOptions,
    pieChartOptions,
  },
  loadType: LANDING_PAGE_LOADING,
  loadPayload: false,
  alertMType: LAYOUT_ALERT_ACTION_REQUEST,
  alertMPayload: alertMessageData,
})
